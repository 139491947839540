import React from "react";
import {
  Box,
  Paper,
  Container,
  Grid,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import footerLanding from '../../../../assets/footerLanding.png';
import Link from '@mui/material/Link';

function GetFullReportComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="lg" sx={{ pt: 4 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={{
          pt: 6,
          [theme.breakpoints.down("md")]: {
            pt: 3,
          },
        }}
      >
        <Grid item xs={12} align="center">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "800",
              fontFamily: "Lato",
              [theme.breakpoints.down("md")]: {
                fontSize: "17px",
                fontWeight: "700",
              },
            }}
          >
            {" "}
            Get Full Report for free
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
          >
            <Grid item xs={12} md={4} align="center">
              <Button
                href={"/auth"}
                size="large"
                sx={{
                  backgroundColor: "#194BFB",
                  textTransform: "none",
                  borderRadius: "10px",
                  fontsize: "20px",
                  fontWeight: 700,
                  // padding: "12px 40px",
                  "&:hover": {
                    backgroundColor: "#194BFB",
                  },
                }}
                variant="contained"
                disableElevation
              >
                Sign Up Now
              </Button>
            </Grid>
            {/* <Grid item xs={12} md={4} align="center"> */}
            &nbsp; &nbsp; &nbsp;
            <Typography
              sx={{
                fontFamily: "Lato",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              {" "}
              {"Or"}
            </Typography>
            &nbsp; &nbsp; &nbsp; &nbsp;
            {/* </Grid> */}
            <Grid item xs={12} md={4} align="center">
            <Link
              // component="button"
              // variant="body2"
              // onClick={() => {
              //   console.info("I'm a button.");
              // }}
              underline="none"
            >
              <Button
                size="large"
                sx={{
                  color: "#194BFB",
                  border: "1px solid #194BFB",
                  textTransform: "none",
                  borderRadius: "10px",
                  fontsize: "20px",
                  fontWeight: 700,
                  // padding: "12px 50px",
                  "&:hover": {
                    background: "none",
                  },
                }}
                variant="outlined"
                disableElevation
              >
                Know More
              </Button>
            </Link>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} align="center">
          <img
            src={footerLanding}
            width={matches ? "40%" : "20%"}
            style={{ paddingBottom: "20px" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default GetFullReportComponent;
