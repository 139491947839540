import React, {createRef, useState} from 'react';
import {
    Grid,
    TextField,
    Typography,
    Button,
    IconButton,
    Box,
    Link,
    InputAdornment,
    Stack,
    Paper
  } from "@mui/material";
  import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
  import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
  import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
  import InfoIcon from "@mui/icons-material/Info";
  import UploadProgress from './UploadProgress';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { useTheme } from '@mui/material/styles';
import CustomTypography from '../../../common/CustomTypography';
//   import {riskScoreUpload} from '../../Services/BackendAPI';
//   import { useSnackbar } from 'notistack';


function LogoUpload() {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('md'));
    const smaller = useMediaQuery(theme.breakpoints.down('sm'));
    // const { enqueueSnackbar } = useSnackbar();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [browseImageUpload,setBrowseImageUpload] = useState(true)
    
    const inputRef=createRef()
    const handleUpload=()=>{
        // setSelectedFile(e.target.files)
        // if (selectedFile.name.substr(selectedFile.name.length - 4) !== '.pdf') {
        //     enqueueSnackbar('only .pdf file aloowed', { variant: 'error' });
        //   } else {
        setIsUploading(true);
        // var formdata = new FormData();
        // formdata.append("risk_dd_file",selectedFile);
        // const body ={
        //     risk_dd_file: formdata
        // }
        // const options={
        //     onUploadProgress:(ProgressEvent)=>{
        //         const {loaded,total} = ProgressEvent;
        //         let percent=Math.floor(loaded * 100/total)
        //         console.log(`${loaded}kb of ${total}kb ${percent}% `)
        //     }
        // }
        // riskScoreUpload(formdata)
        // .then(({data,message,success})=>{
        //     if(success === true){
        //         // enqueueSnackbar(message, { variant: 'success' });
        //         setIsUploading(true);

        //     }else{
        //         // enqueueSnackbar(message, { variant: 'error' });
        //     }
          
        // })
        // .catch((err)=>{
        //     console.log(err);
        // })
    // }
    }

    return (
        <>
        <Grid container  rowSpacing={4}>   
        <Grid item xs={12}>
        <CustomTypography variant={'h5'} fontSize={'1.375rem'}  fontWeight={'800'} text={'Logo Selection'}/>
        </Grid>
            {/* {small && <Grid item xs={8}>
                <Stack direction='row' spacing={1}>
                    <button style={{ fontSize: '0.563rem', color: "#194BFB", cursor: "pointer" }}>GIF</button>
                    <Typography sx={{ color: '#194BFB', fontSize: '0.813rem', fontWeight: '700' }}>View Demo</Typography>
                </Stack>
            </Grid>}     */}
            <Grid item container xs={12} md={8} direction='row' rowSpacing={2}>
            <Grid item xs={12}>
                    <TextField
                        disabled
                        id="input-width-icon-textfield"
                        InputProps={{
                            startAdornment:(
                                <InputAdornment>
                                    <Typography sx={{ color: 'black' }}>{selectedFile?smaller && selectedFile.name.length>15?selectedFile.name.slice(0,15)+'...':selectedFile.name:"File Name Here.. "}</Typography>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton onClick={()=>{
                                        setBrowseImageUpload(true);
                                        setSelectedFile(null)
                                        setIsUploading(false)
                                    }}>
                                        <DeleteRoundedIcon sx={{ cursor: 'pointer', color: '#CDCDCD' }} fontSize='small' />
                                    </IconButton>
                                    <IconButton>
                                        <RefreshRoundedIcon sx={{ cursor: 'pointer', color: '#CDCDCD' }} fontSize='small' />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        fullWidth={small ? true : false}
                    />
                </Grid>
                {
                    browseImageUpload &&
                    <Grid item xs={12}>
                    <Box borderRadius={1} sx={{ border: 'dashed #CDCDCD', borderWidth: '0.125rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh',  }}>
                        <Typography component={'span'} sx={{fontSize:'1rem'}}>Drop your logo, or</Typography>
                        <input 
                        ref={inputRef}
                        accept=".jpg, .jpeg, .png"
                        type="file"
                       style={{ display:'none'}}
                        onChange={(e) => {
                            setSelectedFile(e.target.files[0]);
                            setBrowseImageUpload(false);
                            setIsUploading(true)
                        }}
                      />
                       <Typography component='span' onClick={()=>inputRef.current.click()}  sx={{fontSize:'1rem',fontWeight:'700',borderBottom:'1px solid black ',cursor:'pointer'}}>&nbsp;browse</Typography>
                      
                            {/* <Link  type="file" accept=".csv/!*" sx={{ cursor: 'pointer', fontWeight: 'bold' }} onChange={(e)=>
                                {
                                    setSelectedFile(e.target.files[0]);
                                    handleUpload(e);
                                }}>browse</Link> */}
                           
                    </Box>
                </Grid>
                }
               { isUploading && <Grid item xs={12}>
                    <UploadProgress setSelectedFile={setSelectedFile} setBrowseImageUpload={setBrowseImageUpload} setIsUploading={setIsUploading} isUploading={isUploading}/>
                </Grid>
}
            
            </Grid>

            {/* <Grid item xs={12}>
                <Button
                variant='contained'
                disableElevation
                sx={{ textTransform: 'none', fontWeight: 'bold', backgroundColor:"#194BFB" }}
                size='large'
                fullWidth={small ? true : false}
                onClick={handleUpload}
                >
                    Submit
                </Button>
            </Grid> */}
            
        </Grid>
        </>
    )
}

export default LogoUpload