import React, { useRef, useEffect, useState, Suspense } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  Grid,
  useMediaQuery,
  Box,
  Button,
  Stack,
  Container,
  Link
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PortfolioSegment from "../../PortfolioSegment";
import ScripWiseAnalytics from "../../ScripWiseAnalytics";
import PortfolioPerformance from "../../Dashboard/PortfolioPerformance";
// import { data } from "../../Dashboard/apiData";
import ScenarioManager from "../../ScenarioManager";
import PortfolioStatistics from "../../PortfolioStatistics";
import AnalyserReportSection from "../../Dashboard/AnalyserReportSection";
import { PDFExport, PDFMargin, savePDF } from "@progress/kendo-react-pdf";
import {
  getRiskScoreByID,
  getRiskScoreAPI,
} from "../../../../Services/BackendAPI";
import RecommendedAssets from "../../RecommendedAssets";
import DashboardStepComponent from "../DashboardStepComponent";
import FooterDashboard from "../FooterDashboard";
import { StyledHeadingTypography } from "../../Dashboard/PortfolioPerformance/TitleSection/custom";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  // padding: theme.spacing(3),
  color: theme.palette.text.secondary,
  variant: "outlined",
  boxShadow: "none",
  borderRadius: "0.5625rem",
  // [theme.breakpoints.down("md")]: {
  //   margin: "0px",
  // },
  padding: "0.8rem",
}));

const TextTypography = styled(Typography)(({ theme }) => ({
  fontSize: "24px", 
  color: '#5894F6', 
  fontWeight: 700, 
  fontFamily: "Lato",
  paddingLeft: "1.75rem",
  paddingBottom: "1rem",
  [theme.breakpoints.down("sm")]: {
    // fontSize: "15px",
    paddingLeft: "0px",
    paddingBottom: "0.65rem",
    paddingTop: "0.65rem",
    color: "#525252",
  },
}));

// const [segmentOne, segmentTwo] = data.portfolio_segment.segments;
// const [scriptWiseAnalyticsTableData] =
//   data.script_wise_analytics.level_wise_data;
// const riskAnalyserData = data.risk_analyser;

function DashboardCardPlacement({
  handleExportPDF,
  refPDF,
  enableAsset,
}) {
  const theme = createTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const medium = useMediaQuery(theme.breakpoints.down("md"));
  const [fetchRiskScore, setRiskScore] = useState();
  const [performanceAnalyserData, setPerformanceAnalyserData] = useState();
  const [portfolioPerformance, setPortfolioPerformance] = useState();
  const [portfolioSegmentAsset, setPortfolioSegmentAsset] = useState();
  const [portfolioSegmentMarket, setPortfolioSegmentMarket] = useState();
  const [portfolioStatistics, setPortfolioStatistics] = useState();
  const [scriptWiseAnalytics, setScripWiseAnalytics] = useState();
  const [scenarioManager, setScenarioManager] = useState();
  const getBreakPointData = (small) => {
    return [{ itemsToShow: small ? 1 : 1, itemsToScroll: small ? 1 : 1 }];
  };

  useEffect(() => {
    let body = {
      // "portfolio_id": decodedCookie.split("portfolio_id=")[1]
      portfolio_id: localStorage.getItem("portfolio_id"),
    };

    getRiskScoreByID(body)
      .then(({ data, success, message }) => {
        if (success === true) {
          setPerformanceAnalyserData(data[0].portfolio_analyser);
          setPortfolioPerformance(data[0].portfolio_performance);
          setPortfolioSegmentAsset(data[0].portfolio_segment.segments[0]);
          setPortfolioSegmentMarket(data[0].portfolio_segment.segments[1]);
          setPortfolioStatistics(data[0].portfolio_statistics);
          setScripWiseAnalytics(data[0].script_wise_analytics.level_wise_data);
          setRiskScore(data[0]);
          setScenarioManager(data[0].scenario_manager.data);
        } else {
          console.log(message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <PDFExport
        ref={refPDF}
        paperSize="Legal"
        forcePageBreak=".page-break"
        fileName={`Report_dashboard`}
        author="orim"
        landscape={true}
      >
        {enableAsset !== true ? (
          <>
            <Grid container spacing={2}>
              <Grid
                container
                item
                xs={12}
                md={12}
                lg={8}
                columnSpacing={0}
                rowSpacing={4}
              >
                <Grid item xs>
                  <DashboardStepComponent
                    step={"1"}
                    report={"Risk Profiler"}
                  />
                  <Item>
                    <StyledHeadingTypography
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "14px !important",
                        },
                      }}
                    >
                      Based upon your investment amount of
                      <span
                        style={{
                          color: "#43B747",
                          // textDecoration: "underline",
                          // textDecorationColor: "#70C7FA",
                          // textDecorationStyle:'solid'
                          borderBottom: "3px solid #70C7FA",
                        }}
                      >
                        {" "}
                        ₹10,00,000{" "}
                      </span>
                      & the nature of{" "}
                      <span style={{ color: "#209AE2", fontWeight: 800 }}>
                        your answers
                      </span>{" "}
                      , over 1 year you are well suited with a risk profile that
                      has the below risk score and fluctuates between the
                      following range:
                    </StyledHeadingTypography>
                    <AnalyserReportSection
                      data={performanceAnalyserData}
                      fetchRiskScore={fetchRiskScore}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                  <DashboardStepComponent
                    step={"2"}
                    report={"Portfolio Analyser"}
                  />
                  <Item>
                    <StyledHeadingTypography
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "14px !important",
                        },
                      }}
                    >
                      {/* Over a{" "}
                      <span
                        style={{
                          borderBottom: "3px solid #70C7FA",
                          marginRight: '4px',
                          fontWeight: 600
                        }}
                      >
                        1 year
                      </span>
                      period you are well suited with a portfolio that
                      has the below risk score and fluctuates between the
                      following range: */}
                      Based upon your existing investments over{" "}<span
                        style={{
                          borderBottom: "3px solid #70C7FA",
                          marginRight: '4px',
                          fontWeight: 600
                        }}
                      >
                        1 year
                      </span> your portfolio has the below risk profile which fluctuates between the following
                      range:
                    </StyledHeadingTypography>
                    <AnalyserReportSection
                      data={performanceAnalyserData}
                      fetchRiskScore={fetchRiskScore}
                    />
                  </Item>
                </Grid>
                {small && (
                  <Grid item xs={12} md>
                    <Stack direction="row" justifyContent="space-between">
                      <TextTypography>Recommended Assets</TextTypography>
                      <Button variant="text" sx={{ textTransform: "none" }}>
                        View All
                      </Button>
                    </Stack>

                    <RecommendedAssets />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <TextTypography>Scenario Manager<Tooltip placement="top" title="The Scenario
                  Manager displays how your portfolio would have reacted in historical
                  market movements as per its risk analyses."><InfoIcon /></Tooltip></TextTypography>
                  <Item>
                    <ScenarioManager
                      enableAsset={enableAsset}
                      data={scenarioManager}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextTypography>Portfolio Performance</TextTypography>
                  <Item>
                    <PortfolioPerformance data={portfolioPerformance} />
                  </Item>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextTypography>Portfolio Segments</TextTypography>
                  <Item>
                    <StyledHeadingTypography
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "14px !important",
                          paddingLeft: "0rem",
                        },
                      }}
                    >
                      Asset Class Exposure
                    </StyledHeadingTypography>
                    <PortfolioSegment
                      heading={"asset-class"}
                      data={portfolioSegmentAsset}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Item>
                    <StyledHeadingTypography
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "14px !important",
                          paddingLeft: "0rem",
                        },
                      }}
                    >
                      Market Cap Exposure (Equity)
                    </StyledHeadingTypography>
                    <PortfolioSegment
                      heading={"market-cap"}
                      data={portfolioSegmentMarket}
                    />
                  </Item>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextTypography>Portfolio Statistics</TextTypography>
                  <Item>
                    <PortfolioStatistics data={portfolioStatistics} />
                  </Item>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Item>
                    <ScripWiseAnalytics data={scriptWiseAnalytics} />
                  </Item>
                </Grid>
              </Grid>

              {!small &&
                <Grid item xs={12} md style={{ margin: '0px 30px 0px 30px' }}>
                  <Item sx={{ position: !medium ? "sticky" : "", top: "4rem" }}>
                    <Typography
                      sx={{
                        fontSize: "1.75rem",
                        fontWeight: "800",
                        color: "#000",
                        paddingBottom: "0.75rem",
                        fontFamily: "Lato",
                      }}
                    >
                      Recommended Assets
                    </Typography>
                    <Paper
                      sx={{
                        overflowX: "hidden",
                        overflowY: "scroll",
                        maxHeight: "40rem",
                        boxShadow: "none",
                      }}
                      disableElevation
                    >
                      <RecommendedAssets />
                    </Paper>
                  </Item>
                </Grid>
              }
            </Grid>
            {/* <div style={{ marginTop: "4rem"}}>
            <FooterDashboard
              enableAsset={enableAsset}
              handleExportPDF={handleExportPDF}
              refPDF={refPDF}
            />
          </div> */}
            <div style={{ marginTop: "4rem" }}>
              <Grid
                container
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  container
                  direction={medium ? "column-reverse" : "row"}
                  alignItems="center"
                  justifyContent="center"
                  xs
                  md={7}
                  spacing={5}
                >
                  <Grid item xs={12} md={6} align={!small ? "right" : ""}>
                    <Link href='https://orim.in/faq/#1550669286421-d68bb074-eece' target='_blank' underline="none">
                      <Button
                        // component={Link}
                        // to={`https://orim.in/faq/#1550669286421-d68bb074-eece`}
                        sx={{
                          borderBottom: "1px solid #5894F6",
                          textTransform: "none",
                          fontFamily: "Lato",
                          borderRadius: "0px",
                          "&:hover": {
                            background: "none",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Know More
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} align={!medium ? "" : "center"}>
                    <Button
                      onClick={handleExportPDF}
                      variant="contained"
                      sx={{
                        backgroundColor: "#194BFB",
                        textTransform: "none",
                        fontFamily: "Lato",
                        "&:hover": {
                          backgroundColor: "#194BFB",
                          boxShadow: "none",
                        },
                      }}
                      disableElevation
                      startIcon={<DownloadIcon />}
                    >
                      Download Report
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <PDFMargin top="1cm" left="1in" right="1in" bottom="1cm" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextTypography>Risk Analyser Report</TextTypography>
                <Item>
                  <AnalyserReportSection
                    data={performanceAnalyserData}
                    fetchRiskScore={fetchRiskScore}
                    pdfFormat={true}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={12}>
                <Item>
                  <TextTypography>Portfolio Analyser Report</TextTypography>
                  <AnalyserReportSection
                    data={performanceAnalyserData}
                    fetchRiskScore={fetchRiskScore}
                    pdfFormat={true}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextTypography>Scenario Manager</TextTypography>
                <Item>
                  <ScenarioManager
                    enableAsset={enableAsset}
                    data={scenarioManager}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextTypography>Portfolio Performance</TextTypography>
                <Item>
                  <PortfolioPerformance data={portfolioPerformance} />
                </Item>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextTypography>Portfolio Segments</TextTypography>
                <Item>
                  <StyledHeadingTypography
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "14px !important",
                        paddingLeft: "0rem",
                      },
                    }}
                  >
                    Asset Class Exposure
                  </StyledHeadingTypography>
                  <PortfolioSegment
                    heading={"asset-class"}
                    data={portfolioSegmentAsset}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} md={12}>
                <Item>
                  <StyledHeadingTypography
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "14px !important",
                        paddingLeft: "0rem",
                      },
                    }}
                  >
                    Market Cap Exposure (Equity)
                  </StyledHeadingTypography>
                  <PortfolioSegment
                    heading={"market-cap"}
                    data={portfolioSegmentMarket}
                  />
                </Item>
              </Grid>

              <Grid item xs={12} md={12}>
                <Item>
                  <PortfolioStatistics data={portfolioStatistics} />
                </Item>
              </Grid>

              <Grid item xs={12} md={12}>
                <Item>
                  <ScripWiseAnalytics data={scriptWiseAnalytics} />
                </Item>
              </Grid>
            </Grid>
          </>
        )}
      </PDFExport>
    </ThemeProvider>
  );
}

export default DashboardCardPlacement;
