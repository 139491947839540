import React, { useEffect, useState, useCallback } from "react";
import { getRiskScoreAPI } from "../../../Services/BackendAPI";
import { Typography, Grid, Divider, Card, useMediaQuery } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import PieChart from "./NoResponseComponent";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";

function RiskScore({
  compValues,
  setCompValues,
  // initialValues,
  selectValueType,
  setSelectedValueType,
  symbolValue,
  setSymbolValue,
  portfolioName,
  setPortfolioName,
  portfolioUserName,
  getFreeReport,
  setFreeReport,
}) {
  const [riskScore, setRiskScore] = useState("");
  const [fetchID, setID] = useState("");
  const [fetchValue, setValue] = useState([]);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [correctedCompValue, setCorrectedCompValue] = useState([]);
  const { portfolioDetails } = useSelector((state) => state.securityDetails);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let corrected = compValues.filter((data) => {
      return (
        data.symbol !== "" &&
          data.value !== "" &&
          data.value > 0 &&
          data.category !== "" 
        // && portfolioUserName !==""
      );
    });
    setCorrectedCompValue(corrected);
  }, [compValues]);

  useEffect(() => {

    if(correctedCompValue.length !== 0 )
    debounce(CalculateRiskScore(portfolioName, portfolioUserName), 2000);
  }, [correctedCompValue]);

  const CalculateRiskScore = (portfolioName, portfolioUserName) => {
    let body = {
      portfolio_name: portfolioName,
      portfolio_username: portfolioUserName
        ? portfolioUserName
        : localStorage.getItem("username"),
      value_type: selectValueType,
      requests: correctedCompValue,
    };
        // console.log(body);
    getRiskScoreAPI(body)
      .then(({ data, success, message }) => {
        if(success === true) {
                  // enqueueSnackbar(message || "risk Score fetched", { variant: "success" });
                  setRiskScore(data.portfolio_segment.segments[0].items);
                  setFreeReport(true);
                  localStorage.removeItem("portfolio_id");
                  localStorage.setItem("portfolio_id", data.portfolio_id);
        } else {
            enqueueSnackbar(message, { variant: "error" });
            setRiskScore("");
        }

      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
        setRiskScore("");
      });
  };

  // let timeout;
  // var debounce = function (func, delay) {
  //   clearTimeout(timeout);

  //   timeout = setTimeout(func, delay);
  // };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 1000);
    };
  };

  // const optimizedFn = useCallback(debounce(CalculateRiskScore(portfolioName,portfolioUserName)), []);

  // function sampleFunc(sampleObj) {
  //   let chartData = [];
  //   let chartName = [];

  //   for (var [key, value] of Object.entries(sampleObj)) {
  //     let keyData = key.split("_per")[0];
  //     chartData.push(value);
  //     chartName.push(keyData);
  //   }

  //   return { chartData, chartName };
  // }

  // const data1 = sampleFunc(riskScore);

  const options = {
    chart: {
      type: "pie",
      responsive: {
        rules: [
          {
            condition: {
              // maxWidth: 500
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
            },
          },
        ],
      },
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      // styledMode: true
    },
    title: {
      text: "",
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },

    plotOptions: {
      pie: {
        color: ["#808CFC", "#FF7D44", "#FFD87E", "#53BAFF"],
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:13px">{series.name}</span><br>',
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },

    credits: { enabled: false },

    series: [
      {
        name: "Risk Score",
        colorByPoint: true,
        // data: data1.chartData,

        data: [
          {
            name: `<span style="font-size:1.0625rem">${Number(
              riskScore.equity_per
            ).toFixed()}</span>% <br/ > <span  style="color: #A0AEC0;font-size:1.0625rem" >Equity</span><br/>
            <div>&nbsp;</div>`,
            y: riskScore.equity_per,
          },
          {
            name: `<span style="font-size:1.0625rem">${Number(
              riskScore.mutual_fund_per
            ).toFixed()}</span>% <br/ > <span  style="color: #A0AEC0;font-size:1.0625rem" >Mutual Funds</span>`,
            y: riskScore.mutual_fund_per,
          },
          {
            name: `<span style="font-size:1.0625rem">${Number(
              riskScore.real_estate_per
            ).toFixed()}</span>% <br/ > <span  style="color: #A0AEC0;font-size:1.0625rem" >Real Estate</span>`,
            y: riskScore.real_estate_per,
          },
          {
            name: `<span style="font-size:1.0625rem">${Number(
              riskScore.commodity_per
            ).toFixed()}</span>% <br/ > <span  style="color: #A0AEC0;font-size:1.0625rem" >Commodity</span>`,
            y: riskScore.commodity_per,
          },
        ],
      },
    ],
  };
  Highcharts.setOptions({
    colors: ["#FF7D44", "#808CFC", "#FFD87E", "#53BAFF"],
  });
  return (
    <Card variant={small ? "outlined" : ""}>
      {riskScore !== undefined && riskScore !== null && riskScore !== "" ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <PieChart />
      )}
    </Card>
  );
}

export default RiskScore;
