import { Close } from '@mui/icons-material';
import { Backdrop, Modal, Typography,Fade, Divider, Grid, IconButton, Stack, useMediaQuery, TextField, styled, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react'
import CustomTypography from '../../common/CustomTypography';
import RiskScore from '../../RiskScore';
import RiskScoreTable from '../../RiskScoreTable';
import { ColorPicker, useColor } from "react-color-palette";
import { CustomButton } from '../../body/investmentTabSection/AddNewInvestment';
  const iconButtonStyle={
    position:'absolute',
    top:'1rem',
    right:'1.1rem'
  }
  const iconStyle = {
    fontSize:'1.1rem',
    color:'#000'
  };
  const StyledTextfield = styled(TextField)(({ theme, myColor }) => ({
    backgroundColor: myColor,
    width: '100%',
    fieldset:{
        borderRadius:'10px'
    }
  }));
  
function ColorPickerModal({open,handleOpen,handleClose,color,setColor}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const medium = useMediaQuery(theme.breakpoints.down("lg"));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: small?'80%':'50%',
    maxHeight:'70%',
    bgcolor: '#FFF',
    px: 6,
    py:4,
    borderRadius:'1.25rem',
    display: "flex",
    flexDirection: "column",
    overflow:'auto',
    '::-webkit-scrollbar': {
     width: '10px',
     display: 'block',
     height: '5px',
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      height:'10px',
      backgroundColor: "#ccc",
      
    },
    '::-webkit-scrollbar-button': {
      height: '10px',
     },

    // overflowY: small?"scroll":'hidden',
    // overflowX:small?"scroll":'hidden',
  };
  return (
    <>
       <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition

        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box   sx={style}  >
            <Stack direction='row' alignItems='center' justifyContent={'space-between'}>
                <CustomTypography variant={'h5'} text={'Pick a color'}/>
                <IconButton  onClick={handleClose}>
              <Close sx={iconStyle} />
            </IconButton>
            </Stack>
            <Grid  container py={3} columnSpacing={3} alignItems={'center'} justifyContent={'space-around'}>
            <ColorPicker width={medium?small?200:280:456} height={medium?small?120:160:228} 
                   color={color} 
                   onChange={setColor} hideHSV dark />
                  
                   <Stack spacing={5}>
                        <CustomTypography variant={'h6'} text={'Selected color'}/>
                    <Box  sx={{backgroundColor:color.hex,
                    height:'100px',
                    borderRadius:'7px'}}>
                    </Box>
                    <CustomButton onClick={handleClose} sx={{color:'#FFF',width:'100%'}}>Done</CustomButton>
                   </Stack>
                   </Grid>
            {/* <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
            {/* <IconButton sx={iconButtonStyle} onClick={handleClose}>
              <Close sx={iconStyle} />
            </IconButton> */}
          </Box>
        </Fade>
      </Modal> 
    </>
  )
}

export default ColorPickerModal