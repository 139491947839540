import React,{useState, useEffect} from 'react';
import { Typography, Button, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import ScenarioCard from './ScenarioCard';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TabButton from '../CommonComponents/TabButton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          children
        )}
      </div>
    );
  }

function ScenarioManager({enableAsset,data}) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const [scenarioData, setScenarioData] = useState([]);


    useEffect(() => {
      if(data !== undefined){
         setScenarioData(data);
      }
    }, [data]);
    // console.log(scenarioData);

    return (
      <>
        {!enableAsset ? (
          <Stack spacing={2}>
            <Typography
              align="left"
              sx={{
                color: "#242424",
                fontSize: small ? "0.875rem" : "1.25rem",
                fontWeight: 500,
              }}
            >
              How your portfolio would have reacted during various market scenario’s?
            </Typography>
            <Stack direction="row" spacing={2}>
              {scenarioData !== undefined &&
                scenarioData.map((label, index) => {
                  return (
                    <TabButton
                      active={index === value ? true : false}
                      text={label.display_name}
                      onClick={() => setValue(index)}
                    />
                  );
                })}
            </Stack>
            {scenarioData !== undefined &&
              scenarioData.map((val, index) => (
                <TabPanel value={value} index={index} key={index}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item xs={12} md>
                      <ScenarioCard
                        risk={val.risk_score}
                        heading={val.portfolio_name}
                        percent={val.portfolio_return.toFixed(2) + "%"}
                        label={"Expected Performance"}
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <ScenarioCard
                        risk={val.risk_score}
                        heading={val.scenario_index.scenario_name}
                        percent={
                          val.scenario_index.index_return.toFixed(2) + "%"
                        }
                        label={"Actual Performance"}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              ))}
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Typography
              align="left"
              sx={{
                color: "#242424",
                fontSize: small ? "0.875rem" : "1.25rem",
                fontWeight: 500,
              }}
            >
              How your portfolio would have reacted during various market scenario’s?
            </Typography>

            {scenarioData !== undefined &&
              scenarioData.map((val, index) => (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    key={index}
                  >
                    <Grid item xs={12}>
                      <Typography variant='h4'>{val.display_name}
                      </Typography>
                    </Grid>

                    <Grid item xs>
                      <ScenarioCard
                        risk={val.risk_score}
                        heading={val.portfolio_name}
                        percent={val.portfolio_return.toFixed(2) + "%"}
                        label={"Expected Performance"}
                      />
                    </Grid>

                    <Grid item xs>
                      <ScenarioCard
                        risk={val.risk_score}
                        heading={val.scenario_index.scenario_name}
                        percent={
                          val.scenario_index.index_return.toFixed(2) + "%"
                        }
                        label={"Actual Performance"}
                      />
                    </Grid>
                  </Grid>
              
                </>
              ))}
          </Stack>
        )}
      </>
    );
}

export default ScenarioManager