import React from 'react'
import {Bar} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto'
import {Chart} from 'react-chartjs-2'
import '../../CommonComponents/fontFamilyLato.css'
import {Menu, MenuItem, Stack, Typography, useMediaQuery,Box} from '@mui/material'
import {ArrowDropDown, KeyboardArrowDown} from '@mui/icons-material'
import {useState} from 'react'
import { useTheme } from '@mui/styles'
import {
  utcTimestampToDateString,
  splitDate,
} from "../../../../Services/Utilities";


function PortfolioPerformanceChart({data}) {
    const data1 = {
      labels: ['1M', '3M', '6M', '1Y', '3Y', '5Y', '10Y'],
        // data &&
        // data.historical_return &&
        // data.historical_return.map((item) => item.month),
      datasets: [
        {
          label: "Portfolio returns",
          backgroundColor: "#194BFB",
          borderRadius: 5,
          // stack: 1,
          data:
            data &&
            data.historical_return &&
            data.historical_return.map((item) =>
              Math.floor(item.portfolio_return.toFixed(2) * 100)
            ),
        },
        {
          label: "Index returns",
          backgroundColor: "#C7D2FE",
          borderRadius: 5,
          // stack: 1,
          data:
            data &&
            data.historical_return &&
            data.historical_return.map((item) =>
              Math.floor(item.index_return.toFixed(2) * 100)
            ),
        },
      ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                labels: { // This more specific font property overrides the global property
                    display: false
                }
            }
        },
        scales: {
            y: {
                ticks: { // Include a dollar sign in the ticks
                    callback: function (value, index, ticks) {
                        return value + "% ";
                    },
                    color: '#242424',
                    font: {
                        size: '15',
                        // family:'Lato'
                    },
                    // font: function(context) {
                    //     var width = context.chart.width;
                    //     var size = Math.round(width / 32);

                    //     return {
                    //         weight: 'bold',
                    //         size: size
                    //     };
                    // }
                }

            },
            x: {
                ticks: {
                    // Include a dollar sign in the ticks
                    // callback: function(value, index, ticks) {
                    //     return  value + "% ";
                    // },
                    color: '#242424',
                    font: {
                        size: '15',
                        // family:'Lato'
                    }
                },
                grid: {
                    display: false
                }
            }
        },
        responsive: true,
        maintainAspectRatio:false,
        legend: {
            display: false
        },

        type: "bar"
        // scales: {
        // xAxes: [
        //     {
        //       stacked: true
        //     }
        // ],
        // yAxes: [
        //     {
        //       stacked: true
        //     }
        // ]
        // }
    };
   
    return (
        <Box
         sx={{height:'35rem'
      }}
         >
           
            <Bar data={data1} 
                options={options}/>
         </Box>
    )
}

export default PortfolioPerformanceChart
