import React,{useState,useEffect} from "react";
import "./App.css";
import MainLandingPage from "./Components/Main/MainLanding";
import MainDashboardPage from "./Components/Main/MainDashboard/DashboardPage";
import Button from '@mui/material/Button';
import { SnackbarProvider } from 'notistack';
import ResponsiveDrawer from './Components/UIForAdmin/main/CustomDrawer';

function App() {
  const snackbarRef = React.createRef();
  const onClickDismiss = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };
  const [currentDashboardView, setCurrentDashboardView] = useState("CLIENT_DASHBOARD");
  
  const componentToRender = {
    CLIENT_DASHBOARD: <MainDashboardPage />,
    ADMIN_DASHBOARD: <ResponsiveDrawer />,
  };

  useEffect(() => {
    if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "ADMIN"
    ) {
      setCurrentDashboardView("ADMIN_DASHBOARD");
    } else if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "SUPERADMIN"
    ) {
      setCurrentDashboardView("");
    } else {
      setCurrentDashboardView("CLIENT_DASHBOARD");
    }
  }, [currentDashboardView]);

  return (
    <div>
      <SnackbarProvider
        maxSnack={3}
        ref={snackbarRef}
        action={(key) => (
          <Button
            sx={{ color: "#fff" }}
            size="small"
            onClick={onClickDismiss(key)}
          >
            Dismiss
          </Button>
        )}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {localStorage.getItem("token") ? (
          <MainDashboardPage />
        ) : (
          // componentToRender[currentDashboardView]
          <MainLandingPage />
        )}
      </SnackbarProvider>
    </div>
  );
}

export default App;
