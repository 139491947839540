import React, { useState, useEffect, createRef } from 'react';
import { Button, Typography, Grid, FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { getCommodityOptions, uploadCommodityOptions, uploadRealEstateData } from '../../../Services/BackendAPI';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSnackbar } from "notistack";
import TextFieldComponent from '../../SignUp/CommonComponent/TextFieldComponent';

const CSVUpload = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const small = useMediaQuery(theme.breakpoints.down('md'));
  const smaller = useMediaQuery(theme.breakpoints.down('sm'));
  const inputRef = createRef();
  const reRef = createRef();
  // const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedREFile, setSelectedREFile] = useState(null);
  const [commodities, setCommodities] = useState([]);
  const [realEstateSymbol, setRealEstateSymbol] = useState("");
  const [selectedCommodity, setSelectedCommodity] = useState([]);

  const handleChange = (event) => {
    setSelectedCommodity(event.target.value);
  };

  useEffect(() => {
    getCommodityOptions()
    .then(({data}) => {
      setCommodities(data);
      setSelectedCommodity(data[0]);
    })
    .catch(err => console.log("Error:", err));
  }, []);

  return (
    <Grid container>
     <Grid 
      item xs
      sx={{
        backgroundColor: "white",
        marginTop: "20px",
        margin: "2rem 4rem 2rem 4rem",
        borderRadius: "20px",
        boxShadow: "none",
        padding: "1.5rem",
        [theme.breakpoints.down("md")]: {
          margin: "1.8rem",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs>
          <Typography variant="h4">
            Commodity Upload 
          </Typography>
        </Grid>
        <Grid item xs>
          <FormControl sx={{ minWidth: 200 }} size="medium">
            <InputLabel id="demo-select-small-label">Commodity</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedCommodity}
              label="Age"
              onChange={handleChange}
            >
              {commodities.map((commodity, idx) => <MenuItem key={idx} value={commodity}>{commodity.replaceAll("-"," ").split(" ")[0].toUpperCase()}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
              disabled
              id="input-width-icon-textfield"
              InputProps={{
                  startAdornment:(
                      <InputAdornment>
                          <Typography sx={{ color: 'black' }}>{selectedFile?smaller && selectedFile.name.length>15?selectedFile.name.slice(0,15)+'...':selectedFile.name:"File Name Here.. "}</Typography>
                      </InputAdornment>
                  ),
                  endAdornment: (
                      <InputAdornment>
                          <IconButton onClick={()=>{
                              setSelectedFile(null)
                          }}>
                              <DeleteIcon sx={{ cursor: 'pointer', color: '#CDCDCD' }} fontSize='small' />
                          </IconButton>
                      </InputAdornment>
                  )
              }}
              fullWidth={small ? true : false}
          />
          
          <Grid item xs={12} borderRadius={1} sx={{ border: 'dashed #CDCDCD', borderWidth: '0.125rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh', marginTop: '32px' }}>
              <Typography component={'span'} sx={{fontSize:'1rem'}}>Drop your file, or</Typography>
              <input 
              ref={inputRef}
              type="file" 
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display:'none'}}
              onChange={(e) => {
                  setSelectedFile(e.target.files[0]);
              }}
            />
              <Typography component='span' onClick={()=>inputRef.current.click()}  sx={{fontSize:'1rem',fontWeight:'700',borderBottom:'1px solid black ',cursor:'pointer'}}>&nbsp;browse</Typography>
            
                  {/* <Link  type="file" accept=".csv/!*" sx={{ cursor: 'pointer', fontWeight: 'bold' }} onChange={(e)=>
                      {
                          setSelectedFile(e.target.files[0]);
                          handleUpload(e);
                      }}>browse</Link> */}
                  
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={!selectedFile}
            sx={{
              backgroundColor: "#194BFB",
              borderRadius: "8px",
              textTransform: "none",
              // padding: "0.5rem 3.5rem 0.5rem 3.5rem",
              fontSize: "17px",
              fontWeight: 700,
              "&:hover": {
                backgroundColor: "#194BFB",
              },
            }}
            disableElevation
            onClick={async() => {
              let bodyFormData = new FormData();
              bodyFormData.append(selectedCommodity, selectedFile); 
              const res = await uploadCommodityOptions(bodyFormData);
              if(res.success){
                enqueueSnackbar(res.message, { variant: "success" });
              }
            }}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      item xs
      sx={{
        backgroundColor: "white",
        marginTop: "20px",
        margin: "2rem 4rem 2rem 4rem",
        borderRadius: "20px",
        boxShadow: "none",
        padding: "1.5rem",
        [theme.breakpoints.down("md")]: {
          margin: "1.8rem",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs>
          <Typography variant="h4">
            Real Estate Upload
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextFieldComponent
            onChange={e=>setRealEstateSymbol(e.target.value)}
            name={"Symbol"}
            label={"Symbol"}
            value={realEstateSymbol}
            sx={{ minWidth: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
              disabled
              id="input-width-icon-textfield"
              InputProps={{
                  startAdornment:(
                      <InputAdornment>
                          <Typography sx={{ color: 'black' }}>{selectedREFile?smaller && selectedREFile.name.length>15?selectedREFile.name.slice(0,15)+'...':selectedREFile.name:"File Name Here.. "}</Typography>
                      </InputAdornment>
                  ),
                  endAdornment: (
                      <InputAdornment>
                          <IconButton onClick={()=>{
                              setSelectedREFile(null)
                          }}>
                              <DeleteIcon sx={{ cursor: 'pointer', color: '#CDCDCD' }} fontSize='small' />
                          </IconButton>
                      </InputAdornment>
                  )
              }}
              fullWidth={small ? true : false}
          />
          <Grid item xs={12} borderRadius={1} sx={{ border: 'dashed #CDCDCD', borderWidth: '0.125rem', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh', marginTop: '32px' }}>
              <Typography component={'span'} sx={{fontSize:'1rem'}}>Drop your file, or</Typography>
              <input 
              ref={reRef}
              accept=".pdf"
              type="file"
              style={{ display:'none'}}
              onChange={(e) => {
                setSelectedREFile(e.target.files[0]);
              }}
            />
              <Typography component='span' onClick={()=>reRef.current.click()}  sx={{fontSize:'1rem',fontWeight:'700',borderBottom:'1px solid black ',cursor:'pointer'}}>&nbsp;browse</Typography>
            
                  {/* <Link  type="file" accept=".csv/!*" sx={{ cursor: 'pointer', fontWeight: 'bold' }} onChange={(e)=>
                      {
                          setSelectedFile(e.target.files[0]);
                          handleUpload(e);
                      }}>browse</Link> */}
                  
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={!selectedREFile || !realEstateSymbol}
            sx={{
              backgroundColor: "#194BFB",
              borderRadius: "8px",
              textTransform: "none",
              // padding: "0.5rem 3.5rem 0.5rem 3.5rem",
              fontSize: "17px",
              fontWeight: 700,
              "&:hover": {
                backgroundColor: "#194BFB",
              },
            }}
            disableElevation
            onClick={async() => {
              let bodyFormData = new FormData();
              bodyFormData.append("real-estate-file", selectedREFile);
              bodyFormData.append("symbol", realEstateSymbol);
              const res = await uploadRealEstateData(bodyFormData);
              if(res.success){
                enqueueSnackbar(res.message, { variant: "success" });
              }
            }}
          >
            Upload
          </Button>
        </Grid>
      </Grid>  
    </Grid>
    </Grid>
  )
}

export default CSVUpload