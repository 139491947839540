import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';


const useStyles = makeStyles(() => ({
  circle: {
    stroke: "url(#linearColors)",
  },
}));

export default function GradientCircularProgress() {
  const classes = useStyles({});

  return (
    <>
      <svg width="300" height="300">
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#000"/>
          <stop offset="90%" stopColor="#F3F" />
        </linearGradient>
      </svg>
      <CircularProgress
        thickness={4}
        classes={{ circle: classes.circle }}
      />
    </>
  );
}