import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "@mui/material/styles";
import { dashboardThemes } from "./themeDashboard";
import { Provider } from "react-redux";
import Store from "./Store/store";

const root = createRoot(document.getElementById("root"));
const history = createBrowserHistory();
root.render(
  <Provider store={Store}>
    <Router history={history}>
      <ThemeProvider theme={dashboardThemes}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>
);
