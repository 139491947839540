import { Box, Grid, Stack, Typography } from "@mui/material";
import React,{useState,useEffect} from "react";
import AssetClassExposure from "../Charts/PortfolioSegmentsChart";
import PortFolioSegemntsTable from "../Tables/PortfolioSegments";
import "../CommonComponents/fontFamilyLato.css";
import { StyledHeadingTypography } from "../Dashboard/PortfolioPerformance/TitleSection/custom.jsx";
// import {StyledHeadingTypography}  from '../CommonComponents/CardTitleText/index';
import { useTheme } from "@mui/material/styles";

function PortfolioSegment({ heading, data }) {
  const theme = useTheme();
  const [segmentData, setSegmentData]=useState([])
// console.log(data);
useEffect(() => {
  if(data !== undefined) {
    setSegmentData(data.items);
  }
 
}, [data]);

// console.log(segmentData);

  
  return (
    <>
      {data !== undefined ? (
        <Grid
          container
          rowSpacing={{ xs: 3 }}
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid item xs={12} md align="center">
            <AssetClassExposure
              data={data}
              heading={heading}
              segmentData={segmentData}
            />
          </Grid>
          <Grid item xs={12} md>
            <PortFolioSegemntsTable
              data={data}
              heading={heading}
              segmentData={segmentData}
            />
          </Grid>
        </Grid>
      ) : (
        <div>"Nothing to Show"</div>
      )}
    </>
  );
}

export default PortfolioSegment;
