import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import {Chart,Tooltip,Title,ArcElement,Legend} from 'chart.js';
import { Box } from '@mui/material';
import {getPortfolioSegmentTableData} from "../../../Services/Utilities/getPortfolioSegmentTableData";
Chart.register(ArcElement);

function AssetClassExposure({ data, heading, segmentData }) {

const tableData =
  heading === "asset-class"
    ? [
        {
          id: 1,
          name: "Equity",
          allocation: segmentData.equity_per,
        },
        {
          id: 2,
          name: "Commodity",
          allocation: segmentData.commodity_per,
        },
        {
          id: 3,
          name: "Mutual Funds",
          allocation: segmentData.mutual_fund_per,
        },
        {
          id: 3,
          name: "Real Estate",
          allocation: segmentData.real_estate_per,
        },
      ]
    : [
        {
          id: 1,
          name: "large Cap",
          allocation: segmentData.large_cap,
        },
        {
          id: 2,
          name: "Mid Cap",
          allocation: segmentData.mid_cap,
        },
        {
          id: 3,
          name: "Small Cap",
          allocation: segmentData.small_cap,
        },
      ];


  const chartJsData = {
    labels: tableData.map((item) => item.name),
    datasets: [
      {
        label: "My First Dataset",
        data: tableData.map((item) => item.allocation),
        backgroundColor: ["#F5B41D", "#2754F1", "#D1D1D1", "#ff00008f"],
        hoverBackgroundColor: ["#d79e19", "#1d4ae4", "#a39e9e", "red"],
        hoverOffset: 3,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <Box sx={{ maxWidth: "16rem" }}>
      <Doughnut data={chartJsData} options={options} />
    </Box>
  );
}

export default AssetClassExposure