import { OutlinedInput } from '@mui/material'
import React from 'react'
import styled from 'styled-components';
const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));
function CustomOutlinedInput({placeholder,name,value,onChange, type='text',fullWidth=true}) {
  return (
    <>
    <StyledOutlinedInput autoComplete='off' type={type} onChange={onChange} name={name} value={value} placeholder={placeholder} fullWidth={fullWidth}/>
    </>
  )
}

export default CustomOutlinedInput