import {
  Button,
  Card,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import ClientSection from "../ClientSection";
import MapNewInvestment from "../InvestmentOptionMapperSection/MapNewInvestment";
import AddNewInvestment, {
  CustomButton,
} from "../investmentTabSection/AddNewInvestment";
import SettingsSection from "../SettingsSection";
import { styled } from "@mui/system";
import CustomTypography from "../../common/CustomTypography";
import orimlogo from "../../../../assets/AssetsForAdmin/ORIMFinal.png";
import excelUpload from "../../../../assets/AssetsForAdmin/excelUpload.png";
import CustomImageTextButton from "../../common/CustomImageTextButton";
import UserTable from "../../Table/UserTable";
import InvestmentTable from "../../Table/InvestmentTable";
// const iconsObj=
// {
//   'Dashboard':'',
//   'Client': <ClientSection/>,
//   'Investment':<AddNewInvestment/>,
//   'Investment Option Mapper':<MapNewInvestment/>,
//   'Settings':<SettingsSection/>,
// }

const StyledPaper = styled(Paper)((props) => {
  return {
    // py:{
    //   md:4,
    //   xs:2
    // },
    // px:{
    //   xs:2,
    //   md:8
    // },
    boxShadow: "none",
    minHeight: "80vh",
    // fontSize:'1.1rem',
    // color:'#101010',
    // backgroundColor:'#FFF',
    // backgroundColor:'#FFF',
    // textTransform:'capitalize',
    // borderRadius:'1.25rem',
    // padding:'0.3rem 1.5rem',
    // border:`1px solid ${props.active?'#29CC97':'#E2E8F0'}`,
    // [props.theme.breakpoints.down('sm')]: {
    //   fontSize: "0.75rem"
    // },
  };
});

function Body({ selectedTab }) {
  return (
    <>
      {selectedTab == 2 && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item pl={5}>
            <CustomTypography
              text="Investment Option"
              variant="h5"
              fontWeight="800"
            ></CustomTypography>
          </Grid>
          <Grid item>
            <Grid container pb={2} justifyContent="flex-end" columnGap={3}>
              <CustomImageTextButton
                img={excelUpload}
                text={"Export to Excel"}
              />
              <Link style={{ textDecoration: "none" }} to="/add-new-investment">
                <CustomButton
                  disableRipple
                  sx={{ px: 4, py: "0.5rem" }}
                  variant="contained"
                  disableElevation
                >
                  + Add New Investment{" "}
                </CustomButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
      <StyledPaper
        sx={{
          py: {
            md: 4,
            xs: 2,
          },
          px: {
            xs: 2,
            md: 8,
          },
        }}
      ></StyledPaper>
    </>
  );
}

export default Body;
