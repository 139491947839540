import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import logo from "../../assets/Group2543.png";
import banner from "../../assets/Group2542.png";
import SocialSignUpButton from "./CommonComponent/SocialSignUpButton";
import googleIconImage from "../../assets/image33.png";
import facebookIconImage from "../../assets/image35.png";
import { Snackbar, Stack, useMediaQuery } from "@mui/material";
import FbGoogleGrid from "./CommonComponent/GridContainerFortwo";
import { useTheme } from "@mui/material/styles";
import SignUp, { FooterText } from "./SignUp";
import SignIn from "./SignIn";
import mobLogo from "../../assets/mobileLogo.png";
import MuiAlert from "@mui/material/Alert";
import { Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";

const theme = createTheme();
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SignUpComponent() {
  const themev1 = useTheme();
  const matches = useMediaQuery(themev1.breakpoints.down("sm"));
  const [activeRouteIndex, setActiveRouteIndex] = useState("SIGN_UP");

  const authScreensToRender = {
    SIGN_UP: <SignUp setActiveRouteIndex={setActiveRouteIndex} />,
    SIGN_IN: <SignIn setActiveRouteIndex={setActiveRouteIndex} />,
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          height: "100vh",
          padding: "1.8rem",
          background: "#fff",
        }}
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        {!matches && (
          <Grid
            order={{ sm: 2, md: 1 }}
            item
            xs={false}
            sm={6}
            md={6}
            sx={{
              backgroundColor: "#194BFB",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ p: " 1.5rem" }}
            >
              <Grid item xs>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "70%",
                  }}
                />
              </Grid>
              <Grid
                item
                xs
                alignSelf={"center"}
                sx={{
                  paddingTop: "20%",
                }}
              >
                <img
                  src={banner}
                  alt="banner"
                  style={{
                    height: "80%",
                  }}
                />
              </Grid>
              <Grid item xs alignSelf={"center"}>
                <Typography
                  align={"center"}
                  sx={{
                    color: "#F5F5F5",
                    fontSize: "2.1875rem",
                    fontWeight: 700,
                    fontFamily: "Lato",
                  }}
                >
                  Perfect, Easy & Quick
                </Typography>
                <Typography
                  align={"center"}
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "0.9375rem",
                    fontWeight: 400,
                    fontFamily: "Lato",
                  }}
                >
                  We show you the path for optimum investing!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container item order={{ sm: 1, md: 2 }} xs={12} md={6}>
          <Grid
            container
            direction={"column"}
            sx={{
              px: {
                sm: 4,
                md: 8,
                lg: 15,
              },
            }}
            justifyContent="center"
          >
            <Grid item>
              <Stack
                sx={{
                  gap: {
                    xs: 2,
                    sm: 3,
                  },
                }}
              >
                {matches && (
                  <Box display="flex" justifyContent="center" pb={2}>
                    <img src={mobLogo} alt="logo" />
                  </Box>
                )}
                {/* {authScreensToRender[activeRouteIndex]} */}
                <Routes>
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/signin" element={<SignIn />} />
                  <Route path="*" element={<Navigate to="signup" />} />
                </Routes>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            px={5}
            item
            alignSelf={"flex-end"}
            justifyContent="space-between"
          >
            <FooterText><Link href="https://app.orim.in/terms-condition" underline="none" target="_blank">Privacy Policy</Link></FooterText>
            <FooterText>copyright 2022</FooterText>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
