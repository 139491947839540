import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button, Divider, Grid, TextField, useMediaQuery } from "@mui/material";
import googleIconImage from "../../assets/image33.png";
import facebookIconImage from "../../assets/image35.png";
import HeadingTextComponent from "./CommonComponent/HeadingTextComponent";
import TextFieldComponent from "./CommonComponent/TextFieldComponent";
import SubmitButton from "./CommonComponent/SubmitButton";
import { Link } from "react-router-dom";
import SocialSignUpButton from "./CommonComponent/SocialSignUpButton";
import GridContainerForTwo from "./CommonComponent/GridContainerFortwo";
import { orimSignUp } from "../../Services/BackendAPI";
import useTheme from "@mui/material/styles/useTheme";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { LinkedCamera } from "@mui/icons-material";

const theme = createTheme();

export const FooterText = styled(Typography)(({ theme }) => ({
  color: "#718096",
  fontSize: "0.8rem",
}));

export const MobFooterText = styled(Typography)(({ theme }) => ({
  color: "#194BFB",
  fontSize: "0.6rem",
  fontWeight: "600",
  textAlign: "center ",
}));

const routerrender = (label) => <h1>{label}</h1>;
export default function SignUp() {
  // console.log("setActiveRouteIndex", setActiveRouteIndex);
  const { enqueueSnackbar } = useSnackbar();
  const [textFieldValues, setTextFieldValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    rePassword: "",
  });
  const themev1 = useTheme();
  const matches = useMediaQuery(themev1.breakpoints.down("sm"));
  const textInputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "firstName") {
      setTextFieldValues({
        ...textFieldValues,
        firstName: value,
      });
      return;
    } else if (name === "lastName") {
      setTextFieldValues({
        ...textFieldValues,
        lastName: value,
      });
      return;
    } else if (name === "email") {
      setTextFieldValues({
        ...textFieldValues,
        email: value,
      });
      return;
    } else if (name === "phone") {
      setTextFieldValues({
        ...textFieldValues,
        phone: value,
      });
      return;
    } else if (name === "password") {
      setTextFieldValues({
        ...textFieldValues,
        password: value,
      });
      return;
    } else if (name === "rePassword") {
      setTextFieldValues({
        ...textFieldValues,
        rePassword: value,
      });
      return;
    }
  };
  const onSignUpSubmitHandler = () => {
    if (textFieldValues.firstName === "") {
      enqueueSnackbar("please check First Name", { variant: "error" });
      return;
    } else if (textFieldValues.lastName === "") {
      enqueueSnackbar("please check Last Name", { variant: "error" });
      return;
    } else if (textFieldValues.email === "") {
      enqueueSnackbar("please check email", { variant: "error" });
      return;
    } else if (textFieldValues.phone === "") {
      enqueueSnackbar("please check phone", { variant: "error" });
      return;
    } else if (textFieldValues.password === "") {
      enqueueSnackbar("please check password", { variant: "error" });
      return;
    } else if (textFieldValues.rePassword === "") {
      enqueueSnackbar("please check password", { variant: "error" });
      return;
    }
    let payload = {
      username: textFieldValues.email,
      password: textFieldValues.password,
      first_name: textFieldValues.firstName,
      last_name: textFieldValues.lastName,
      phone_number: textFieldValues.phone,
      // "phone_number":
    };
    orimSignUp(payload)
      .then(({ data, error }) => {
        if (error.code === 0) {
          // console.log("signup", data);
          enqueueSnackbar(error.message, { variant: "success" });
          localStorage.setItem("token", data.auth_token);
          localStorage.setItem("username", data.username);
          localStorage.setItem("name", data.name);
          window.location.reload();
        } else {
          enqueueSnackbar(error.message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
      });
    // window.localStorage.reload();
    // window.location.reload();
  };
  return (
    <>
      <HeadingTextComponent
        heading={"Your portfolio analytics are waiting for you"}
        subHeading={"Sign up Now for an account"}
      />

      {/* <GridContainerForTwo>
        <SocialSignUpButton
          src={googleIconImage}
          text={"Sign Up with Google  "}
        />
        <SocialSignUpButton
          src={facebookIconImage}
          text={"Sign Up with Facebook"}
        />
      </GridContainerForTwo>
      <Divider color="#E6EBF2">
        <Typography
          align="center"
          color="#718096"
          sx={{
            fontSize: "0.85rem",
            fontWeight: "500",
          }}
        >
          Or With Email
        </Typography>
      </Divider> */}
      <GridContainerForTwo>
        <TextFieldComponent
          onChange={textInputHandler}
          name={"firstName"}
          label={"First Name"}
          value={textFieldValues.firstName}
        />
        <TextFieldComponent
          name={"lastName"}
          label={"Last Name"}
          onChange={textInputHandler}
          value={textFieldValues.lastName}
        />
      </GridContainerForTwo>
      {/* <TextField id="outlined-basic"  name={"email"} label="Outlined" variant="outlined" onChange={textInputHandler} value={textFieldValues.email} /> */}
      <TextFieldComponent
        name={"email"}
        type={"email"}
        label={"Email"}
        onChange={textInputHandler}
        value={textFieldValues.email}
      />
      <TextFieldComponent
        name={"phone"}
        type={"number"}
        label={"Phone"}
        onChange={textInputHandler}
        value={textFieldValues.phone}
      />
      <TextFieldComponent
        name={"password"}
        type={"password"}
        label={"Password"}
        onChange={textInputHandler}
        value={textFieldValues.password}
      />
      <TextFieldComponent
        name={"rePassword"}
        type={"password"}
        label={"Re- Enter Password"}
        onChange={textInputHandler}
        value={textFieldValues.rePassword}
      />
      <Box
        px={{
          xs: 3,
          md: 6,
        }}
      >
        <Typography
          align="center"
          color="#718096"
          sx={{
            fontSize: {
              xs: "0.6rem",
              md: "0.85rem",
            },
            letterSpacing: "0.05em",
          }}
        >
          Be creating an account, you agreeing to our{" "}
          <span
            style={{
              color: matches ? "#194BFB" : "#020202",
              fontWeight: "600",
            }}
          >
            Privacy Policy
          </span>
          ,{/* </span> */} {matches ? "& " : "and "}
          <span
            style={{
              color: matches ? "#194BFB" : "#020202",
              fontWeight: "600",
            }}
          >
            Electronics Communication Policy.
          </span>
        </Typography>
        {matches && (
          <MobFooterText>
            Privacy Policy<span style={{ color: "#718096" }}>&</span> Copyright
            2022{" "}
          </MobFooterText>
        )}
      </Box>
      <SubmitButton text={"Sign Up"} onClick={onSignUpSubmitHandler} />
      <Typography align="center" sx={{ fontSize: "0.85rem" }}>
        Already have an account?
        <Link
          to="/auth/signin"
          style={{
            color: "#020202",
            fontWeight: "700",
            fontSize: "14px",
            fontFamily: "Lato",
            cursor: "pointer",
            paddingLeft: "4px",
          }}
          // onClick={() => setActiveRouteIndex("SIGN_IN")}
        >
          {" "}
          Sign In
        </Link>
      </Typography>
    </>
  );
}
