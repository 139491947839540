import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Button,
  useMediaQuery,
  SwipeableDrawer,
  Collapse,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import {
  useNavigate,
  Routes,
  Link,
  Navigate,
  Route,
  useLocation,
} from "react-router-dom";
import { Typography, Grid, IconButton, Container } from "@mui/material";
import DashboardCardPlacement from "../UIForClient/CommonComponents/CardPlacements";
import { orimLogout } from "../../Services/BackendAPI";
import orimlogo from "../../assets/image 55.png";
import CloseIcon from "@mui/icons-material/Close";
import mobileicon from "../../assets/mobileLogo.png";
import OrimLogo from "../../assets/ORIMFinal.png";
import RecommendedAssets from "../UIForClient/RecommendedAssets";
import Dashboard from "../UIForClient/Dashboard";
import homeicon from "../../assets/home.png";
import recommendasseticon from "../../assets/recommendasset.png";
import riskanalysericon from "../../assets/riskanalyser.png";
import riskprofilingicon from "../../assets/riskprofiling.png";
import {
  HomeIcon,
  RecommendedAssetIcon,
  RiskProfilingIcon,
  RiskAnalyserIcon,
  DashboardIcon,
  ClientIcon,
  InvestmentIcon,
  AddInvestmentIcon,
  SettingsIcon,
} from "../SideBar/SvgIcons";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#FAFCFE",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "30%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#A0AEC0",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));


export default function Appbar({ enableAsset, handleExportPDF, refPDF,drawerWidth }) {
  const theme = useTheme();
  const themev1 = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const [open, setOpen] = React.useState(true);
  const small = useMediaQuery(themev1.breakpoints.down("sm"));
  const medium = useMediaQuery(themev1.breakpoints.down("md"));
 
  const NavbarItems = [
    {
      label: "Home",
      route: "/home",
      icon: (
        <HomeIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Recommend Assets",
      route: "/recommendedassets",
      icon: (
        <RecommendedAssetIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Risk Profiling",
      route: "/riskprofiling",
      icon: (
        <RiskProfilingIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Risk Analyser",
      route: "/riskanalyser",
      icon: (
        <RiskAnalyserIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
  ];
  const NavbarItemAdmin = [
    {
      label: "Dashboard",
      route: "/dashboard",
      icon: (
        <DashboardIcon />
      ),
    },
    {
      label: "Add New Investment",
      route: "/add-new-investment",
      icon: <AddInvestmentIcon />,
    },
    {
      label: "Client",
      route: "/client",
      icon: <ClientIcon />,
    },
    {
      label: "Investment",
      route: "/investment",
      icon: <InvestmentIcon />,
    },
    {
      label: "Settings",
      route: "/settings",
      icon: <SettingsIcon />,
    },
  ];
  const [navbarItem, setNavBarItem] = useState(NavbarItems);

  useEffect(() => {
    if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "ADMIN"
    ) {
      setNavBarItem(NavbarItemAdmin);
    } else if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "SUPERADMIN"
    ) {
      setNavBarItem([]);
    } else {
      setNavBarItem(NavbarItems);
    }
  }, []);

    useEffect(() => {
      navbarItem.map((item, index) => {
        if (location.pathname === item.route) {
          setSelected(index);
        }
      });
    }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const logoutHandler = () => {
    orimLogout()
      .then((res) => {
        localStorage.clear();
        window.location.reload();
      })
      .catch((error) => {
        localStorage.clear();
        window.location.reload();
      });
  };


  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
          >
            <LogoutIcon onClick={logoutHandler} />
          </IconButton>
          {/* <p>Logout</p> */}
        </MenuItem>
      </Menu>
    </>
  );
 
 const drawer = (
   <div>
     {/* <Toolbar /> */}
     <List>
       {navbarItem.map((item, index) => (
         <>
           <ListItem
             key={index}
             onClick={() => {
               setSelected(index);
               navigate(item.route);
             }}
             sx={{
               bgcolor: selected === index ? "#F6F8FA" : "#fff",
               padding: "1rem",
               paddingLeft: "2rem",
             }}
           >
             <ListItemIcon
               sx={{
                 minWidth: "40px !important",
                 bgcolor: selected === index ? "#F6F8FA" : "#fff",
                 color: selected === index ? "#194BFB" : "#000000",
               }}
             >
               {item.icon}
             </ListItemIcon>

             <ListItemText
               sx={{
                 color: selected === index ? "#194BFB" : "#000000",
                 cursor: "pointer",
                 ".css-ikfqlk-MuiTypography-root": {
                   fontSize: "20px",
                   fontFamly: "Lato",
                   fontWeight: 600,
                 },
               }}
               primary={item.label}
             />
           </ListItem>
         </>
       ))}
     </List>
   </div>
 );

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        {enableAsset !== true && (
          <AppBar
            sx={{
              backgroundColor: "#FFFFFF",
              zIndex: theme.zIndex.drawer + 1,
              boxShadow: "7px 5px 8px 1px rgba(222,220,220,0.71)",
              height: small ? "59px" : "80px",
            }}
          >
            <Toolbar>
              {!medium && <img src={OrimLogo} style={{ width: "123px" }} />}
              {medium && (
                <>
                  <SwipeableDrawer
                    sx={{
                      zIndex: 1201,
                      width: "600px",
                    }}
                    variant="temporary"
                    anchor={theme.direction === "rtl" ? "right" : "left"}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                      keepMounted: true,
                    }}
                  >
                    <Grid container>
                      <Grid item xs align="left">
                        <img
                          src={mobileicon}
                          style={{ width: "100%", padding: "10px" }}
                        />
                      </Grid>
                      <Grid item xs align="right">
                        <IconButton
                          size="large"
                          aria-label="show 17 new notifications"
                          align="right"
                        >
                          <CloseIcon
                            sx={{
                              color: "black",
                              "& hover": {
                                backgroundColor: "grey",
                              },
                            }}
                            onClick={handleDrawerToggle}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>

                    {drawer}
                  </SwipeableDrawer>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                    sx={{
                      ...(mobileOpen && { display: "none" }),
                    }}
                  >
                    <MenuIcon sx={{ color: "black" }} />
                  </IconButton>
                  <img src={orimlogo} />
                </>
              )}
              <Box sx={{ flexGrow: 1 }} />
              {medium && (
                <>
                  <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                  >
                    <Badge color="error">
                      <NotificationsIcon color="disabled" />
                    </Badge>
                  </IconButton>
                  <Avatar
                    alt={
                      localStorage.getItem("name") ||
                      (localStorage.hasOwnProperty("name") &&
                        localStorage.getItem("name") !== "undefined" &&
                        localStorage.getItem("name") !== null)
                        ? `${localStorage
                            .getItem("name")
                            .slice(0, 1)
                            .toUpperCase()}`
                        : "R"
                    }
                    src="/static/images/avatar/2.jpg"
                  />
                </>
              )}
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "none", md: "flex" },
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  spacing={3}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <IconButton
                      size="large"
                      aria-label="show 17 new notifications"
                      sx={{
                        "&:hover": {
                          background: "none",
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#F3F5F9",
                        }}
                      >
                        <NotificationsIcon color="action" />
                      </Avatar>
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <Avatar
                      alt={
                        localStorage.getItem("name") ||
                        (localStorage.hasOwnProperty("name") &&
                          localStorage.getItem("name") !== "undefined" &&
                          localStorage.getItem("name") !== null)
                          ? `${localStorage
                              .getItem("name")
                              .slice(0, 1)
                              .toUpperCase()}`
                          : "R"
                      }
                      src="/static/images/avatar/2.jpg"
                    />
                  </Grid>
                  <Grid item xs={6} container direction="column">
                    <Grid item xs>
                      <Typography sx={{ color: "black", fontSize: "1.125rem" }}>
                        {localStorage.getItem("name")}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography
                        sx={{ color: "#9DA8B7", fontSize: "0.875rem" }}
                      >
                        {localStorage.getItem("username")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} align="right">
                    <Button onClick={logoutHandler} >
                      <LogoutIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={logoutHandler} />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        )}
        {renderMobileMenu}
      </Box>
    </>
  );
}
