import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Grid,
  TextField,
  FormControl,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Link,
  useMediaQuery,
  Card,
  Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SecurityDetailes from "./SecurityDetailes";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";
import ExcelUpload from "./ExcelUpload";
import { createStyles, withStyles } from "@mui/styles";
import RiskScore from "../RiskScore";
import RiskScoreTable from "./RiskScoreTable";
import { useTheme } from '@mui/material/styles';
import Carousel from "react-elastic-carousel";
import "./style.css";
import "react-magic-slider-dots/dist/magic-dots.css";

const CustomTypography2 = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600",
  fontFamily: "Lato",
  // color: "#000000",
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
},
}));

const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  padding: "4px"
}));

const CustomRadio = withStyles((theme) =>
  createStyles({
    root: {
      "& svg": {
        width: "1em",
        height: "1em",
      },
    },
  })
)(Radio);

function InvestmentDetails({
  compValues,
  setCompValues,
  // initialValues,
  selectValueType,
  setSelectedValueType,
  symbolValue,
  setSymbolValue,
  portfolioName,
  setPortfolioName,
  value,
  setValue,
  portfolioUserName,
  setPortfolioUserName,
  getFreeReport,
  setFreeReport,
  dashboard
}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [clicked, setClicked] = useState(false);
  // const ref = useRef(null);

  const getBreakPointData = () => {
    return [{ itemsToShow:  1, itemsToScroll:  1 }];
  };
 
  function handleChange(e) {
    setValue(e.target.value);
  }

  function handleRadioButtonChange(e) {
    setSelectedValueType(e.target.value)
  }

  function handleEditPenClick() {
    setClicked(true);  
  }

 

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs>
          <Typography variant="h4">
            Check the risk in your portfolio by filling the details below
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h5">Name</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="my-input"
                label=""
                variant="outlined"
                onChange={(e) => setPortfolioName(e.target.value)}
                sx={{ outline: "2px" }}
              />
            </Grid>
            {!dashboard && (
              <Grid item xs={12}>
                <Typography variant="h5">Email ID</Typography>
              </Grid>
            )}
            {!dashboard && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setPortfolioUserName(e.target.value)}
                  sx={{ outline: "2px" }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h5">Portfolio Details Input Type</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                sx={{ fontSize: "4px" }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  sx={{
                    ".css-uckjdc-MuiTypography-root": {
                      fontSize: '18px'
                    },
                    [theme.breakpoints.down("sm")]: {
                      ".css-ahj2mt-MuiTypography-root": {
                        fontSize: "13px",
                      },
                    },
                  }}
                  value="manualInput"
                  control={<CustomRadio />}
                  label="Manual Input"
                />
                <FormControlLabel
                  sx={{
                    ".css-uckjdc-MuiTypography-root": {
                      fontSize: '18px'
                    },
                    [theme.breakpoints.down("sm")]: {
                      ".css-ahj2mt-MuiTypography-root": {
                        fontSize: "13px",
                      },
                    },
                  }}
                  value="excelUpload"
                  control={<CustomRadio />}
                  label="Excel Upload"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        {value === "manualInput" ? (
          <>
            <Grid
              item
              container
              xs
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h5">
                  Enter Your Portfolio Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectValueType}
                    onChange={handleRadioButtonChange}
                  >
                    <FormControlLabel
                      sx={{
                        ".css-uckjdc-MuiTypography-root":{
                          fontSize: '18px'
                        },
                        [theme.breakpoints.down("sm")]: {
                          ".css-ahj2mt-MuiTypography-root": {
                            fontSize: "13px",
                          },
                        },
                      }}
                      value="PERCENT"
                      control={<CustomRadio />}
                      label="Percentage"
                    />
                    <FormControlLabel
                      sx={{
                        ".css-uckjdc-MuiTypography-root":{
                          fontSize: '18px'
                        },
                        [theme.breakpoints.down("sm")]: {
                          ".css-ahj2mt-MuiTypography-root": {
                            fontSize: "13px",
                          },
                        },
                      }}
                      value="PRICES"
                      control={<CustomRadio />}
                      label="INR Value"
                    />
                    <FormControlLabel
                      sx={{
                        ".css-uckjdc-MuiTypography-root":{
                          fontSize: '18px'
                        },
                        [theme.breakpoints.down("sm")]: {
                          ".css-ahj2mt-MuiTypography-root": {
                            fontSize: "13px",
                          },
                        },
                      }}
                      value="QUANTITY"
                      control={<CustomRadio />}
                      label="Quantity"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {small && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">{"Portfolio Allocation"}</Typography>
                </Grid>

                <Carousel
                  showArrows={false}
                  pagination={true}
                  enableAutoPlay={true}
                  autoPlaySpeed={2000}
                  breakPoints={getBreakPointData()}
                >
                  <div
                    style={{
                      width: "100%",
                      padding: "10px",
                      marginLeft: "20px",
                    }}
                  >
                    <RiskScore
                            compValues={compValues}
                            setCompValues={setCompValues}
                            // initialValues={initialValues}
                            selectValueType={selectValueType}
                            setSelectedValueType={setSelectedValueType}
                            symbolValue={symbolValue}
                            setSymbolValue={setSymbolValue}
                            portfolioName={portfolioName}
                            setPortfolioName={setPortfolioName}
                            portfolioUserName={portfolioUserName}
                            getFreeReport={getFreeReport}
                            setFreeReport={setFreeReport}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingLeft: "10px",
                    }}
                  >
                    <RiskScoreTable
                       compValues={compValues}
                          setCompValues={setCompValues}
                          selectValueType={selectValueType}
                          setSelectedValueType={setSelectedValueType}
                          symbolValue={symbolValue}
                          setSymbolValue={setSymbolValue}
                    />
                  </div>
                </Carousel>
              </>
            )}

            <Grid
              item
              container
              xs
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography variant="h5">Enter Security Details</Typography>
              </Grid>

              <Grid
                item
                xs
                // container
                // direction="row"
                // justifyContent="center"
                // alignItems="center"
                // spacing={1}
              >
                {/* <Grid item xs> */}
                <SecurityDetailes
                  compValues={compValues}
                  setCompValues={setCompValues}
                  // initialValues={initialValues}
                  selectValueType={selectValueType}
                  setSelectedValueType={setSelectedValueType}
                  symbolValue={symbolValue}
                  setSymbolValue={setSymbolValue}
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </>
        ) : (
          <ExcelUpload
            getFreeReport={getFreeReport}
            setFreeReport={setFreeReport}
          />
        )}
      </Grid>
    </div>
  );
}

export default InvestmentDetails;
