import { Close } from "@mui/icons-material";
import {
  Backdrop,
  Modal,
  Typography,
  Fade,
  Divider,
  Grid,
  Button,
  Stack,
  useMediaQuery,
  TextField,
  styled,
  IconButton,
  Paper
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import CustomTypography from "../../common/CustomTypography";
import CustomOutlinedInput from "../../common/CustomOutlinedInput";
import StackTwoComponents from "../../body/investmentTabSection/StackTwoComponents";
import CustomSelectComponent from "../../body/investmentTabSection/CustomSelectComponent";
import AddNewInvestment from "../../body/investmentTabSection/AddNewInvestment";
const iconButtonStyle = {
  position: "absolute",
  top: "1rem",
  right: "1.1rem",
};
const iconStyle = {
  fontSize: "1.1rem",
  color: "#000",
};
const StyledTextfield = styled(TextField)(({ theme, myColor }) => ({
  backgroundColor: myColor,
  width: "100%",
  fieldset: {
    borderRadius: "10px",
  },
}));
const StyledBox = styled(Box)(({ theme, myColor }) => ({
  border: "1px solid #DFE0EB",
  height: "250px",
  width: "100%",
  cursor: "text",
  borderRadius: "10px",
  padding: "0.7rem",
}));

export const CustomButton = styled(Button)((props) => {
  return {
    fontSize: "1rem",
    display: "block",
    fontWeight: "600",
    color: "#FFF",
    backgroundColor: "#194BFB",
    textTransform: "capitalize",
    // padding:'0 1rem 0 1rem',
    // width: '25%',
    [props.theme.breakpoints.down("sm")]: {
      // fontSize: "0.75rem",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#194BFB",
    },
  };
});

export const StyledCustomButton = styled(CustomButton)((props) => {
  return {
    fontSize: "1rem",
    fontWeight: "600",
    backgroundColor: "#194BFB",
    textTransform: "capitalize",
    // padding: '0.7rem 1rem',
    // width: '20%',
    [props.theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    "&:hover": {
      backgroundColor: "#194BFB",
    },
  };
});


function EditInvestmentDtlsModal({ open, handleOpen, handleClose, row }) {
    // console.log('row',row)
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: small ? "80%" : "50%",
    maxHeight: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    px: 6,
    py: 4,
    borderRadius: "1.25rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      px: 2,
      py: 2,
    },
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          ".css-sox5kk-MuiBackdrop-root": {
            backgroundColor: "rgb(0,0,0,0.04)",
          },
          [theme.breakpoints.down('sm')]:{
            // margin: '1.5rem'
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Paper
              sx={{ overflowY: "scroll", boxShadow: "none" }}
              disableElevation
            >
              <AddNewInvestment row={row} modalOpen={open}/>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default EditInvestmentDtlsModal;
