import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
// import '../../Tables/fontFamilyLato.css'
import { Menu, MenuItem, Stack, Typography, Grid } from "@mui/material";
import { ArrowDropDown, KeyboardArrowDown } from "@mui/icons-material";
import PortfolioPerformanceChart from "../../Charts/PortfolioPerformanceChart";
import PortfolioPerformanceTable from "../../Tables/PortfolioPerformanceTable";
import TitleSection from "./TitleSection";

function PortfolioPerformance({ data }) {
  return (
    <Grid
      spacing={1}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} md={12}>
        <TitleSection data={data}/>
      </Grid>
      <Grid item xs={12} md={12}>
        <PortfolioPerformanceChart
          data={data}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <PortfolioPerformanceTable
          data={data}
        />
      </Grid>
    </Grid>
  );
}

export default PortfolioPerformance;
