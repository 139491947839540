import React from "react";
import { Grid, Typography,Box, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Stack from "@mui/material/Stack";

function ScenarioCard({ risk, heading, percent, label }) {
  // console.log(risk, heading, percent, label);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card
      variant="outlined"
      sx={{
        display: !small ? "flex" : "",
        // paddingLeft: "1rem",
        // paddingTop: "1rem",
        // paddingRight: "1rem",
        borderRadius: "0.625rem",
        padding: "0.5rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection:small ? "column": "row", p: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F8FBFF",
            borderRadius: "0.625rem",
            p: 2,

          }}
        >
          <Typography
            align="center"
            sx={{
              fontSize: "1.5625rem",
              color: "#4EBB51",
              fontWeight: 500,
              // [theme.breakpoints.down("md")]: {
              //   fontSize: "15px !important",
              // },
            }}
          >
            Risk
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "3rem",
              color: "#4EBB51",
              fontWeight: 800,
              // [theme.breakpoints.down("md")]: {
              //   fontSize: "15px",
              // },
            }}
          >
            {risk}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
            pl: 2,
            alignItems: small ? "center" : "left",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              color: "#000",
              fontWeight: 500,
              // [theme.breakpoints.down("md")]: {
              //   fontSize: "12px",
              // },
            }}
          >
            {heading}
          </Typography>
          <Box
            sx={{
              display: "flex",
             flexDirection:small ? "column": "row",
              pt: 2,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "3.25rem",
                color: "#FF0000",
                fontWeight: 700,
                padding: 0,
                // [theme.breakpoints.down("md")]: {
                //   fontSize: "30px",
                // },
              }}
            >
              {percent}
            </Typography>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <Typography
              sx={{
                fontSize: "1.5rem",
                color: "#000",
                fontWeight: 500,
                // [theme.breakpoints.down("md")]: {
                //   fontSize: "12px",
                // },
              }}
            >
              {label}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ScenarioCard;
