import {Typography} from '@mui/material';
import React from 'react';

function HeadingTextComponent({
    heading,
    subHeading,
    subHeadingFontSize = "1.1rem",
    headingColor = "black",
    subHeadingColor = "#718096"
}) {
    return (
      <>
        <Typography
          align="center"
          color={headingColor}
          sx={{ fontSize: "2.1875rem", fontWeight: 700, fontFamily: "Lato" }}
        >
          {heading}
        </Typography>
        <Typography
          align="center"
          color={subHeadingColor}
          sx={{
            fontSize: subHeadingFontSize,
            fontWeight: "500",
            pb: "3rem",
            fontFamily: 'Lato'
          }}
        >
          {subHeading}
        </Typography>
      </>
    );
}

export default HeadingTextComponent
