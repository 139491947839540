import React, { useState } from "react";
import {
  Box,
  Paper,
  Container,
  Grid,
  Divider,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import InvestmentDetails from "./InvestmentDetails";
import footerLanding from '../../../assets/footerLanding.png'
import RiskScore from "../RiskScore";
import RiskScoreChart from "./RiskScoreTable";
import { createStyles, withStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import {
  Route,
  Routes
} from "react-router-dom";
import RiskReportMainPage from '../Dashboard/RiskReport';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const CustomTypography = withStyles((theme) =>
  createStyles({
    root: {
      fontSize: "20px",
      fontWeight: "700",
      color: "#7F70DB",
     padding: "2rem"
    },
  })
)(Typography);



function Footer({
  compValues,
  setCompValues,
  selectValueType,
  setSelectedValueType,
  symbolValue,
  setSymbolValue,
  getFreeReport,
  setFreeReport,
  dashboard
}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  

    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: "4rem" }}
        >
          {dashboard && (
            <Grid item xs={12} align="center">
              <Button
                component={Link}
                to={"/home"}
                variant="contained"
                // disabled={getFreeReport === false}
                sx={{
                  backgroundColor: "#194BFB",
                  borderRadius: "8px",
                  textTransform: "none",
                  // padding: "0.5rem 3.5rem 0.5rem 3.5rem",
                  fontSize: "17px",
                  fontWeight: 700,
                  "&:hover": {
                    backgroundColor: "#194BFB",
                  },
                }}
                disableElevation
              >
                {"Get Full Report"}
              </Button>
            </Grid>
          )}
          {!dashboard && (
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                <Grid item xs={12} md={4} align="center">
                  <Button
                    component={Link}
                    to={"/riskscore"}
                    size="large"
                    sx={{
                      backgroundColor: "#194BFB",
                      textTransform: "none",
                      borderRadius: "10px",
                      fontsize: "22px",
                      fontWeight: 700,
                      // padding: "12px 40px",
                      "&:hover": {
                        backgroundColor: "#194BFB",
                      },
                    }}
                    variant="contained"
                    disableElevation
                    disabled={getFreeReport === false}
                  >
                    Get Free report
                  </Button>
                </Grid>
                {/* <Grid item xs={12} md={4} align="center"> */}
                &nbsp; &nbsp; &nbsp;
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontWeight: 500,
                    fontSize: "22px",
                  }}
                >
                  {" "}
                  {"Or"}
                </Typography>
                &nbsp; &nbsp; &nbsp; &nbsp;
                {/* </Grid> */}
                <Grid item xs={12} md={4} align="center">
                  <Button
                    component={Link}
                    to={"/auth/signin"}
                    size="large"
                    sx={{
                      backgroundColor: "#194BFB",
                      textTransform: "none",
                      borderRadius: "10px",
                      fontsize: "20px",
                      fontWeight: 700,
                      // padding: "12px 40px",
                      "&:hover": {
                        backgroundColor: "#194BFB",
                      },
                    }}
                    variant="contained"
                    disableElevation
                  >
                    Sign In Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!dashboard && (
            <Grid item xs={12} align="right">
              <img src={footerLanding} style={{ paddingBottom: "1rem" }} />
            </Grid>
          )}
        </Grid>
        <Routes>
          <Route
            path="/riskscore"
            element={
              <RiskReportMainPage
                compValues={compValues}
                setCompValues={setCompValues}
                selectValueType={selectValueType}
                setSelectedValueType={setSelectedValueType}
                symbolValue={symbolValue}
                setSymbolValue={setSymbolValue}
              />
            }
          />
        </Routes>
      </>
    );
}

export default Footer;