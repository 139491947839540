import { createTheme } from "@mui/material/styles";

export const dashboardThemes = createTheme({
  typography: {
    fontFamily: "Lato",
    h4: {
      fontSize: "25px",
      fontWeight: 800,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "18px",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: "48px",
          fontWeight: 600,
        },
      },
    },

    // MuiSvgIcon: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: "34px",
    //       verticalAlign: "text-top",
    //       paddingTop:'12px'
    //     },
    //   },
    // },
  },
});
