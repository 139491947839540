import React from 'react'
import { StyledHeadingTypography } from '../../PortfolioPerformance/TitleSection/custom'
import { styled } from '@mui/material/styles';
import { Grid, Stack, Typography } from '@mui/material';
import CircularProgressBar from '../../CustomCircularProgressBar';
import RiskScoreCard from '../../CustomCircularProgressBar/RiskScoreCard';
import ChartPractice from '../../../Charts/ChartPractice';
import InvestmentValueCard from '../../AnalyserReportSection/InvestmentValueCard';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
  border: "1px solid #E2E8F0",
  minHeight: "428px",
  borderRadius: '12px'
}));


function AnalyserReport({ data, fetchRiskScore, pdfFormat=false }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      // spacing={2}
    >
      {!pdfFormat ? (
        <Grid item container xs columnSpacing={{ xs: 2, md: 3 }} rowSpacing={3}>
          <Grid item xs md lg={4}>
            <Item>
              <RiskScoreCard fetchRiskScore={fetchRiskScore} />
            </Item>
          </Grid>
          <Grid item xs md lg={4}>
            <Item>
              <ChartPractice data={data} />{" "}
            </Item>
          </Grid>
          <Grid item xs md lg={4}>
            <Item>
              <InvestmentValueCard data={data} />{" "}
            </Item>
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs spacing={6}>
          <Grid item xs>
            <Item>
              <RiskScoreCard fetchRiskScore={fetchRiskScore} />
            </Item>
          </Grid>
          <Grid item xs>
            <Item>
              <ChartPractice data={data} />{" "}
            </Item>
          </Grid>
          <Grid item xs>
            <Item>
              <InvestmentValueCard data={data} />{" "}
            </Item>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default AnalyserReport