import React, { Component } from "react";
import Slider from "react-slick";
import excellImage from '../../../assets/image 44.png';
import Carousel from "react-elastic-carousel";
import "./style.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { Card, Box } from '@mui/material';
import RsikScore from '../RiskScore';

export default function SlickSlider({pieChartSlider, name}) {

    const image =[
        {url:excellImage},
        {url:excellImage},
        {url:excellImage},
    ]
    const breakPoints = [
      { itemsToScroll: 1, itemsToShow: 1 },
      // { width: 550, itemsToShow: 2, itemsToScroll: 2 },
      // { width: 768, itemsToShow: 3 },
      // { width: 1200, itemsToShow: 4 }
    ];

      const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        transform: 'none',
        appendDots: (dots) => {
          return (
            <MagicSliderDots
              dotsContainer={"magic-dots slick-dots dots-container"}
              dots={dots}
              numDotsToShow={3}
              dotWidth={17}
            />
          );
        }
      };
  

    return (
      <>
        <Carousel breakPoints={breakPoints}>
        {/* <Slider {...settings}> */}
        
      {  image.map((value, index) => (
          <div key={index}>
              <img src={value.url} style={{width: '100%', marginRight:'12px'}}/>
          </div>
        ))
        }
        
        
          {/* </Slider> */}
        </Carousel>
      </>
    );
}
