import React, { useEffect, useState,useRef } from "react";
import CustomImageTextButton from "../../common/CustomImageTextButton";
import excelUpload from "../../../../assets/AssetsForAdmin/excelUpload.png";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { useSelector, useDispatch } from "react-redux";
import { fetchClientDetailsAction } from "../../../../Store/actions/securityDetails";



function GenerateExcel() {
  const dispatch = useDispatch();
  const excelRef = useRef(null);

  const excelExport = () => {
    if (excelRef.current !== null) {
      excelRef.current.save();
    }
  };
  const clientDetails = useSelector(
    (state) => state.securityDetails.clientDetailsList.list
  );

  useEffect(() => {
    dispatch(fetchClientDetailsAction());
  }, []);



  return (
    <>
      <CustomImageTextButton
        img={excelUpload}
        text={"Export to Excel"}
        handleOnClick={excelExport}
      />
      <ExcelExport data={clientDetails} fileName="Products.xlsx" ref={excelRef}>
        <ExcelExportColumn field="portfolio_name" title="Portfolio Name" />
        <ExcelExportColumn
          field="portfolio_analyser.investment_amount"
          title="Investment Amount"
        />
        <ExcelExportColumn
          field="portfolio_analyser.gain_risk.gain"
          title="Investment Gain"
        />
        <ExcelExportColumn
          field="portfolio_analyser.gain_risk.risk"
          title="Investment Risk"
        />
        <ExcelExportColumn
          field="portfolio_analyser.expected_return.profit"
          title="Expected Return Profit"
        />
        <ExcelExportColumn
          field="portfolio_analyser.expected_return.loss"
          title="Expected Return Loss"
        />
        <ExcelExportColumn field="created_at" title="Created At" />
        <ExcelExportColumn field="updated_at" title="Updated At" />
      </ExcelExport>
    </>
  );
}

export default GenerateExcel;
