import * as React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import LandingPage from "../../UIForClient/Dashboard";
import RiskReportFree from "../../UIForClient/Dashboard/RiskReport";
import Auth from "../../SignUp";

export default function Main() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/riskscore" element={<RiskReportFree />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}
