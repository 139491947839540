import React, { useState, useCallback } from "react";
import { Grid, Button, IconButton, Paper, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import RowComponents from "./RowComponents";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "../../../../assets/Vector (1).png";
// import { addNewEntryInPortfolioDetailsAction } from "../../../Store/actions/securityDetails";

function SecurityDetailes({
  selectValueType,
  compValues,
  setCompValues,
  // initialValues,
  symbolValue,
  setSymbolValue,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const { portfolioDetails } = useSelector((state) => state.securityDetails);
  const [fetchedScriptValue, setFetchedScriptValue] = useState("");
  const [rowDeleted, setRowDeleted] = useState(false);

  const addMoreRow = () => {
    setCompValues([
      ...compValues,
      {
        symbol: "",
        value: null,
        category: "",
      },
    ]);
  };

  const removeRows = (idx) => {
    let values = [...compValues];
    // console.log(values)
    values.splice(idx, 1);
    // console.log(values)
    setCompValues(values);
    setRowDeleted(true);
    // console.log("Deleted",compValues);
  };
  const handleChange = (value, index, key) => {
    let eachRowData = [...compValues];
    let eachComponentData = eachRowData[index];
    eachComponentData[key] = value;

    setCompValues(eachRowData);
  };

    //  console.log("Updated Rows", compValues);

  return (
    <
      // Paper
      // sx={{
      //   // maxHeight: small ? "500px" : "300px",
      //   boxShadow: "none",
      // }}
    >
      {compValues.map((rowValue, idx) => (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{ m: "8px 0 8px 0" }}
          key={idx}
        >
          {/* {console.log("rerender")} */}
          <Grid item xs={12} md={10}>
            <RowComponents
              selectValueType={selectValueType}
              fetchedScriptValue={fetchedScriptValue}
              setFetchedScriptValue={setFetchedScriptValue}
              compValues={compValues}
              setCompValues={setCompValues}
              idx={idx}
              handleChange={handleChange}
              symbolValue={symbolValue}
              setSymbolValue={setSymbolValue}
              addMoreRow={addMoreRow}
              removeRows={removeRows}
              rowDeleted={rowDeleted}
              setRowDeleted={setRowDeleted}
            />
          </Grid>
          {!small && (
            <Grid item xs={12} md>
              {/* <IconButton
              color="primary"
              component="span"
              disabled={compValues.length == 1}
              onClick={() => removeRows(idx)}
            >
              <DeleteIcon
                sx={{
                  fontSize: "16px",
                  color: compValues.length == 1 ? "#808080" : "#D11A2A",
                }}
              />
            </IconButton> */}
              {compValues.length !== 1 && (
                <img
                  component={"button"}
                  src={DeleteIcon}
                  style={{
                    paddingTop: "15px",
                    paddingLeft: "14px",
                    width: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    compValues.length !== 1 && removeRows(idx);
                  }}
                />
              )}
              {idx == compValues.length - 1 && (
                <Button
                  size="small"
                  sx={{
                    fontSize: "16px",
                    color: "#194BFB",
                    textTransform: "none",
                    marginBottom: "12px",
                    marginLeft: "8px",
                  }}
                  startIcon={
                    <AddIcon sx={{ fontSize: "16px", color: "#194BFB" }} />
                  }
                  onClick={() => addMoreRow()}
                >
                  Add
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      ))}
    </
    // Paper
    >
  );
}

export default SecurityDetailes;
