import React, { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
  Button,
  useMediaQuery,
  SwipeableDrawer,
  Collapse,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Typography,
  Grid,
  IconButton,
  Container,
  SvgIcon,
} from "@mui/material";
import orimlogo from "../../assets/image 55.png";
import CloseIcon from "@mui/icons-material/Close";
import mobileicon from "../../assets/mobileLogo.png";
import OrimLogo from "../../assets/ORIMFinal.png";
import RecommendedAssets from "../UIForClient/RecommendedAssets";
import Dashboard from "../UIForClient/Dashboard";
import homeicon from "../../assets/home.png";
import recommendasseticon from "../../assets/recommendasset.png";
import riskanalysericon from "../../assets/riskanalyser.png";
import riskprofilingicon from "../../assets/riskprofiling.png";
//icons for client
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import BallotRoundedIcon from "@mui/icons-material/BallotRounded";
//images for admin
import addInvestment from "../../assets/AssetsForAdmin/addInvestment.png";
import client from "../../assets/AssetsForAdmin/client.png";
import dashboard from "../../assets/AssetsForAdmin/dashboard.png";
import settings from "../../assets/AssetsForAdmin/settings.png";
import investment from "../../assets/AssetsForAdmin/investment.png";
import {
  HomeIcon,
  RecommendedAssetIcon,
  RiskProfilingIcon,
  RiskAnalyserIcon,
  DashboardIcon,
  ClientIcon,
  InvestmentIcon,
  AddInvestmentIcon,
  SettingsIcon,
} from "./SvgIcons";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function SideBar(enableAsset, drawerWidth) {
  const theme = useTheme();
  const themev1 = useTheme();
  const small = useMediaQuery(themev1.breakpoints.down("sm"));
  const medium = useMediaQuery(themev1.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const NavbarItems = [
    {
      label: "Home",
      route: "/home",
      icon: (
        <HomeIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Recommend Assets",
      route: "/recommendedassets",
      icon: (
        <RecommendedAssetIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Risk Profiling",
      route: "/riskprofiling",
      icon: (
        <RiskProfilingIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Risk Analyser",
      route: "/riskanalyser",
      icon: (
        <RiskAnalyserIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
  ];
  const NavbarItemAdmin = [
    {
      label: "Dashboard",
      route: "/dashboard",
      icon: (
        <DashboardIcon
          // sx={{
          //   fontSize: "34px",
          //   verticalAlign: "text-top",
          //   paddingTop: "12px",
          // }}
        />
      ),
    },
    {
      label: "Add New Investment",
      route: "/add-new-investment",
      icon: <AddInvestmentIcon />,
    },
    {
      label: "Client",
      route: "/client",
      icon: <ClientIcon />,
    },
    {
      label: "Investment",
      route: "/investment",
      icon: <InvestmentIcon />,
    },
    {
      label: "Settings",
      route: "/settings",
      icon: <SettingsIcon />,
    },
    {
      label: "Corporate Action",
      route: "/corporate-action",
      icon: (
        <CorporateFareIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
    {
      label: "Manual Data Upload",
      route: "/manual-data-upload",
      icon: (
        <UploadFileIcon
          sx={{
            fontSize: "34px",
            verticalAlign: "text-top",
            paddingTop: "12px",
          }}
        />
      ),
    },
  ];
  const [navbarItem, setNavBarItem] = useState(NavbarItems);
  useEffect(() => {
    if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "ADMIN"
    ) {
      setNavBarItem(NavbarItemAdmin);
    } else if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "SUPERADMIN"
    ) {
      setNavBarItem([]);
    } else {
      setNavBarItem(NavbarItems);
    }
  }, []);

  useEffect(() => {
    navbarItem.map((item, index) => {
      if (location.pathname === item.route) {
        setSelected(index);
      }
    });
  }, []);

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {navbarItem.map((item, index) => (
          <>
            <ListItem
              key={index}
              onClick={() => {
                setSelected(index);
                navigate(item.route);
              }}
              sx={{
                bgcolor: selected === index ? "#F6F8FA" : "#fff",
                padding: "1rem",
                paddingLeft: "2rem",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: "40px !important",
                  // width: "21px",
                  bgcolor: selected === index ? "#F6F8FA" : "#fff",
                  color: selected === index ? "#194BFB" : "#000000",
                  // padding: "1rem",
                  // paddingLeft: "2rem",
                }}
              >
                {item.icon}
                {/* <img
                  src={item.icon}
                  style={{
                    color: selected === index ? "#000" : "#E1E0E4",
                    bgcolor: selected === index ? "#000" : "#E1E0E4",
                    cursor: "pointer",
                    width: "21px",
                  }}
                /> */}
              </ListItemIcon>

              <ListItemText
                sx={{
                  color: selected === index ? "#194BFB" : "#000000",
                  cursor: "pointer",
                  ".css-ikfqlk-MuiTypography-root": {
                    fontSize: "20px",
                    fontFamly: "Lato",
                    fontWeight: 600,
                  },
                }}
                primary={item.label}
              />
            </ListItem>
          </>
        ))}
      </List>
    </div>
  );
  return (
    <>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {enableAsset !== true && !medium && (
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                // width: drawerWidth,
                width: 400,
                backgroundColor: "#fffff",
                paddingTop: "27px",
                boxShadow: "7px 5px 8px 1px rgba(222,220,220,0.71)",
                // position: "absolute",
              },
            }}
            open={mobileOpen}
          >
            {drawer}
          </Drawer>
        )}
      </Box>
      {medium && (
        <>
          <SwipeableDrawer
            sx={{
              zIndex: 1201,
              width: "650px",
            }}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Grid container>
              <Grid item xs align="left">
                <img
                  src={mobileicon}
                  style={{ width: "100%", padding: "10px" }}
                />
              </Grid>
              <Grid item xs align="right">
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  align="right"
                >
                  <CloseIcon
                    sx={{
                      color: "black",
                      "& hover": {
                        backgroundColor: "grey",
                      },
                    }}
                    onClick={handleDrawerToggle}
                  />
                </IconButton>
              </Grid>
            </Grid>

            {drawer}
          </SwipeableDrawer>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              ...(mobileOpen && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <img src={orimlogo} />
        </>
      )}
    </>
  );
}

export default SideBar;
