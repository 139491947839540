import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react'
import { Box, Card, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import './styles.css'



// const color="linear-gradient(#e66465, #9198e5)" 
// const psign="+";
// const nsign="-";



const PLCustomTypography = styled(Typography)((props)=>{
  return {
    color:props.profit?'#43B747':'#F23939',
    fontWeight:700,
}})
const StyledBox = styled(Typography)((props)=>{
  return {
    backgroundColor:'#F8FBFF',
    px:2
}})
const StyledCustomTypography = styled(Typography)((props)=>{
  return {
    color:'#434343',fontSize:'0.75rem',fontWeight:600
}})



function ExpectedReturnsChart({data}) {

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      margin: [0, 15, 0, 15],
      type: "bar",
      animation: true,
      backgroundColor: "transparent",
      Color: "#FFF",
      height: 100,
      gridLineWidth: 0,
      events: {
        redraw: function () {},
      },
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
      bar: {
        maxPointWidth: 35,
      },
    },
    xAxis: {
      lineWidth: 1,
      minorGridLineWidth: 0,
      lineColor: "transparent",
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      lineColor: "transparent",
      labels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      gridLineWidth: 0,
    },
    series: [
      {
        name: "Loss",
        data: [Number(data.expected_return.loss.toFixed())],
        color: "#FF6969",
        id: "Loss",
      },
      {
        name: "Profit",
        data: [Number(data.expected_return.profit.toFixed())],
        color: "#43B747",
        id: "Profit",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxHeight: 100,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: -5,
              },
              title: {
                text: null,
              },
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
  };

  return (
    <Stack mt={4}>
      <Stack direction="row" justifyContent={"space-between"} >
        <StyledBox>
          <PLCustomTypography profit>
            + ₹{Number(data.expected_return.profit).toFixed()}
          </PLCustomTypography>
        </StyledBox>
        <StyledBox>
          <PLCustomTypography>
            - ₹{Number(data.expected_return.loss).toFixed()}
          </PLCustomTypography>
        </StyledBox>
      </Stack>
      <div align="center" style={{ margin: "-20px 0 -20px 0" }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>

      <Stack direction="row" mx={2} justifyContent={"space-between"}>
        <StyledCustomTypography>Profit</StyledCustomTypography>
        <StyledCustomTypography>Loss</StyledCustomTypography>
      </Stack>
    </Stack>
  );
}

export default ExpectedReturnsChart