import React,{useEffect} from "react";
import {
  Box,
  Paper,
  Container,
  Grid,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import Questionaire from "./Questionaire";
import GetFullReportComponent from "./GetFullReportComponent";
import { useDispatch,useSelector } from 'react-redux';

function RiskReportMainPage({
  compValues,
  setCompValues,
  selectValueType,
  setSelectedValueType,
  symbolValue,
  setSymbolValue,
}) {
  const theme = useTheme();
  const riskScore = useSelector((state)=> state.securityDetails.riskScore);
  // useEffect(() => { 
  //   securityDetailAction()
  // });
  // const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          // height: "60vh",
          marginTop: "20px",
          margin: "4rem",
          borderRadius: "20px",
          boxShadow: "none",
          [theme.breakpoints.down("md")]: {
            margin: 2
           },
        }}
      >
          <Questionaire 
          compValues={compValues}
          setCompValues={setCompValues}
          selectValueType={selectValueType}
          setSelectedValueType={setSelectedValueType}
          symbolValue={symbolValue}
          setSymbolValue={setSymbolValue}
          />
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          // height: "40vh",
          marginTop: "20px",
          margin: "4rem",
          borderRadius: "20px",
          boxShadow: "none",
          [theme.breakpoints.down("md")]: {
           margin: 2
          },
        }}
      >
            <GetFullReportComponent/>
      </Box>
    </>
  );
}

export default RiskReportMainPage;
