import React,{useState,useEffect} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  Paper,
  Stack,
  Typography,
  Grid,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import bolt from "../../../assets/bolt.png";
import Carousel from "react-elastic-carousel";
import { getRecommendedAssets } from "../../../Services/BackendAPI";
import { useSnackbar } from "notistack";
import { riskFactorBtn, riskFactorIcon } from "./RiskFactorBtn";

const ColorButton = styled(Button)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 600,
  color: "Black",
  // backgroundColor: "#FFB0B0",
  // border: "1px solid #FF5630",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: "8px",
  fontFamily: "Lato",
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#FFB0B0",
    cursor: "inherit",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "4px 6px",
    fontSize: "9px",
    borderRadius: "4px",
    border: "1px solid #FFB0B0",
  },
}));

const InvestNowBtn = styled(Button)(({ theme }) => ({
  color: "white",
  fontSize: "14px",
  fontWeight: 700,
  backgroundColor: "#333AD6",
  textTransform: "none",
  boxShadow: "none",
  borderRadius: "8px",
  fontFamily: "Lato",

  margin: 0,
  "&:hover": {
    boxShadow: "none",
    backgroundColor: "#333AD6",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
    fontSize: "10px",
    fontWeight: 500,
  },
}));

function RecommendedAssets({ openFullView = false }) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const breakPoints = [{ itemsToShow: 1, itemsToScroll: 1 }];
  const { enqueueSnackbar } = useSnackbar();
  const [recommendedAssets, setRecommendedAssets] = useState([]);

  useEffect(() => {
    getRecommendedAssets()
      .then(({ data, status, message }) => {
        if (status === "Success") {
          // enqueueSnackbar(message, { variant: "success" });
          setRecommendedAssets(data.list);
        } else {
          enqueueSnackbar(message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
        console.log(err);
      });
  }, []);

  // const riskBtn = document.querySelector('ColorButton');
  // riskBtn.style.backgroundColor = 

  return (
    <>
      {!small && !openFullView ? (
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={1}
        >
          {recommendedAssets &&
            recommendedAssets.map((value, index) => (
              <Grid item xs>
                <Card
                  key={index}
                  variant="outlined"
                  sx={{ p: 2, borderRadius: "7px" }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    mb={2}
                    spacing={1}
                  >
                    <Grid item xs={12} md={3}>
                      <CardMedia
                        component="img"
                        sx={{ width: "80%", height: "80%" }}
                        image={value.image}
                        alt="asset card image"
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 800,
                          fontFamily: "Lato",
                        }}
                      >
                        {value.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Lato",
                          color: "#5E5E5E",
                        }}
                      >
                        {value.description}
                        <Button
                          size="small"
                          variant="text"
                          sx={{
                            textTransform: "none",
                            fontSize: "12px",
                            color: "#333AD6",
                            padding: 0,
                            paddingLeft: "4px",
                            "&:hover": {
                              boxShadow: "none",
                              backgroundColor: "#fff",
                            },
                          }}
                        >
                          See in details
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <Grid item xs md>
                      <ColorButton
                        variant="contained"
                        startIcon={
                          <img src={riskFactorIcon(value.risk_factor)} />
                        }
                        sx={riskFactorBtn(value.risk_factor)}
                      >
                        {value.risk_factor}
                      </ColorButton>
                    </Grid>

                    <Grid item xs md align="right">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          fontFamily: "Lato",
                          color: "#858585",
                        }}
                      >
                        {` ${value.cagr}YR CAGR`}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "Lato",
                          color: "#089E0E",
                        }}
                      >
                        {`${value.return_percentage} % Return`}
                      </Typography>
                      {/* </Box> */}
                    </Grid>
                    <Grid item xs md align={"right"}>
                      <Button
                        fullwidth
                        variant="contained"
                        disableElevation
                        size="large"
                        sx={{
                          backgroundColor: "#333AD6",
                          textTransform: "none",
                          fontSize: "14px",
                          fontWeight: 700,
                          borderRadius: "6px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          "&:hover": {
                            backgroundColor: "#333AD6",
                          },
                        }}
                      >
                        Invest Now
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
                <br />
              </Grid>
            ))}
        </Grid>
      ) : !small && openFullView ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            {recommendedAssets &&
              recommendedAssets.map((value, index) => (
                <Grid item xs={4}>
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{ p: 2, borderRadius: "7px" }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      mb={2}
                      spacing={1}
                    >
                      <Grid item xs={12} md={3}>
                        <CardMedia
                          component="img"
                          sx={{ width: "80%", height: "80%" }}
                          image={value.image}
                          alt="asset card image"
                        />
                      </Grid>
                      <Grid item xs={12} md>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: 800,
                            fontFamily: "Lato",
                          }}
                        >
                          {value.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Lato",
                            color: "#5E5E5E",
                          }}
                        >
                          {value.description}
                          <Button
                            size="small"
                            variant="text"
                            sx={{
                              textTransform: "none",
                              fontSize: "12px",
                              color: "#333AD6",
                              padding: 0,
                              paddingLeft: "4px",
                              "&:hover": {
                                boxShadow: "none",
                                backgroundColor: "#fff",
                              },
                            }}
                          >
                            See in details
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Grid item xs md>
                        <ColorButton
                          variant="contained"
                          startIcon={
                            <img src={riskFactorIcon(value.risk_factor)} />
                          }
                          sx={riskFactorBtn(value.risk_factor)}
                        >
                          {value.risk_factor}
                        </ColorButton>
                      </Grid>

                      <Grid item xs md align="right">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                            fontFamily: "Lato",
                            color: "#858585",
                          }}
                        >
                          {` ${value.cagr}YR CAGR`}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            fontFamily: "Lato",
                            color: "#089E0E",
                          }}
                        >
                          {`${value.return_percentage} % Return`}
                        </Typography>
                        {/* </Box> */}
                      </Grid>
                      <Grid item xs md align={"right"}>
                        <Button
                          fullwidth
                          variant="contained"
                          disableElevation
                          size="large"
                          sx={{
                            backgroundColor: "#333AD6",
                            textTransform: "none",
                            fontSize: "14px",
                            fontWeight: 700,
                            borderRadius: "6px",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                            "&:hover": {
                              backgroundColor: "#333AD6",
                            },
                          }}
                        >
                          Invest Now
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                  <br />
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <Carousel
          showArrows={false}
          pagination={false}
          enableAutoPlay={true}
          autoPlaySpeed={2000}
          breakPoints={breakPoints}
        >
          {recommendedAssets &&
            recommendedAssets.map((value, index) => (
              <Card
                key={index}
                variant="outlined"
                sx={{ display: "inline-block", p: 2, m: 1 }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  mb={2}
                  spacing={1}
                >
                  <Grid item xs={3}>
                    <CardMedia
                      component="img"
                      sx={{ width: "100%" }}
                      image={value.image}
                      alt="asset card image"
                    />
                  </Grid>

                  <Grid item xs>
                    {/* <Grid item xs> */}
                    <Typography
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "14px",
                          fontWeight: 800,
                          fontFamily: "Lato",
                        },
                      }}
                    >
                      {value.name}
                    </Typography>
                    <Typography
                      // className="line-clamp"
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "12px",
                          fontWeight: 500,
                          fontFamily: "Lato",
                          color: "#5E5E5E",
                        },
                      }}
                    >
                      {value.description}
                      <Button
                        size="small"
                        variant="text"
                        sx={{
                          textTransform: "none",
                          fontSize: "10px",
                          color: "#333AD6",
                          padding: 0,
                          paddingLeft: "4px",
                          "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "#fff",
                            // cursor: "inherit",
                          },
                        }}
                      >
                        See in details
                      </Button>
                    </Typography>
                    {/* </Grid> */}
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                  // flexDirection={{ xs: "column", sm: "row" }}
                  // sx={{ fontSize: "12px" }}
                >
                  {/* <Box> */}
                  <Grid item xs>
                    <ColorButton
                      variant="contained"
                      startIcon={
                        <img
                          src={riskFactorIcon(value.risk_factor)}
                          style={{ width: "15px" }}
                        />
                      }
                      sx={riskFactorBtn(value.risk_factor)}
                    >
                      {value.risk_factor}
                    </ColorButton>
                  </Grid>

                  {/* </Box> */}
                  {/* <Grid container columnSpacing={2} item xs={7}> */}
                  <Grid item xs>
                    {/* <Box> */}
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 500,
                        fontFamily: "Lato",
                        color: "#858585",
                      }}
                    >
                      {value.cagr}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: 600,
                        fontFamily: "Lato",
                        color: "#089E0E",
                      }}
                    >
                      {`${value.return_percentage} % Return`}
                    </Typography>
                    {/* </Box> */}
                  </Grid>
                  <Grid item xs>
                    <Button
                      fullwidth
                      variant="contained"
                      disableElevation
                      size="large"
                      sx={{
                        backgroundColor: "#333AD6",
                        textTransform: "none",
                        [theme.breakpoints.down("sm")]: {
                          padding: "4px 8px",
                          fontSize: "10px",
                          fontWeight: 600,
                          borderRadius: "4px",
                        },
                        "&:hover": {
                          backgroundColor: "#333AD6",
                        },
                      }}
                    >
                      Invest Now
                    </Button>
                  </Grid>
                  {/* </Grid> */}
                </Grid>
              </Card>
            ))}
        </Carousel>
      )}
    </>
  );
}

export default RecommendedAssets;
