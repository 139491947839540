import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import checkIcon from "../../../assets/checkIcon.png";
import retakeIcon from "../../../assets/retakeIcon.png";
import { styled } from "@mui/material/styles";
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

const Item = styled("div")(({ theme }) => ({

          paddingLeft: "1.75rem",
            marginBottom :'1rem',
          [theme.breakpoints.down("sm")]: {
            paddingLeft: "0px",
            paddingBottom: "0.65rem",
            paddingTop: "0.65rem",
            // marginBottom :'1rem'
          }
}));

function DashboardStepComponent({step,report}) {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("md"));
    const handleClick = () => {
      console.info("You clicked the Chip.");
    };

    const handleDelete = () => {
      console.info("You clicked the delete icon.");
    };

    return (
      <Item>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} md>
            <Typography
              sx={{
                color: "#5894F6",
                fontSize: "22px",
                fontWeight: 'bold',
                fontFamily: "Lato",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "15px",
                },
              }}
            >
              {`Step ${step} : `}
              <Tooltip placement="top" title={`${step == 1?`The Risk Profiler analyzes
                the risk you wish to take for your future investments`:`The Portfolio Analyzer
                analyzes the risk you have taken in your current investments`}`}>
                <InfoIcon />
              </Tooltip>
              <span
                style={{
                  color: "#525252",
                }}
              >
                {report}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6} md align={small ? "left" :"center"}>
            <Chip
              label="Completed"
              sx={{
                backgroundColor: "#fff",
                fontSize: "1.2625rem",
                fontFamily: "Lato",
                // [theme.breakpoints.down("sm")]: {
                //   fontSize: "11px",
                // },
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
              onClick={handleClick}
              onDelete={handleDelete}
              deleteIcon={
                <img src={checkIcon} style={{ paddingRight: "0.5rem" }} />
              }
            />
          </Grid>
          <Grid item xs={6} md align={"right"}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "0.5rem solid",
                color: "#5894F6",
                textTransform: "none",
                border: "1px solid #5894F6",
                fontSize: "1.0625rem",
                fontFamily: "Lato",
                // [theme.breakpoints.down("sm")]: {
                //   fontSize: "11px",
                // },
                "&:hover": {
                  background: "none",
                },
              }}
              endIcon={<img src={retakeIcon} />}
            >
              Retake
            </Button>
          </Grid>
        </Grid>
      </Item>
    );
}

export default DashboardStepComponent;