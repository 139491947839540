import React, { useState, createRef } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Box,
  Link,
  InputAdornment,
  Stack,
} from "@mui/material";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";
import UploadProgress from "./UploadProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { riskScoreUpload } from "../../../Services/BackendAPI";
import { useSnackbar } from "notistack";
import sample from "./Sample_Excel_Upload_Sheet.xlsx";

function ExcelUpload({ getFreeReport, setFreeReport }) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [browseExcelUpload, setBrowseExcelUpload] = useState(true);
  const inputRef = createRef();

  const handleUpload = () => {
    // setSelectedFile(e.target.files)
    // if (selectedFile.name.substr(selectedFile.name.length - 4) !== '.pdf') {
    //     enqueueSnackbar('only .pdf file aloowed', { variant: 'error' });
    //   } else {
    setIsUploading(true);
    var formdata = new FormData();
    formdata.append("risk_dd_file", selectedFile);
    const body = {
      risk_dd_file: formdata,
    };
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb ${percent}% `);
      },
    };
    riskScoreUpload(formdata)
      .then(({ data, message, success }) => {
        if (success === true) {
          enqueueSnackbar(message, { variant: "success" });
          setTimeout(setIsUploading(false), 3000);
          setFreeReport(true);
          // setIsUploading(true);
        } else {
          enqueueSnackbar(message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  return (
    <Grid item container xs spacing={3}>
      <Grid item xs={12}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CloudDownloadIcon color="primary" />
          <Link
            href={sample}
            target="_blank"
            sx={{
              marginLeft: "0.625rem",
              marginRight: "0.625rem",
              color: "#194BFB",
              cursor: "pointer",
            }}
            download
          >
            Download Sample Excel File
          </Link>
          <IconButton disableRipple>
            <InfoIcon sx={{ cursor: "pointer" }} color="disabled" />
          </IconButton>
        </div>
      </Grid>
      {small && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <button
              style={{
                fontSize: "0.563rem",
                color: "#194BFB",
                cursor: "pointer",
              }}
            >
              GIF
            </button>
            <Typography
              sx={{
                color: "#194BFB",
                fontSize: "0.813rem",
                fontWeight: "700",
              }}
            >
              View Demo
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item container xs={12} direction="row" rowSpacing={2}>
        <Grid item xs={12}>
          {browseExcelUpload && (
            <Box
              borderRadius={1}
              sx={{
                border: "dashed #E2E8F0",
                borderWidth: "0.125rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "25vh",
                marginRight: "24px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "17px",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "13px",
                  },
                }}
              >
                {small ? "Upload your file, or" : "Drop your file, or "}
                <input
                  ref={inputRef}
                  accept=".xlsx, .xls, .csv"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setBrowseExcelUpload(false);
                    //   setIsUploading(true)
                  }}
                />
              </Typography>
              <Typography
                component="span"
                onClick={(e) => inputRef.current.click()}
                sx={{
                  fontSize: "17px",
                  fontWeight: "700",
                  borderBottom: "1px solid black ",
                  cursor: "pointer",
                }}
              >
                &nbsp;browse
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          {isUploading && (
            <UploadProgress
              isUploading={isUploading}
              setBrowseExcelUpload={setBrowseExcelUpload}
              setSelectedFile={setSelectedFile}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled
            id="input-width-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: "17px",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "8px",
                        whiteSpace: "break-spaces",
                      },
                    }}
                  >
                    {selectedFile !== null
                      ? selectedFile.name
                      : "Your File Here"}
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      setBrowseExcelUpload(true);
                      setSelectedFile(null);
                      setIsUploading(false);
                    }}
                  >
                    <DeleteRoundedIcon
                      sx={{ cursor: "pointer", color: "#CDCDCD" }}
                      fontSize="small"
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setBrowseExcelUpload(true);
                      setSelectedFile(null);
                      setIsUploading(false);
                    }}
                  >
                    <RefreshRoundedIcon
                      sx={{ cursor: "pointer", color: "#CDCDCD" }}
                      fontSize="small"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth={small ? true : false}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          disableElevation
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            backgroundColor: "#194BFB",
            borderRadius: "8px",
          }}
          size="large"
          fullWidth={small ? true : false}
          onClick={handleUpload}
          disabled={selectedFile === null}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}

export default ExcelUpload;
