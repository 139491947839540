import React, { useState } from "react";
import {
  Box,
  Paper,
  Container,
  Grid,
  Divider,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import { styled } from "@mui/system";
import InvestmentDetails from "./InvestmentDetails";
import RiskScore from "../RiskScore";
import RiskScoreTable from "./RiskScoreTable";
import { createStyles, withStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import Footer from './Footer';
import { useSelector, useDispatch } from "react-redux";
import UploadExcellExample from "./UploadExcellExample";
import CloseIcon from '@mui/icons-material/Close';
import OrimLogo from "../../../assets/ORIMFinal.png";

const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
}));


export default function Dashboard() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const riskScore = useSelector((state)=> state.securityDetails.riskScore);
  const initialValues =     {
    symbol: "",
    value: null,
    category: "",
  };
  const [compValues, setCompValues] = useState([
    {
      symbol: "",
      value: null,
      category: "",
    },
  ]);
  const [symbolValue, setSymbolValue] = useState("");
  const [selectValueType, setSelectedValueType] = useState("PERCENT");
  const [value, setValue] = useState("manualInput");
  const [portfolioName, setPortfolioName] = useState('');
  const [portfolioUserName, setPortfolioUserName] = useState('');
  const [openGif, setOpenGif] = useState(false);
  const [getFreeReport, setFreeReport] = useState(false);
  const dashboard = localStorage.getItem('token');

  const handleGif =() =>{
    // console.log("handleGif")
    setOpenGif(true)
  }

  const handleClose=() =>{
    // console.log("handleGif")
    setOpenGif(false)
  }
  
  return (
    <Box
      sx={{
        backgroundColor: "white",
        marginTop: !dashboard ? "20px" : 0,
        margin: !dashboard ? "2rem 4rem 2rem 4rem" : 0,
        borderRadius: "20px",
        boxShadow: "none",
        padding: "1.5rem",
        [theme.breakpoints.down("md")]: {
          margin: !dashboard ? "1.8rem" : 0,
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {small && value !== "manualInput" && !openGif && (
          <Grid item xs={6}>
            <button
              style={{
                fontSize: "0.563rem",
                color: "#AEB0B4",
                cursor: "pointer",
                margin: "1.2rem",
              }}
              onClick={handleGif}
            >
              GIF
            </button>
          </Grid>
        )}
        {!dashboard && (
          <Grid
            item
            xs={6}
            align={
              small && value !== "manualInput" && !openGif ? "right" : "left"
            }
          >
            {!small ? (
              <img src={OrimLogo} style={{ width: "15%" }} />
            ) : (
              <img src={OrimLogo} style={{ width: "50%" }} />
            )}
          </Grid>
        )}
        {openGif && (
          <Grid item xs={6} align={"right"}>
            <IconButton
              aria-label="delete"
              onClick={handleClose}
              sx={{ padding: "18px" }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {!dashboard && <Divider sx={{ m: 1 }} />}

      {!openGif ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md lg={8}>
            <Item
            // sx={{
            //   [theme.breakpoints.down("md")]: {
            //     // padding: "1.2rem",
            //   },
            // }}
            >
              <InvestmentDetails
                compValues={compValues}
                setCompValues={setCompValues}
                // initialValues={initialValues}
                selectValueType={selectValueType}
                setSelectedValueType={setSelectedValueType}
                symbolValue={symbolValue}
                setSymbolValue={setSymbolValue}
                portfolioName={portfolioName}
                setPortfolioName={setPortfolioName}
                value={value}
                setValue={setValue}
                portfolioUserName={portfolioUserName}
                setPortfolioUserName={setPortfolioUserName}
                getFreeReport={getFreeReport}
                setFreeReport={setFreeReport}
                dashboard={dashboard}
              />
            </Item>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={12} md lg>
            <Grid container spacing={2} alignItems="flex-start">
              {!small && (
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    sx={{
                      paddingLeft: "1.5rem",
                    }}
                  >
                    {value === "manualInput" && "Portfolio Allocation"}
                  </Typography>
                </Grid>
              )}

              {value === "manualInput" ? (
                <>
                  <Grid item xs={12}>
                    <Item sx={{ padding: "1.5rem 0rem 0.5rem 1.5rem" }}>
                      {!small && (
                        <>
                          <RiskScore
                            compValues={compValues}
                            setCompValues={setCompValues}
                            // initialValues={initialValues}
                            selectValueType={selectValueType}
                            setSelectedValueType={setSelectedValueType}
                            symbolValue={symbolValue}
                            setSymbolValue={setSymbolValue}
                            portfolioName={portfolioName}
                            setPortfolioName={setPortfolioName}
                            portfolioUserName={portfolioUserName}
                            getFreeReport={getFreeReport}
                            setFreeReport={setFreeReport}
                          />
                          <Divider sx={{ marginTop: "18px" }} />
                        </>
                      )}
                    </Item>
                  </Grid>

                  {!small && (
                    <Grid item xs={12}>
                      <Typography
                        variant="h4"
                        sx={{
                          paddingLeft: "1.5rem",
                        }}
                      >
                        {value === "manualInput" && "Allocation"}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Item sx={{ padding: "1.5rem 0rem 0.5rem 1.5rem" }}>
                      {!small && (
                        <RiskScoreTable
                          compValues={compValues}
                          setCompValues={setCompValues}
                          selectValueType={selectValueType}
                          setSelectedValueType={setSelectedValueType}
                          symbolValue={symbolValue}
                          setSymbolValue={setSymbolValue}
                        />
                      )}
                    </Item>
                  </Grid>
                </>
              ) : (
                <>
                  {!small && (
                    <Grid item xs={12}>
                      <Item sx={{ padding: "1.5rem 0rem 0.5rem 1.5rem" }}>
                        <UploadExcellExample />
                      </Item>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Item sx={{ padding: "1.5rem 0rem 0.5rem 1.5rem" }}>
          <UploadExcellExample />
        </Item>
      )}

      <Footer
        compValues={compValues}
        setCompValues={setCompValues}
        selectValueType={selectValueType}
        setSelectedValueType={setSelectedValueType}
        symbolValue={symbolValue}
        setSymbolValue={setSymbolValue}
        getFreeReport={getFreeReport}
        setFreeReport={setFreeReport}
        dashboard={dashboard}
      />
    </Box>
  );
}
