import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

//Client Dashboard Icons

export const HomeIcon = (props) => (
  <SvgIcon {...props}>
    <g clip-path="url(#clip0_1167_2529)">
      <path
        d="M6.5 8.12097C5.60254 8.12097 4.875 8.84851 4.875 9.74597V12.996H8.125V9.74597C8.125 8.84851 7.39746 8.12097 6.5 8.12097Z"
        // fill="#194BFB"
      />
      <path
        d="M9.20834 9.74593V12.9959H11.375C12.2725 12.9959 13 12.2684 13 11.3709V6.4304C13.0001 6.149 12.8908 5.87859 12.695 5.6764L8.09197 0.700092C7.27977 -0.178678 5.90898 -0.232633 5.03021 0.579563C4.98847 0.618156 4.94825 0.65835 4.90968 0.700092L0.314717 5.67478C0.113065 5.8778 -7.61334e-05 6.15237 3.84371e-08 6.43853V11.3709C3.84371e-08 12.2684 0.727543 12.9959 1.625 12.9959H3.79166V9.74593C3.80179 8.26891 4.99431 7.06275 6.43414 7.02802C7.92213 6.99212 9.19699 8.21881 9.20834 9.74593Z"
        // fill="#194BFB"
      />
      <path
        d="M6.5 8.12097C5.60254 8.12097 4.875 8.84851 4.875 9.74597V12.996H8.125V9.74597C8.125 8.84851 7.39746 8.12097 6.5 8.12097Z"
        // fill="#194BFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1167_2529">
        <rect width="13" height="13" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const RecommendedAssetIcon = (props) => (
  <SvgIcon {...props}>
    <g clip-path="url(#clip0_1167_2537)">
      <path
        d="M9.91663 0H4.08329C2.47504 0 1.16663 1.30842 1.16663 2.91667V11.0833C1.16663 12.6916 2.47504 14 4.08329 14H9.91663C11.5249 14 12.8333 12.6916 12.8333 11.0833V2.91667C12.8333 1.30842 11.5249 0 9.91663 0ZM5.83329 11.0833C5.83329 11.4053 5.57196 11.6667 5.24996 11.6667H4.08329C3.76129 11.6667 3.49996 11.4053 3.49996 11.0833V9.91667C3.49996 9.59467 3.76129 9.33333 4.08329 9.33333H5.24996C5.57196 9.33333 5.83329 9.59467 5.83329 9.91667V11.0833ZM5.83329 7.58333C5.83329 7.90533 5.57196 8.16667 5.24996 8.16667H4.08329C3.76129 8.16667 3.49996 7.90533 3.49996 7.58333V6.41667C3.49996 6.09467 3.76129 5.83333 4.08329 5.83333H5.24996C5.57196 5.83333 5.83329 6.09467 5.83329 6.41667V7.58333ZM5.83329 4.08333C5.83329 4.40533 5.57196 4.66667 5.24996 4.66667H4.08329C3.76129 4.66667 3.49996 4.40533 3.49996 4.08333V2.91667C3.49996 2.59467 3.76129 2.33333 4.08329 2.33333H5.24996C5.57196 2.33333 5.83329 2.59467 5.83329 2.91667V4.08333ZM9.91663 11.0833H7.58329C6.82029 11.0798 6.82088 9.92017 7.58329 9.91667H9.91663C10.6796 9.92017 10.679 11.0798 9.91663 11.0833ZM9.91663 7.58333H7.58329C6.82029 7.57983 6.82088 6.42017 7.58329 6.41667H9.91663C10.6796 6.42017 10.679 7.57983 9.91663 7.58333ZM9.91663 4.08333H7.58329C6.82029 4.07983 6.82088 2.92017 7.58329 2.91667H9.91663C10.6796 2.92017 10.679 4.07983 9.91663 4.08333Z"
        // fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1167_2537">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const RiskProfilingIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M10.625 0H5V10H13.75V3.125C13.75 2.2962 13.4208 1.50134 12.8347 0.915291C12.2487 0.32924 11.4538 0 10.625 0V0Z"
      //   fill="black"
    />
    <path
      d="M2.74688 10.0381C2.87131 10.0127 2.998 9.99992 3.125 10H3.75V0.0625C3.04415 0.206582 2.40975 0.590114 1.95418 1.14819C1.49861 1.70626 1.24985 2.40459 1.25 3.125V10.6406C1.68691 10.311 2.20342 10.1031 2.74688 10.0381Z"
      //   fill="black"
    />
    <path
      d="M13.75 11.25H3.125C2.62772 11.25 2.15081 11.4475 1.79917 11.7992C1.44754 12.1508 1.25 12.6277 1.25 13.125C1.25 13.6223 1.44754 14.0992 1.79917 14.4508C2.15081 14.8025 2.62772 15 3.125 15H10.625C11.0354 15 11.4417 14.9192 11.8209 14.7621C12.2 14.6051 12.5445 14.3749 12.8347 14.0847C13.1249 13.7945 13.3551 13.45 13.5121 13.0709C13.6692 12.6917 13.75 12.2854 13.75 11.875V11.25Z"
      //   fill="black"
    />
  </SvgIcon>
);

export const RiskAnalyserIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M5.625 10.9375C5.62579 10.1415 5.86039 9.36331 6.29967 8.69952C6.73895 8.03573 7.36355 7.51561 8.0959 7.20377C8.82825 6.89192 9.63607 6.80208 10.419 6.94542C11.202 7.08875 11.9256 7.45894 12.5 8.01V1.875C12.5 1.37772 12.3025 0.900805 11.9508 0.549175C11.5992 0.197544 11.1223 0 10.625 0L3.125 0C2.2965 0.000992411 1.50222 0.330551 0.916387 0.916387C0.330551 1.50222 0.000992411 2.2965 0 3.125V11.875C0.000992411 12.7035 0.330551 13.4978 0.916387 14.0836C1.50222 14.6694 2.2965 14.999 3.125 15H9.6875C8.61006 15 7.57675 14.572 6.81488 13.8101C6.05301 13.0483 5.625 12.0149 5.625 10.9375ZM3.125 4.375C3.125 4.20924 3.19085 4.05027 3.30806 3.93306C3.42527 3.81585 3.58424 3.75 3.75 3.75H8.75C8.91576 3.75 9.07473 3.81585 9.19194 3.93306C9.30915 4.05027 9.375 4.20924 9.375 4.375C9.375 4.54076 9.30915 4.69973 9.19194 4.81694C9.07473 4.93415 8.91576 5 8.75 5H3.75C3.58424 5 3.42527 4.93415 3.30806 4.81694C3.19085 4.69973 3.125 4.54076 3.125 4.375ZM13.5669 14.8169C13.4497 14.934 13.2907 14.9999 13.125 14.9999C12.9593 14.9999 12.8003 14.934 12.6831 14.8169L11.1788 13.3125C10.7332 13.5968 10.216 13.7486 9.6875 13.75C9.13124 13.75 8.58747 13.585 8.12496 13.276C7.66245 12.967 7.30196 12.5277 7.08909 12.0138C6.87622 11.4999 6.82052 10.9344 6.92904 10.3888C7.03756 9.84324 7.30543 9.3421 7.69876 8.94876C8.0921 8.55543 8.59324 8.28756 9.13881 8.17904C9.68438 8.07052 10.2499 8.12622 10.7638 8.33909C11.2777 8.55196 11.717 8.91245 12.026 9.37496C12.3351 9.83747 12.5 10.3812 12.5 10.9375C12.4986 11.466 12.3469 11.9832 12.0625 12.4288L13.5669 13.9331C13.684 14.0503 13.7499 14.2093 13.7499 14.375C13.7499 14.5407 13.684 14.6997 13.5669 14.8169Z"
      //   fill="#060606"
    />
  </SvgIcon>
);

//Admin Dashboard Icons

export const DashboardIcon = (props) => (
  <SvgIcon {...props}>
      <path
        d="M4.49998 0H7.87498C10.3602 0 12.375 2.01472 12.375 4.49998V7.87498C12.375 10.3602 10.3602 12.375 7.87498 12.375H4.49998C2.01472 12.375 0 10.3603 0 7.87498V4.49998C0 2.01472 2.01472 0 4.49998 0Z"

      />
      <path
        d="M19.125 0H22.5C24.9852 0 27 2.01472 27 4.49998V7.87498C27 10.3602 24.9852 12.375 22.5 12.375H19.125C16.6397 12.375 14.625 10.3602 14.625 7.87498V4.49998C14.6249 2.01472 16.6397 0 19.125 0Z"

      />
      <path
        d="M4.49998 14.625H7.87498C10.3602 14.625 12.375 16.6397 12.375 19.125V22.5C12.375 24.9852 10.3602 27 7.87498 27H4.49998C2.01472 27 0 24.9853 0 22.5V19.125C0 16.6397 2.01472 14.625 4.49998 14.625Z"

      />
      <path
        d="M19.125 14.625H22.5C24.9852 14.625 27 16.6397 27 19.125V22.5C27 24.9853 24.9852 27 22.5 27H19.125C16.6397 27 14.625 24.9853 14.625 22.5V19.125C14.6249 16.6397 16.6397 14.625 19.125 14.625Z"

      />
      
    {/* <path d="M4.49998 0H7.87498C10.3602 0 12.375 2.01472 12.375 4.49998V7.87498C12.375 10.3602 10.3602 12.375 7.87498 12.375H4.49998C2.01472 12.375 0 10.3603 0 7.87498V4.49998C0 2.01472 2.01472 0 4.49998 0Z" />
    <path d="M19.125 0H22.5C24.9852 0 27 2.01472 27 4.49998V7.87498C27 10.3602 24.9852 12.375 22.5 12.375H19.125C16.6397 12.375 14.625 10.3602 14.625 7.87498V4.49998C14.6249 2.01472 16.6397 0 19.125 0Z" />
    <path d="M4.49998 14.625H7.87498C10.3602 14.625 12.375 16.6397 12.375 19.125V22.5C12.375 24.9852 10.3602 27 7.87498 27H4.49998C2.01472 27 0 24.9853 0 22.5V19.125C0 16.6397 2.01472 14.625 4.49998 14.625Z" />
    <path d="M19.125 14.625H22.5C24.9852 14.625 27 16.6397 27 19.125V22.5C27 24.9853 24.9852 27 22.5 27H19.125C16.6397 27 14.625 24.9853 14.625 22.5V19.125C14.6249 16.6397 16.6397 14.625 19.125 14.625Z" />
   */}
  </SvgIcon>
);

export const ClientIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="8.5" cy="5.5" r="5.5"  />
    <path d="M16.5 20.5V23.6667C16.5 25.2315 15.2315 26.5 13.6667 26.5H3.33333C1.76853 26.5 0.5 25.2315 0.5 23.6667V20.5C0.5 16.0817 4.08172 12.5 8.5 12.5C12.9183 12.5 16.5 16.0817 16.5 20.5Z" />
  </SvgIcon>
);

export const InvestmentIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M7.3125 4.37396C7.30937 4.30417 7.29167 4.2375 7.29167 4.16667C7.29167 1.86562 9.15729 0 11.4583 0C13.7594 0 15.625 1.86562 15.625 4.16667H9.375C8.66875 4.16667 7.97917 4.23854 7.3125 4.37396ZM25 11.4583V17.7083H23.3469C22.6542 19.4104 21.401 20.8469 19.7917 21.7792V25H14.5833V22.9167H10.4167V25H5.20833V21.7917C2.65729 20.3125 1.04167 17.5521 1.04167 14.5833C1.04167 13.3583 1.31458 12.1969 1.79167 11.1479C0.736458 10.6458 0 9.57708 0 8.33333C0 6.61042 1.40208 5.20833 3.125 5.20833V7.29167C2.55104 7.29167 2.08333 7.75833 2.08333 8.33333C2.08333 8.83229 2.44375 9.23125 2.9125 9.33229C4.44167 7.45417 6.76979 6.25 9.375 6.25H15.625C15.7448 6.25 15.8604 6.27187 15.9802 6.27708C17.1354 3.14167 20.8333 3.125 20.8333 3.125V8.13646C21.9198 9.01042 22.8083 10.1312 23.3479 11.4583H25ZM19.7917 12.5C19.7917 11.925 19.325 11.4583 18.75 11.4583C18.175 11.4583 17.7083 11.925 17.7083 12.5C17.7083 13.075 18.175 13.5417 18.75 13.5417C19.325 13.5417 19.7917 13.075 19.7917 12.5Z"
      // fill="black"
    />
  </SvgIcon>
);

export const AddInvestmentIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M20.2664 9.59985H15.9998V7.46655H20.2664V9.59985ZM25.5996 3.19995V21.333H0V3.19995C0 1.43571 1.43571 0 3.19995 0H22.3997C24.1639 0 25.5996 1.43571 25.5996 3.19995ZM3.19995 11.7332H11.7332V9.59985H3.19995V11.7332ZM22.3997 13.8665H3.19995V15.9998H22.3997V13.8665ZM22.3997 5.33325H13.8665V11.7332H22.3997V5.33325Z"
      // fill="#194BFB"
    />
  </SvgIcon>
);

export const SettingsIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M0.355625 15.8375C1.08341 17.1006 2.69739 17.5346 3.96051 16.8068C3.96138 16.8063 3.9622 16.8058 3.96307 16.8053L4.35459 16.5792C5.09367 17.2115 5.94321 17.7019 6.86042 18.0257V18.4771C6.86042 19.9349 8.04221 21.1166 9.5 21.1166C10.9578 21.1166 12.1396 19.9349 12.1396 18.4771V18.0257C13.0569 17.7014 13.9065 17.2104 14.6454 16.5774L15.0387 16.8044C16.3021 17.5333 17.9173 17.1 18.6461 15.8366C19.375 14.5732 18.9417 12.958 17.6783 12.2291L17.2876 12.0039C17.4635 11.047 17.4635 10.0661 17.2876 9.10915L17.6783 8.88392C18.9417 8.15503 19.375 6.53993 18.6461 5.27648C17.9173 4.01307 16.3022 3.57973 15.0387 4.30862L14.6472 4.53476C13.9074 3.9032 13.0572 3.41372 12.1396 3.09095V2.63958C12.1396 1.18179 10.9578 0 9.5 0C8.04221 0 6.86042 1.18179 6.86042 2.63958V3.09095C5.94308 3.41525 5.09351 3.90621 4.35459 4.53921L3.96129 4.31134C2.69784 3.58241 1.08275 4.01575 0.353852 5.27916C-0.375044 6.54257 0.05826 8.15771 1.32171 8.8866L1.71237 9.11183C1.53651 10.0687 1.53651 11.0497 1.71237 12.0066L1.32171 12.2318C0.0617657 12.9627 -0.370136 14.5746 0.355625 15.8375ZM9.5 7.0389C11.4437 7.0389 13.0194 8.6146 13.0194 10.5583C13.0194 12.502 11.4437 14.0778 9.5 14.0778C7.55628 14.0778 5.98057 12.502 5.98057 10.5583C5.98057 8.6146 7.55628 7.0389 9.5 7.0389Z"
      // fill="black"
    />
  </SvgIcon>
);
