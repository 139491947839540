import React from 'react'
import { styled} from '@mui/system';
import { Button, useMediaQuery } from '@mui/material';
import '../../CommonComponents/fontFamilyLato.css';
import { useTheme } from '@mui/styles';

const StyledButton=styled(Button) ((props)=>{
  return {
    fontFamily:'Lato',
    fontSize:"1.25rem",
    color:props.active?'#4F45E5':'#C4C4C4',
    fontWeight:'600',
    backgroundColor:'#FFF',
    textTransform:'capitalize',
    padding:'0 1rem',
    border:`1px solid ${props.active?'#4F45E5':'#E2E8F0'}`,
    borderRadius:50,
    [props.theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem"
    },
    
    
}})

function TabButton({active,text,onClick}) {
  return (
    <StyledButton active={active} onClick={onClick}>{text}</StyledButton>
  )
}

export default TabButton