import {
  getRiskScoreAPI,
  getRecommendedAssets,
  getClientDetails,
} from "../../Services/BackendAPI";

export const fetchRiskScoreAction = (body) => (dispatch) => {
    getRiskScoreAPI(body).then(({data}) => {
        // console.log(data);
        dispatch({
            type: 'GET_RISK_SCORE',
            payload: {
                portfolioAnalyser: data.portfolio_analyser,
            },
        });
    }).catch((err)=>{
        console.log(err);
    })
   
}

export const fetchRecommendedAssetsAction = () => dispatch => {
  getRecommendedAssets()
    .then(({ data }) => {
      console.log(data);
      dispatch({
        type: "GET_RECOMMENDED_ASSETS",
        payload: data
      })
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addNewEntryInPortfolioDetailsAction = (payload, type, index, value) => (dispatch) => {
    const {portfolio_name, portfolio_username, value_type, requests} = payload;
    // console.log('INPUT_SPECIFIC_PORTFOLIO', payload, type, index, value,);
    // const portfolioDetails = requests.map((portfolio, idx) => {
    //     console.log(portfolio, idx, index);
    //     if(idx === index) {
    //         portfolio.type = value;
    //         return portfolio;
    //     }
    //     return portfolio;
    // });

    requests[index][`${type}`] = value;

    // console.log(payload, type, index, value, requests);
    dispatch({type: 'INPUT_SPECIFIC_PORTFOLIO', payload: requests});
}

// action dispatch modal

export const securityModalAction = (row,j) => (dispatch) => {
  // console.log('row',row,j)
   dispatch({
     type: "OPEN_SECURITY_MODAL",
     payload: {row,j},
   });
}

export const fetchClientDetailsAction = () => (dispatch) => {
  getClientDetails()
    .then(({ data }) => {
      // console.log(data);
      dispatch({
        type: "GET_CLIENT_DETAILS",
        payload: data,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};