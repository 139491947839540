import React,{useState, useEffect} from "react";
import {
  Box,
  Paper,
  Container,
  Grid,
  Divider,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import RiskAnalyserReport from './RiskAnalyserReport';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Data } from "./Data";
import { getRiskScoreByID } from "../../../../Services/BackendAPI";
import OrimLogo from "../../../../assets/ORIMFinal.png";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.5625rem",
  fontWeight: "700",
  color: "#7F70DB",
  fontFamily: "Lato",
  // [theme.breakpoints.down("sm")]:{
  //   fontSize: "15px",
  // }
}));

const CustomTypography2 = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: "600",
  fontFamily: "Lato",
  // [theme.breakpoints.down("sm")]:{
  //   fontSize: "12px",
  // }
  // color: "pink",
}));

function Questionaire({ 
  compValues,
  setCompValues,
  selectValueType,
  setSelectedValueType,
  symbolValue,
  setSymbolValue
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [riskAnalyserData, setRiskAnalyserData] = useState('');
  const [fetchRiskScore, setRiskScore] = useState();

  useEffect(()=>{localStorage.getItem("p");
    let payload = {
      portfolio_id: localStorage.getItem("portfolio_id")
    };
    getRiskScoreByID(payload)
      .then(({ data, success, message }) => {
          setRiskAnalyserData(data[0].portfolio_analyser);
          setRiskScore(data[0]);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  },[])
  return (
    <Box sx={{ m: 2, p: 1 }}>
      <img src={OrimLogo} style={{ width: "8%", paddingTop:'12px' }} />
      <Divider sx={{ mt: 4 }} />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: "1.5625rem",
              fontWeight: "800",
              fontFamily: "Lato",
              marginTop: "1.5rem",
              // [theme.breakpoints.down("sm")]:{
              //   fontSize: "18px",
              // }
            }}
          >
            Risk Analyser Report
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ borderLeft: "5px solid black" }}>
            <CustomTypography2 sx={{ paddingLeft: "1rem" }}>
              Over a 1 year period your portfolio has the below risk statistics
              This outcome is based upon your investment portfolio
            </CustomTypography2>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "500",
                fontFamily: "Lato",
                color: "#A0AEC0",
                paddingLeft: "1rem",
                // [theme.breakpoints.down("sm")]:{
                //   fontSize: "12px",
                // }
              }}
            >
              This portfolio is based upon your investment amount of Rs. 1000 &
              the nature of your answers.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          {fetchRiskScore !== undefined ? (
            <RiskAnalyserReport
              data={riskAnalyserData}
              fetchRiskScore={fetchRiskScore}
            />
          ) : (
            <div>"Nothing to report"</div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Questionaire;
