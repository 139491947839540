import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import "../../CommonComponents/fontFamilyLato.css";
import { useTheme } from "@mui/material/styles";
import { getPortfolioSegmentTableData } from "../../../../Services/Utilities/getPortfolioSegmentTableData";




const tableAssetClass = ["", "Asset", "Allocation"];
const tableMarketCap = ["", "Market Cap", "Allocation"];

const StyledTableContainer = styled(TableContainer)({
  fontFamily: "Lato",
  border: "1px solid #C4C4C4",
  fontSize: "1rem",
  borderRadius: "10px",
  maxWidth: "900px",
});

const HeadingRow = styled(TableRow)({
  borderBottom: 0,
  backgroundColor: "#F5F7FF",
});

const Cell = styled(TableCell)({
  fontFamily: "Lato",
  textAlign: "center",
  fontSize: "1.125rem",
  fontWeight: "800",
  borderBottom: 0,
  borderRight: "1px solid #C4C4C4",
});

const TableDataRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "#F8F8F8",
  },
});

const ColorBox = styled(Box)({
  height: "1rem",
  width: "1rem",
  margin: "0 auto",
});

const colors = ["#F5B41D", "#2754F1", "#D1D1D1", "#ff00008f"];

function PortFolioSegemntsTable({ data, heading, segmentData }) {
  const theme = useTheme();

 const tableData =
   heading === "asset-class"
     ? [
         {
           id: 1,
           name: "Equity",
           allocation: segmentData.equity_per,
         },
         {
           id: 2,
           name: "Commodity",
           allocation: segmentData.commodity_per,
         },
         {
           id: 3,
           name: "Mutual Funds",
           allocation: segmentData.mutual_fund_per,
         },
         {
           id: 3,
           name: "Real Estate",
           allocation: segmentData.real_estate_per,
         },
       ]
     : [
         {
           id: 1,
           name: "large Cap",
           allocation: segmentData.large_cap,
         },
         {
           id: 2,
           name: "Mid Cap",
           allocation: segmentData.mid_cap,
         },
         {
           id: 3,
           name: "Small Cap",
           allocation: segmentData.small_cap,
         },
       ];

//  const tableData = getPortfolioSegmentTableData(heading, segmentData);
//  console.log(tableData);
  return (
    <StyledTableContainer component={Paper} variant="outlined">
      <Table aria-label="portfolio segments table" size="medium">
        <TableHead>
          <HeadingRow>
            {heading === "asset-class"
              ? tableAssetClass.map((heading, index) => {
                  return (
                    <Cell
                      key={index}
                      sx={{
                        borderBottom: "1px solid #C4C4C4",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "15px !important",
                        },
                      }}
                    >
                      {heading}
                    </Cell>
                  );
                })
              : tableMarketCap.map((heading, index) => {
                  return (
                    <Cell
                      key={index}
                      sx={{
                        borderBottom: "1px solid #C4C4C4",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "15px !important",
                        },
                      }}
                    >
                      {heading}
                    </Cell>
                  );
                })}
          </HeadingRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => {
            return (
              <TableDataRow key={row.id}>
                <Cell>
                  <ColorBox
                    sx={{
                      backgroundColor: colors[index],
                      [theme.breakpoints.down("md")]: {
                        fontSize: "15px !important",
                      },
                    }}
                  ></ColorBox>
                </Cell>
                <Cell
                  // align='left'
                  sx={{
                    fontWeight: "500",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "15px !important",
                    },
                  }}
                >
                  {row.name}{" "}
                </Cell>
                <Cell
                  sx={{
                    color: "#FF4B4B",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "15px !important",
                    },
                  }}
                >
                  {Number(row.allocation).toFixed()}%
                </Cell>
              </TableDataRow>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

export default PortFolioSegemntsTable;
