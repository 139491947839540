import { Close } from '@mui/icons-material';
import { Backdrop, Modal, Typography,Fade, Divider, Grid, IconButton, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react'
import CustomTypography from '../../common/CustomTypography';
import RiskScore from '../../RiskScore';
import RiskScoreTable from '../../RiskScoreTable';

  const iconButtonStyle={
    position:'absolute',
    top:'1rem',
    right:'1.1rem'
  }
  const iconStyle = {
    fontSize:'1.1rem',
    color:'#000'
  };
 
function SecurityDetails({
  open,
  handleOpen,
  handleClose,
  portfolioAllocation,
  row,
}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const large = useMediaQuery(theme.breakpoints.up("lg"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: small ? "80%" : "55%",
    maxHeight: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    px: {
      xs: 2,
      md: 5,
    },
    py: {
      xs: 2,
      md: 4,
    },
    borderRadius: "1.25rem",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: "#ccc",
    },
    "::-webkit-scrollbar-button": {
      height: "10px",
    },
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          ".css-sox5kk-MuiBackdrop-root": {
            backgroundColor: "rgb(0,0,0,0.04)",
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid
              container
              justifyContent={"space-around"}
              alignItems={"flex-start"}
            >
              <Grid item xs={12} lg={5}>
                <RiskScore
                  portfolioAllocation={row.portfolio_segment.segments[0].items}
                />
              </Grid>
              <Divider orientation={large && "vertical"} flexItem />
              <Grid item xs={12} lg={5}>
                <RiskScoreTable
                  portfolioAllocation={
                    row.script_wise_analytics.level_wise_data.data
                  }
                />
              </Grid>
            </Grid>
            <IconButton sx={iconButtonStyle} onClick={handleClose}>
              <Close sx={iconStyle} />
            </IconButton>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default SecurityDetails