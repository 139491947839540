import React from 'react'
import { TextField } from '@mui/material';
import { makeStyles, propsToClassKey } from '@mui/styles';
import {styled} from '@mui/styles';
import { withStyles, createStyles } from '@mui/styles';

 const CssTextField = styled(TextField)(({ theme }) => ({
   border: "1px solid #E2E8F0",
   borderRadius: "10px",
   ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
     fontFamily: "Lato",
     color: "#E2E8F0",
   },
 }));
export default function TextFieldComponent({name,onChange,value,type="text",label,variant="outlined", disabled=false}) {
  return (
    <CssTextField 
    name={name} onChange={onChange} value={value} autoComplete='off' disabled={disabled} type={type} label={label} fullWidth variant={variant}/>
  )
}
