import {Button} from '@mui/material'
import React from 'react'

export default function SubmitButton({
    text,
    type = "submit",
    bgColor = "#194BFB",
    variant = "contained",
    onClick
}) {
    return (
      <Button
        type={type}
        sx={{
          backgroundColor: bgColor,
          textTransform: "capitalize",
          py: "0.5rem",
          fontSize: "1.1rem",
          fontWeight: "700",
          borderRadius: "0.62rem",
           boxShadow :'none',
          "&:hover": {
            backgroundColor: bgColor,
            boxShadow :'none'
          },
        }}
        fullWidth
        variant={variant}
        onClick={onClick}
      >
        {text}{" "}
      </Button>
    );
}
