import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import { styled } from "@mui/system";
import "../../CommonComponents/fontFamilyLato.css";
import {
  utcTimestampToDateString,
  splitDate,
} from "../../../../Services/Utilities";
import { useTheme } from "@mui/material/styles";
const tableHeadings = ["Period", "Historical Returns", "Benchmark"];

const StyledTableContainer = styled(TableContainer)({
  fontFamily: "Lato",
  border: "1px solid #C4C4C4",
  fontSize: "1.125rem",
  borderRadius: "10px",
});

const HeadingRow = styled(TableRow)({
  borderBottom: 0,
  backgroundColor: "#F5F7FF",
});

const Cell = styled(TableCell)({
  fontFamily: "Lato",
  textAlign: "center",
  fontSize: "1.125rem",
  fontWeight: "800",
  borderBottom: 0,
  borderRight: "1px solid #C4C4C4",
});

const TableDataRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "#F8F8F8",
  },
});

function PortfolioPerformanceTable({ data }) {
  const theme = useTheme();

  // console.log(data);

  return (
    <StyledTableContainer component={Paper} variant="outlined">
      <Table aria-label="historical returns table" size="medium">
        <TableHead>
          <HeadingRow>
            {tableHeadings.map((heading, index) => {
              return (
                <Cell
                  key={index}
                  sx={{
                    borderBottom: "1px solid #C4C4C4",
                    [theme.breakpoints.down("md")]: {
                      fontSize: "15px !important",
                    },
                  }}
                >
                  {heading}
                </Cell>
              );
            })}
          </HeadingRow>
        </TableHead>
        <TableBody>
          {data &&
            data.historical_return &&
            data.historical_return.map((row) => {
              const portfolioReturn = Math.floor(
                row.portfolio_return.toFixed(2) * 100
              );
              const indexReturn = Math.floor(row.index_return.toFixed(2) * 100);
              return (
                <TableDataRow key={row.id}>
                  <Cell
                    sx={{
                      fontWeight: "500",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "15px !important",
                      },
                    }}
                  >
                    {row.month}
                  </Cell>
                  <Cell
                    sx={{
                      color: portfolioReturn >= 0 ? "#31B182" : "#FF4B4B",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "15px !important",
                      },
                    }}
                  >
                    {portfolioReturn} %{" "}
                    {portfolioReturn >= 0 ? (
                      <ArrowDropUpIcon sx={{ verticalAlign: "top" }} />
                    ) : (
                      <ArrowDropDownIcon sx={{ verticalAlign: "top" }} />
                    )}
                  </Cell>
                  <Cell
                    sx={{
                      color: indexReturn >= 0 ? "#31B182" : "#FF4B4B",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "15px !important",
                      },
                    }}
                  >
                    {indexReturn} %
                    {indexReturn >= 0 ? (
                      <ArrowDropUpIcon sx={{ verticalAlign: "top" }} />
                    ) : (
                      <ArrowDropDownIcon sx={{ verticalAlign: "top" }} />
                    )}
                  </Cell>
                </TableDataRow>
              );
            })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}

export default PortfolioPerformanceTable;
