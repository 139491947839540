import {Grid, Stack} from '@mui/material'
import React from 'react'
import CustomTypography from '../../../common/CustomTypography'
import CustomOutlinedInput from '../../../common/CustomOutlinedInput'
import StackTwoComponents from '../../investmentTabSection/StackTwoComponents'

function ProfileDetails() {
    return (
        <>
            <CustomTypography variant={'h5'}
                fontSize={'1.375rem'}
                fontWeight={'800'}
                text={'Profile Details'}/>

            <Grid container rowSpacing={1}>
                <Grid item
                    xs={12} md={5}>
                    <Stack spacing={1}>
                        <CustomTypography variant={'h6'}
                            text={'Name/Company Name'}/>
                        <CustomOutlinedInput fullWidth placeholder='Enter Name/Company Name'/>
                    </Stack>
                </Grid>
                <Grid item
                    xs={1}></Grid>
                <Grid item
                    xs={12} md={5}>
                    <Stack spacing={1}>
                        <CustomTypography variant={'h6'}
                            text={'Email  Id'}/>
                        <CustomOutlinedInput placeholder='Enter Email Id'/>
                    </Stack>
                </Grid>
                </Grid>
                <Grid container rowSpacing={1}>
                <Grid item
                    xs={12} md={5}>
                    <Stack spacing={1}>
                        <CustomTypography variant={'h6'}
                            text={'Current Password'}/>
                        <CustomOutlinedInput type='password' fullWidth placeholder='Enter Current Password'/>
                    </Stack>
                </Grid>
                <Grid item
                    xs={1}></Grid>
                <Grid item
                    xs={12} md={5}>
                    <Stack spacing={1}>
                        <CustomTypography variant={'h6'}
                            text={'New Password'}/>
                        <CustomOutlinedInput type='password' placeholder='Set New Password'/>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default ProfileDetails
