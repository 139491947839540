import { Paper, Card, Typography, Grid, Stack, Box } from "@mui/material";
import React from "react";
import CustomCircularProgressBar from "..";
import InfoIcon from "@mui/icons-material/Info";
import {useTheme} from '@mui/material/styles';
function RiskScoreCard({ fetchRiskScore }) {
  const theme = useTheme();
  return (
    <>
      <Stack direction={"row"} alignItems="center">
        <Typography
          variant="h6"
          sx={{
            color: "#242424",
            fontFamily: "Lato",
            fontWeight: 600,
            // [theme.breakpoints.down("sm")]: {
            //   fontSize: "16px",
            // },
          }}
        >
          Risk Score
        </Typography>
        <InfoIcon sx={{ color: "#D6DEE8", height: "0.8rem" }} />
      </Stack>

      <Box
        // elevation={0}
        // alignSelf="center"
        sx={{
          mt: 3,
          py: {
            xs:1,
            md:3,
            lg:4
          },
          px: {
            xs:1,
            md:2,
            lg:3
          },
          // minWidth: '200px',
          // padding: '1rem',
          display: "inline-flex",
          border: "0.0625rem solid #E2E8F0",
          boxShadow: "0.625rem 0.625rem 2.25rem 0rem rgba(122,116,116,0.15)",
          borderRadius: "0.75rem",
        }}
      >
        <CustomCircularProgressBar fetchRiskScore={fetchRiskScore} />
      </Box>
    </>
  );
}

export default RiskScoreCard;
