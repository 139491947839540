import {
    Button,
    ButtonGroup,
    Card,
    Divider,
    FormControl,
    Grid,
    IconButton,
    Input,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material'
import {Box} from '@mui/system'
import React, {useRef, useState} from 'react'
import CustomTypography from '../../../common/CustomTypography'
// import CustomOutlinedInput from '../../../common/CustomTypography/CustomOutlinedInput'
import StackTwoComponents from '../../investmentTabSection/StackTwoComponents/index'
import {styled} from '@mui/system';
import {Close, Filter} from '@mui/icons-material'
import CustomSelectComponent from '../../investmentTabSection/CustomSelectComponent/index'
import {CustomButton} from '../../investmentTabSection/AddNewInvestment'
// import StatusButtonTabs from './StatusButtonTabs'
import { useTheme } from '@mui/material/styles'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
// export const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];


// const volatilityArr = ['Low', 'Medium', 'High']

// const statusArr = ['Active', 'Pending', 'Inactive']

export function createData(name, value) {
    return {name, value};
}

export const rows = [
    createData('CAGR', 11),
    createData('Minimum Investment', 4558755),
    createData('Return Percentage', 1.2),
    createData('Hyperlink', '_XX_'),
    createData('Volatility', 'LOW'),
    createData('Description', 'This is Future Investment.'),
];


const BorderlessTableCell = styled(TableCell)({
 border:'none',
});

// export const StyledCustomButton = styled(CustomButton)((props) => {
//     return {
//         fontSize: "1rem",
//         fontWeight: '600',
//         backgroundColor: '#194BFB',
//         textTransform: 'capitalize',
//         // padding: '0.7rem 1rem',
//         // width: '20%',
//         [props.theme.breakpoints.down('sm')]: {
//             width:'80%',
//         },
//         '&:hover': {
//             backgroundColor: '#194CCC'
//         }
//     }
// })


export const StyledTableContainer= styled(TableContainer)((props) => {
    return {
        borderRadius: "10px",
        border:"1px solid #9FA2B4",
        width:'60%',
        [props.theme.breakpoints.down('sm')]: {
            width:'100%',
            border:'none',
        },
        [props.theme.breakpoints.down('md')]: {
            width:'80%',
        },
        
        // '&:hover': {
        //     backgroundColor: '#194CCC'
        // }
    }
})

function MapNewInvestment() {
    // const inputFile = useRef(null);
    // const [selectedStatus, setSelectedStatus] = useState(0)
    const theme = useTheme();
    const medium = useMediaQuery(theme.breakpoints.down("lg"));
    const small = useMediaQuery(theme.breakpoints.down("sm"));
    // const handleStatusChange = (index) => {
    //     setSelectedStatus(index)
    // }
    return (
        <>    
        {/* <div style={{marginBottom: '1.25rem'}}>

                    <CustomTypography variant={'h5'}
                        text={'Map New Investment'}/>
                </div>
                <form>
                <Grid container
                    columnSpacing={2}
                    rowSpacing={2}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    pl={small?2:0}>
                    <Grid item
                       xs={12} md={6} lg={4}>
                        <Stack>
                            <CustomTypography variant={'h6'}
                                text={'Investment Option'}/>
                            <CustomSelectComponent width={medium?'100%':'40%'}
                                placeholder={'Low'}
                                arr={
                                    ['Low', 'Moderate', 'High']
                                }/>
                        </Stack>
                    </Grid>
                    <Grid item
                     xs={12} md={6} lg={4}>
                        <Stack>
                            <CustomTypography variant={'h6'}
                                text={'Risk Factor'}/>
                            <CustomSelectComponent width={medium?'100%':'60%'}
                                placeholder={'Moderate'}
                                arr={
                                    ['Low', 'Moderate', 'High']
                                }/>
                        </Stack>
                    </Grid>
                    <Grid item
                       xs={12} md={6} lg={4}>
                        <Stack>
                            <CustomTypography variant={'h6'}
                                text={'Status'}/>
                            <Stack direction={'row'}
                                spacing={3}
                                justifyContent={small?'space-between':''}>

                                {
                                ['Active', 'Inactive', 'Pending'].map((status, index) => {
                                    // console.log(index, selectedStatus, 'selected')
                                    return <StatusButtonTabs key={index}
                                        active={
                                            index === selectedStatus ? 1 : 0
                                        }
                                        text={status}
                                        onClick={
                                            () => handleStatusChange(index)
                                        }/>
                                })
                            } </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='center'
                    py={4}>
                    <StyledCustomButton sx={{px:4,py:1}} disableRipple variant='contained'>Map Investment Option</StyledCustomButton>
                </Box> */}
                <Divider color='#A0AEC0'/>
                <Box display='flex' justifyContent={'center'} py={4}>
                    <TableContainer component={Paper}
                        elevation={0}
                        sx={
                            {
                                borderRadius: "10px",
                                border:small?'none': "1px solid #9FA2B4",
                                width:small?'100%':medium?'80%':'60%',
                            }
                    }
                    >
                        <Table 
                            aria-label="investment mapper table">
                            <TableBody > 
                                {
                                (rows).map((row,index) => (
                                    <TableRow  key={index}>
                                        <BorderlessTableCell width='60%' component="th" scope="row">
                                            {row.name}
                                            </BorderlessTableCell>
                                        <BorderlessTableCell  align={small?'right':'left'} >
                                            {row.value} 
                                            </BorderlessTableCell>
                                    </TableRow>
                                ))
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            {/* </form> */}
        </>
    )
}

export default MapNewInvestment
