import { Card, Divider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ExpectedReturnsChart from '../../../Charts/ExpectedReturnsChart'
import '../../fontFamilyLato.css'
import {useTheme} from '@mui/material/styles';
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";


const toIndianCurrency = (num) => {
  const curr = num.toLocaleString('en-IN', {
     style: 'currency',
     currency: 'INR'
  });
return curr.slice(0,-3);
};

function InvestmentValueCard({data}) {
  const theme = useTheme();
  return (
    <>
      {/* <Card variant='outlined'> */}
      <Stack alignItems="flex-start">
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Lato",
            // fontSize: "1.0625rem",
            color: "#242424",
            fontWeight: 600,
          }}
        >
          Investment Value
        </Typography>

        <Typography
          variant="h4"
          sx={{
            fontFamily: "Lato",
            // fontSize: "1.8125rem",
            fontWeight: "700",
            color: "#629AF6",
            // [theme.breakpoints.down("sm")]: {
            //   fontSize: "17px",
            // },
          }}
        >
          <CurrencyRupeeIcon /> {data.investment_value}
        </Typography>
      </Stack>
      {/* <Divider sx={{margin: "18px 0px"}}/> */}
      <hr
        style={{
          border: "none",
          borderTop: "1px dashed #E2E8F0",
          // color: "#fff",
          backgroundColor: "#fff",
          height: "1px",
          width: "100%",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      />
      {/* <Box display='flex' flexDirection={'column'}  justifyContent={'center'}> */}
      <Typography
        align="left"
        variant="h6"
        sx={{
          fontFamily: "Lato",
          // fontSize: "1.0625rem",
          color: "#242424",
          marginBottom: "1rem",
          fontWeight: 600,
        }}
      >
        Expected Returns
      </Typography>

      <ExpectedReturnsChart data={data} />

      {/* </Box> */}

      {/* </Card> */}
    </>
  );
}

export default InvestmentValueCard