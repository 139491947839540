import React,{useEffect,useState} from 'react'
import TableWrapper from '../Index';
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Headers } from "./arrHeaders";
import GenerateExcel from "./GenerateExcel";
import { createTheme } from "@mui/material/styles";

export default function UserTable() {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {!matches && (
        <Grid container pb={2} justifyContent="flex-end" columnGap={3}>
          <GenerateExcel />
        </Grid>
      )}
      <TableWrapper tableType="client-details" headers={Headers} />
      {matches && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GenerateExcel />
        </Grid>
      )}
    </>
  );
}
