import React from 'react';
import {
    Grid,
    Divider,
    Typography,
  } from "@mui/material";
import "./style.css";
import SimpleSlider from './SlickSlider';
import { useTheme } from '@mui/material/styles';

function UploadExcellExample(props) {
    const theme = useTheme();

  
    const data = [
      { Heading: "Step 1 :", Text: " Download the excel file" },
      { Heading: "Step 2 :", Text: "Check the worksheet named “Sample” " },
      {
        Heading: "Step 3 :",
        Text: " Enter your portfolio details in the “Main” worksheet as explained in “Sample”",
      },
      { Heading: "Step 4 :", Text: "Save and upload the excel sheet" },
    ];
    return (<>

    <Typography   sx={{
               fontSize: "18px", fontWeight: "700", fontFamily: "Lato", 
               marginBottom: '2rem',
               [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
                fontWeight: "600"
            },
          }}>
    Uploading an excel is simpler than you think!
        </Typography>
        <Grid container alignContent='flex-start' spacing={2}>

            <Grid item xs={12}>
            <SimpleSlider />
            </Grid>

           { data.map((value, index) =>
           (<>  <Grid item xs={12} constainer spacing={2}>
           <Grid item xs={12}>
           <Typography key={index} sx={{fontSize:'20px',fontWeight:'600', fontFamily:'Lato',
          [theme.breakpoints.down("sm")]: {
            fontSize: "17px",
        },
        }}>{value.Heading}</Typography>
           </Grid>
           
            <Grid item xs={12}>
            <Typography sx={{fontSize:'15px',fontWeight:'500', fontFamily:'Lato',color :'#9B9B9B',
         [theme.breakpoints.down("sm")]: {
            fontSize: "13px",
        },
        }}>{value.Text}</Typography>
            </Grid>
            </Grid>
           </>
        ))}
           
        </Grid>
   </> );
}

export default UploadExcellExample;