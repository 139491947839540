import React from "react";
import { styled } from "@mui/system";
import { Table, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Box } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import TableLoader from "./TableLoader";
import { useTheme } from "@mui/material/styles";

const CellWithRightBorder = withStyles((theme) =>
  createStyles({
    root: {
      borderRight: "1px solid #e9e8f7",
      borderRightColor: "#e9e8f7",
      backgroundColor: "#F8FBFF",
      padding: "4px",
      margin: "0px",
      borderBottom: "none",
      " &:last-child": {
        borderRight: "none",
      },
    },
  })
)(TableCell);

const RowsWithRightBorder = withStyles((theme) =>
  createStyles({
    root: {
      borderRight: "1px solid #e9e8f7",
      borderRightColor: "#e9e8f7",
      padding: "4px",
      " &:last-child": {
        borderRight: "none",
      },
    },
  })
)(TableCell);

function RiskScoreChart({
  compValues,
  setCompValues,
  selectValueType,
  setSelectedValueType,
  symbolValue,
  setSymbolValue,
}) {
  const theme = useTheme();

  const tableTitle =()=>{
    if(selectValueType === 'PERCENT'){
      return 'Percentage'
    } else if(selectValueType === 'PRICES'){
      return 'INR Value'
    } else{
      return 'Quantity'
    }
  }

  return (
    <>
      {compValues[0].value !== null ? (
        <Box m={1}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              borderRadius: "10px",
              border: "1px solid #e9e8f7",
              padding: 0,
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <CellWithRightBorder
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 500,
                        fontSize: "10px",
                        padding: "8px",
                      },
                    }}
                    align="center"
                  >
                    Asset
                  </CellWithRightBorder>
                  <CellWithRightBorder
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 500,
                        fontSize: "10px",
                        padding: "8px",
                      },
                    }}
                    align="center"
                  >
                    Commodity
                  </CellWithRightBorder>
                  <CellWithRightBorder
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      [theme.breakpoints.down("sm")]: {
                        fontWeight: 500,
                        fontSize: "10px",
                        padding: "8px",
                      },
                    }}
                    align="center"
                  >
                    {/* {selectValueType} */}
                    {tableTitle()}
                  </CellWithRightBorder>
                </TableRow>
              </TableHead>
              <TableBody>
                {compValues.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ background: i % 2 ? "#F6F6F6" : "#FFFFFF" }}
                  >
                    <RowsWithRightBorder
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        fontFamily: "Lato",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "10px",
                          padding: "8px",
                        },
                      }}
                      align="center"
                    >
                      {row.category}
                    </RowsWithRightBorder>
                    <RowsWithRightBorder
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        fontFamily: "Lato",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "10px",
                          padding: "8px",
                        },
                      }}
                      align="center"
                    >
                      {row.symbol}
                    </RowsWithRightBorder>
                    <RowsWithRightBorder
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        fontFamily: "Lato",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "10px",
                          padding: "8px",
                        },
                      }}
                      align="center"
                    >
                      {row.value}
                    </RowsWithRightBorder>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <TableLoader />
      )}
    </>
  );
}

export default RiskScoreChart;
