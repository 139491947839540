import React from "react";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Box,  } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import CustomTypography from "../common/CustomTypography";

const CellWithRightBorder = withStyles((theme) =>
  createStyles({
    root: {
     borderRight: "1px solid #e9e8f7",
      borderRightColor: "#e9e8f7",
      fontSize: "0.875rem",
      fontWeight:600,
      backgroundColor: "aliceblue",
      " &:last-child": {
        borderRight: "none",
      },
    },
  })
)(TableCell);

const RowsWithRightBorder = withStyles((theme) =>
  createStyles({
    root: {
      borderRight: "1px solid #e9e8f7",
      borderRightColor: "#e9e8f7",
      textTransform:'capitalize',
      fontSize: "0.8125rem",

      " &:last-child": {
        borderRight: "none",
      },
    },
  })
)(TableCell);
function RiskScoreTable({portfolioAllocation}) {
 
  return (
    <>
      <CustomTypography variant={"h6"} text={"Allocation"} />
      {portfolioAllocation !== "" ? (
        <Box mt={4}>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              borderRadius: "15px",
              border: "2px solid #e9e8f7",
            }}
          >
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <CellWithRightBorder align="center">
                    Asset
                  </CellWithRightBorder>
                  {/* <CellWithRightBorder align="center">
                  Script
                  </CellWithRightBorder> */}
                  <CellWithRightBorder align="center">
                    Holdings
                  </CellWithRightBorder>
                </TableRow>
              </TableHead>
              <TableBody>
                {portfolioAllocation.map((row, i) => (
                  <TableRow key={i}>
                    <RowsWithRightBorder align="center">
                      {row.security}
                    </RowsWithRightBorder>
                    {/* <RowsWithRightBorder align="center">
                      {row.symbol}
                    </RowsWithRightBorder> */}
                    <RowsWithRightBorder align="center">
                      {row.holdings}
                    </RowsWithRightBorder>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default RiskScoreTable;
