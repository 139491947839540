import { Grid, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { createStyles, withStyles } from "@mui/styles";
import {data} from '../Dashboard/apiData';
import { Tooltip } from "@mui/material";

const TitleTypography = withStyles((theme) =>
  createStyles({
    root: {
      padding: "18px",
      color: "#245AB4",
      fontWeight: 600,
    },
  })
)(Typography);

const ValueTypography = withStyles((theme) =>
  createStyles({
    root: {
      padding: "18px",
      color: "#0C0C0C",
      fontWeight: 800,
    },
  })
)(Typography);

function PortfolioStatistics({data}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));


  const stats = [
    {
      title: "Volatility",
      value: data && data.volatility.toFixed(1)+"%",
      tool: `Volatility is the deviation of the
      portfolio value from its mean over a 3 year period`
    },
    {
      title: "Beta",
      value: data && data.sharpe_ratio.toFixed(1),
      tool: `Beta measures the expected move
      in the portfolio compared to the benchmark`
    },
    {
      title: "Sharpe Ratio",
      value: data && data.overall_beta.toFixed(1),
      tool: `Sharpe ratio signifies the
      risk adjusted returns generated by the portfolio`
    },
    {
      title: "Drawdown",
      value: data && data.max_draw_down.toFixed(1),
    },
  ];
  return (
    <Stack spacing={2} sx={{ padding: "1rem" }}>
      {/* <Typography
        sx={{
          color: "#242424",
          fontWeight: 500,
          fontSize: small ? "0.875rem" : "1.25rem",
        }}
      >
        Other Stats
      </Typography> */}

      <Grid
        container
        // direction='row'
        // justifyContent='flex-start'
        sx={{ border: "0.063rem solid #C4C4C4", borderRadius: "0.625rem" }}
      >
        {stats.map((stat, index) => (
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRight:
                index % 2 === 0 && !small ? "0.063rem solid #C4C4C4" : "",
              borderBottom:
                index === stats.length - 1
                  ? ""
                  : index === stats.length - 2
                  ? small && "0.063rem solid #C4C4C4"
                  : "0.063rem solid #C4C4C4",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                xs={6}
                sx={{ borderRight: small ? "0.063rem solid #C4C4C4" : "" }}
              >
                <TitleTypography
                  sx={{
                    fontSize: small ? "0.938rem" : "1.256rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontFamily: "Lato",
                    fontWeight:600
                  }}
                >
                  {stat.title}
                  {stat.tool && <Tooltip placement="top" title={stat.tool}>
                    <InfoIcon
                      fontSize="small"
                      color="disabled"
                      sx={{ paddingLeft: "4px", cursor: "pointer" }}
                    />
                  </Tooltip>}
                </TitleTypography>
              </Grid>
              <Grid item xs={6} align="center">
                <ValueTypography
                  sx={{
                    fontSize: small ? "0.938rem" : "1.35rem",
                    fontFamily: "Lato",
                    fontWeight:600
                  }}
                >
                  {stat.value}
                </ValueTypography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default PortfolioStatistics;
