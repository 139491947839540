import { Close } from '@mui/icons-material';
import { Backdrop, Modal, Typography,Fade, Divider, Grid, IconButton, Stack, useMediaQuery, TextField, styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react'
import CustomTypography from '../../common/CustomTypography';
import RiskScore from '../../RiskScore';
import RiskScoreTable from '../../RiskScoreTable';

  const iconButtonStyle={
    position:'absolute',
    top:'1rem',
    right:'1.1rem'
  }
  const iconStyle = {
    fontSize:'1.1rem',
    color:'#000'
  };
  const StyledTextfield = styled(TextField)(({ theme, myColor }) => ({
    backgroundColor: myColor,
    width: '100%',
    fieldset:{
        borderRadius:'10px'
    }
  }));
  const StyledBox = styled(Box)(({ theme, myColor }) => ({
    border:'1px solid #DFE0EB',
    height:'250px',
    width:'100%',
    cursor:'text',
    borderRadius:'10px',
    padding: '0.7rem'
  }));
  
  
function DescriptionModal({open,handleOpen,handleClose,row}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: small ? "80%" : "50%",
    maxHeight: "60%",
    bgcolor: "#FFF",
    boxShadow: 24,
    px: 6,
    py: 4,
    borderRadius: "1.25rem",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      px: 2,
      py: 2,
    },
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          ".css-sox5kk-MuiBackdrop-root": {
            backgroundColor: "rgb(0,0,0,0.04)",
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <CustomTypography variant={"h6"} text={"Description"} />
              <IconButton onClick={handleClose}>
                <Close sx={iconStyle} />
              </IconButton>
            </Stack>
            <Grid container py={3}>
              <StyledBox>
                <Typography>{row.description}</Typography>
              </StyledBox>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default DescriptionModal