import axios from "axios";
const getHeaders = () => ({
  "x-authorization-token": localStorage.getItem("token"),
  "Content-Type": "application/json",
  username: localStorage.getItem("username"),
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
});

const getHeadersManualUpload = () => ({
  "x-authorization-token": localStorage.getItem("token"),
  "Content-Type": "multipart/form-data",
  username: localStorage.getItem("username"),
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
});

const getHeadersSearch = () => ({
  "x-device-type": "web",
  "x-device-id": "123456789",
    "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
  "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"

});

const baseURL =
  process.env.NODE_ENV === "development"
    && "http://localhost:3000"

export function orimRiskScore({ method, endpoint, params, data, headers }) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true || data.error.code === 0) {
          resolve(data);
        } else if (
          data.success === "error" ||
          data.error.code === 1 ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimdumperAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.status === "Success") {
          resolve(data);
        } else if (data.status === "error") {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimdumperAPITwo({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.success === true) {
          resolve(data);
        } else if (data.success === false) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        console.log(err);
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimdumperSignAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  // bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.error.code === 0) {
          resolve(data);
        } else if (data.error.code === 1) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (
          errorResponse.data &&
          errorResponse.data.error &&
          errorResponse.data.error.message
        ) {
          reject(errorResponse.data.error.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimInvestmentDetailsTwo({
  method,
  endpoint,
  params,
  data,
  headers,
  bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
       baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (
          data.success === true ||
          data.error.code === 0 ||
          data.status === "Success"
        ) {
          resolve(data);
        } else if (
          data.status === "error" ||
          data.error.code === 1 ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        console.log(err, "error");
        const errorResponse = (err && err.response) || {};
        if (!bypassUnauthorizedCheck && errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

export function orimManualUploadAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL,
      url: endpoint,
      headers: getHeadersManualUpload(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (
          data.success === true ||
          data.error.code === 0 ||
          data.status === "Success"
        ) {
          resolve(data);
        } else if (
          data.status === "error" ||
          data.error.code === 1 ||
          data.success === false
        ) {
          reject(data.message || "error");
        } else {
          reject("Invalid response");
        }
      })
      .catch((err) => {
        console.log(err, "error");
        const errorResponse = (err && err.response) || {};
        if (!bypassUnauthorizedCheck && errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem("token");
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
        } else {
          reject(errorResponse.statusText);
        }
      });
  });
}

