import { Link, Typography } from "@mui/material";
import { borderRadius } from "@mui/system";


const statusColorChecker = (status) => {
  const statusLowerCase = status.toLowerCase();
  if (statusLowerCase === "active") {
    return {
      background: "#29CC97",
      height: "24px",
      width: "76px",
      borderRadius: "100px",
      color: "white",
      textAlign: "center",
    };
  } else if (statusLowerCase === "inactive") {
    return {
      background: "#F12B2C",
      height: "24px",
      width: "76px",
      borderRadius: "100px",
      color: "white",
      textAlign: "center",
    };
  } else {
    return {
      background: "#FEC400",
      height: "24px",
      width: "76px",
      borderRadius: "100px",
      color: "white",
      textAlign: "center",
    };
  }
};


export const Headers = [
  {
    label: "Image & Name",
    keyName: "portfolio_name",
    muiStyling: { align: "left" },
    formatter: ({ name }) => (
      <Typography fontSize="14px" fontWeight={600}>
        {name}
      </Typography>
    ),
  },
  {
    label: "Description",
    keyName: "portfolio_username",
    muiStyling: { align: "left" },
    formatter: ({ description }) => {
      return (
        <Typography fontSize="14px" fontWeight={600}>
          {description.charAt(0).toUpperCase() + description.slice(1)}
        </Typography>
      );
    },
  },
  {
    label: "Volatility",
    keyName: "phone_number",
    muiStyling: { align: "left" },
    formatter: ({ volatility }) => {
      return (
        <Typography fontSize="14px" fontWeight={600}>
          {volatility.charAt(0).toUpperCase() + volatility.slice(1)}
        </Typography>
      );
    },
  },
  {
    label: "CAGR",
    keyName: "phone_number",
    muiStyling: { align: "right", width: "20px" },
    formatter: ({ cagr }) => {
      return (
        <Typography fontSize="14px" fontWeight={600}>
          {cagr.charAt(0).toUpperCase() + cagr.slice(1)}
        </Typography>
      );
    },
  },
  {
    label: "Return Percentage",
    keyName: "phone_number",
    muiStyling: { align: "right", width: "20px" },
    formatter: ({ return_percentage }) => {
      return (
        <Typography fontSize="14px" fontWeight={600}>
          {return_percentage}
        </Typography>
      );
    },
  },
  {
    label: "Hyperlink",
    keyName: "phone_number",
    muiStyling: { align: "left" },
    formatter: ({ hyperlink }) => {
      return (
        <Typography fontSize="14px" fontWeight={600}>
          <Link href={hyperlink} underline="always">
            Link
          </Link>
        </Typography>
      );
    },
  },
  {
    label: "Status",
    keyName: "status",
    muiStyling: { align: "left" },
    formatter: ({ status }) => {
      return (
        <div style={statusColorChecker(status)}>
          <Typography
            sx={{ fontSize: "12px", fontWeight: "700", padding: "5px 11px" }}
          >
            {status.toUpperCase()}
          </Typography>
        </div>
      );
    },
  },
  {
    label: "",
    keyName: "morepopover",
    muiStyling: { align: "right" },
  }
];
