import {Grid, Stack, useMediaQuery, useTheme} from '@mui/material'
import React from 'react'

function StackTwoComponents({containerOne, containerTwo,spacing=5}) {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Stack   direction={{ xs: 'column', sm: 'row' }}
            spacing={small?1:spacing} >

            <Grid container direction='column' 
                rowSpacing={1.2}>
                <Grid item>
                    {
                    containerOne[0]
                } </Grid>
                <Grid item>
                    {
                    containerOne[1]
                } </Grid>
            </Grid>
            <Grid container direction='column'
                rowSpacing={1.2}>
                    {
                    containerTwo[0]
                }
                <Grid item>
                    {
                    containerTwo[1]
                } </Grid>
            </Grid>


        </Stack>
    )
}

export default StackTwoComponents
