import { Add } from '@mui/icons-material'
import { Avatar, AvatarGroup, Box, Button, Grid, IconButton, List, ListItem, Stack, useMediaQuery ,Paper} from '@mui/material'
import { styled } from '@mui/system'
import React, { useState } from 'react'
import CustomTypography from '../../common/CustomTypography';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import StackTwoComponents from '../investmentTabSection/StackTwoComponents';
// import CustomOutlinedInput from '../../common/CustomTypography/CustomOutlinedInput';
import ProfileDetails from './ProfileDetails';
import ColorPickerModal from '../../modals/ColorPickerModal';
import LogoUpload from './LogoUpload';
import { useTheme } from '@mui/material/styles'
const colors=[
    'orange','pink','blue','#acff7b',
]

export const StyledAvatar = styled(Avatar)((props) => {
    return {
        backgroundColor:props.color?props.color:'#D9D9D9',
        height:'4rem',
        width:'4rem',
        [props.theme.breakpoints.down('md')]: {
          height:'2.75rem',
          width:'2.75rem',
          },
          [props.theme.breakpoints.down('sm')]: {
            height:'2rem',
            width:'2rem',
            },
        '& .MuiAvatar-fallback':{
            display:'none'
        }
        // fontSize: "1rem",
        // fontWeight: '600',
        // backgroundColor: '#194BFB',
        // textTransform: 'capitalize',
        // padding: '0.7rem 1rem',
        // width: '20%',
        // [props.theme.breakpoints.down('sm')]: {
        //     fontSize: "0.75rem"
        // },
        // '&:hover': {
        //     backgroundColor: '#194CCC'
        // }
    }
})

export const StyledDiv = styled('div')((props) => {
    return {
        cursor:'pointer',
    }
})
function SettingsSection() {
  const [color, setColor] = useColor("hex", "#121212");
  const [showPickColors,setShowPickColors] = useState(false)
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const handleOpenColorPicker=()=>{
    setShowPickColors(true)
  }
  const handleCloseColorPicker=()=>{
    setShowPickColors(false)
  }
  return (
    <Paper sx={{ padding: "2rem", boxShadow: "none" }} disableElevation>
      <Stack spacing={3}>
        <LogoUpload />
        {/* <CustomTypography variant={'h5'} fontSize={'1.375rem'}  fontWeight={'800'} text={'Logo Selection'}/> */}
        <CustomTypography
          variant={"h5"}
          fontSize={"1.375rem"}
          fontWeight={"800"}
          text={"Color Selection"}
        />
        <Stack direction="row" gap={2}>
          {colors.map((color, index) => {
            return (
              <StyledDiv key={index} onClick={(e) => console.log(e)}>
                <StyledAvatar color={color} />
              </StyledDiv>
            );
          })}
          <StyledDiv onClick={() => setShowPickColors(true)}>
            <StyledAvatar>
              <Add
                sx={{ color: "#000", fontSize: small ? "1.2rem" : "1.8rem" }}
              />
            </StyledAvatar>
          </StyledDiv>
          {showPickColors && (
            <ColorPickerModal
              open={showPickColors}
              color={color}
              setColor={setColor}
              handleOpen={handleOpenColorPicker}
              handleClose={handleCloseColorPicker}
            ></ColorPickerModal>
          )}
        </Stack>
        <ProfileDetails />
      </Stack>
    </Paper>
  );
}

export default SettingsSection