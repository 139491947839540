import { Box, Stack } from '@mui/material';
import React,{useState} from 'react';

import TabButton from '../CommonComponents/TabButton';
import { StyledHeadingTypography } from '../Dashboard/PortfolioPerformance/TitleSection/custom';
import ScripWiseAnalyticsTable from '../Tables/ScripWiseAnalyticsTable';
import { useTheme }  from '@mui/material/styles';

function ScripWiseAnalytics({data}) {
  const theme = useTheme();
  const texts=[
    data && data.name
  ]
  const [activeIndex,setActiveIndex] = useState(0);
  const handleActiveIndexChange=(index)=>{
    setActiveIndex(index);
  }
  return (
    <Stack spacing={2}>
        <StyledHeadingTypography
          sx={{
            [theme.breakpoints.down("md")]:{
              fontSize:"14px !important",
          }}}
        >Script Wise Analytics </StyledHeadingTypography>
        <Stack direction='row' spacing={{
          xs:1,md:3
        }}>
          {
            texts.map((text,index)=>{
             return <TabButton active={index===activeIndex?true:false} text={text}
             onClick={()=>handleActiveIndexChange(index)}/>
            })
          }
        </Stack>
        <ScripWiseAnalyticsTable data={data}/>
    </Stack>
  )
}

export default ScripWiseAnalytics