import React from "react";
import {
  Button,
  Container,
  useMediaQuery,
  Grid,
  Typography,
  useTheme,
  Link
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import footerLanding from '../../../assets/footerLanding.png';

function FooterDashboard({ handleExportPDF, refPDF, showDownloadBtn=false }) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
      >
        {/* {(showDownloadBtn &&
          <Grid item xs={12} md={12}>
            <Grid
              container
              direction={small ? "column-reverse" : "row"}
              justifyContent="center"
              alignItems="center"
              spacing={6}
            >
              <Grid item xs={12} md={6} align={small ? "center" : "right"}>
                <Button
                  variant="text"
                  sx={{
                    borderBottom: "1px solid #5894F6",
                    textTransform: "none",
                    fontFamily: "Lato",
                    borderRadius: "0px",
                    "&:hover": {
                      background: "none",
                      boxShadow: "none",
                    },
                  }}
                >
                  Know More
                </Button>
              </Grid>
              <Grid item xs={12} md={6} align={small ? "center" : ""}>
                <Button
                  onClick={handleExportPDF}
                  variant="contained"
                  sx={{
                    backgroundColor: "#194BFB",
                    textTransform: "none",
                    fontFamily: "Lato",
                    "&:hover": {
                      backgroundColor: "#194BFB",
                      boxShadow: "none",
                    },
                  }}
                  disableElevation
                  startIcon={<DownloadIcon />}
                >
                  Download Report
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )} */}

        <Grid item xs={12} md={12}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={12} align="center">
              <Typography
                sx={{
                  fontSize: "1.125rem",
                  color: "#909090",
                  fontFamily: "Lato",
                }}
              >
                <Link href="https://app.orim.in/terms-condition" target="_blank" underline="none">Privacy Policy</Link>,&nbsp; 
                <Link href="https://app.orim.in/terms-condition" target="_blank" underline="none">Terms & Conditions</Link> &&nbsp; 
                <Link href="https://app.orim.in/terms-condition" target="_blank" underline="none">FAQ</Link>
              </Typography>
              <Typography
                sx={{
                  paddingTop: "1rem",
                  color: "#5894F6",
                  fontFamily: "Lato",
                }}
              >
                Version 1.0.0.1
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <img src={footerLanding} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FooterDashboard;
