import {
  orimRiskScore,
  orimdumperAPI,
  orimdumperAPITwo,
  orimdumperSignAPI,
  orimInvestmentDetailsTwo,
  orimManualUploadAPI
} from "./request";

export function getRiskScoreAPI(body){
    return orimdumperAPITwo({
      endpoint: `/orimdumper/api/v1/calculations`,
      method: "POST",
      data: body,
      // headers:{}
    });
}


export function getSearchScript(payload){
    return orimRiskScore({
      endpoint: `/orimdumper/api/v1/search?symbol=${payload.symbol}&category=${payload.category}`,
      method: "GET",
      // data: payload,
      // headers:{}
    });
}

export function orimLogin(payload){
    return orimdumperSignAPI({
      endpoint: `/orimdumper/api/v1/login`,
      method: "POST",
      data: payload,
    });
}

export function orimSignUp(payload){
    return orimdumperSignAPI({
        endpoint: `/orimdumper/api/v1/signup`,
        method: 'POST',
        data: payload
    })
}

export function orimLogout(){
    return orimdumperSignAPI({
        endpoint: `/orimdumper/api/v1/logout`,
        method: 'GET',
        headers : {
            "username" : localStorage.getItem("username"),
            "auth-token": localStorage.getItem("token")
        }
        
    })
}

export function riskScoreUpload(payload){
    return orimRiskScore({
        endpoint: `/orimdumper/api/v1/risk-score-file`,
        method: 'POST',
        data: payload
    })
}

export function getRiskScoreByID(body){
    return orimdumperAPITwo({
      endpoint: `/orimdumper/api/v1/risk-profile-data`,
      method: "POST",
      data: body,
    });
}

export function getRecommendedAssets() {
  return orimdumperAPI({
    endpoint: `/orimdumper/api/v1/investment-details-list?page_no=1&user_type=ADMIN`,
    method: "GET",
  });
}

export function investmentDetails(payload) {
  return orimInvestmentDetailsTwo({
    endpoint: `/orimdumper/api/v1/investment-details`,
    method: "POST",
    data: payload,
  });
}

export function getClientDetails() {
  return orimdumperAPI({
    endpoint: `/orimdumper/api/v1/client-details?page_no=1&user_type=ADMIN`,
    method: "GET",
  });
}


export function deleteInvestmentDetails(portfolioName) {
  return orimdumperAPITwo({
    endpoint: `/orimdumper/api/v1/delete-investment-details?portfolio_name=${portfolioName}`,
    method: "DELETE",
  });
}

export function updateInvestmentDetails(payload) {
  return orimdumperAPITwo({
    endpoint: `/orimdumper/api/v1/update-investment-details`,
    method: "PUT",
    data: payload,
  });
}

// Corporate Action Add
export function addCorporateAction(payload) {
  return orimRiskScore({
    endpoint: `/orimdumper/api/v1/corporate-action-add`,
    method: "POST",
    data: payload
  })
}

// Corporate Action Fetch
export function fetchCorporateAction() {
  return orimRiskScore({
    endpoint: `/orimdumper/api/v1/corporate-action-list`,
    method: "GET",
  })
}

// Get commodity options
export function getCommodityOptions() {
  return orimRiskScore({
    endpoint: `/orimdumper/api/v1/get-commodity-file details`,
    method: "GET",
  })
}

//Upload Commodity Details
export function uploadCommodityOptions(payload) {
  return orimManualUploadAPI({
    endpoint: `/orimdumper/api/v1/upload-commodity-prices`,
    method: "POST",
    // headers: {
    //   'Content-Type': 'multipart/form-data'
    // },
    data: payload
  })
}

export function uploadRealEstateData(payload) {
  return orimManualUploadAPI({
    endpoint: `/orimdumper/api/v1/upload-real-estate-prices`,
    method: "POST",
    // headers: {
    //   'Content-Type': 'multipart/form-data'
    // },
    data: payload
  })
}