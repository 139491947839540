import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import "../../CommonComponents/fontFamilyLato.css";
import TabButton from "../../CommonComponents/TabButton";
import { useTheme } from "@mui/material/styles";


const tableHeadings = [
  "Security",
  "Holdings %",
  "Risk Score",
  "Upside Range",
  "Downside Range",
  "1 Yr Return",
  "Risk/Reward",
];

const StyledTableContainer = styled(TableContainer)({
  fontFamily: "Lato",
  border: "1px solid #C4C4C4",
  fontSize: "1.125rem",
  borderRadius: "10px",
});

const HeadingRow = styled(TableRow)({
  borderBottom: 0,
  backgroundColor: "#F5F7FF",
});

const Cell = styled(TableCell)({
  fontFamily: "Lato",
  textAlign: "center",
  fontSize: "1rem",
  fontWeight: "500",
  borderBottom: 0,
  borderRight: "1px solid #C4C4C4",
});

const TableDataRow = styled(TableRow)({
  "&:nth-of-type(2)": {
    backgroundColor: "#FFFDCB",
  },
  "&:nth-of-type(4)": {
    backgroundColor: "#EDFFF6",
  },
});
function ScripWiseAnalyticsTable({ data }) {
  const theme = useTheme();
  return (
    <>
      <StyledTableContainer component={Paper} variant="outlined">
        <Table aria-label="historical returns table" size="medium">
          <TableHead>
            <HeadingRow>
              {tableHeadings.map((heading, index) => {
                return (
                  <Cell
                    sx={{
                      borderBottom: "1px solid #C4C4C4",
                      fontWeight: "600",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "15px !important",
                      },
                    }}
                    key={index}
                  >
                    {heading}
                  </Cell>
                );
              })}
            </HeadingRow>
          </TableHead>
          <TableBody>
            {data &&
              data.data.map((row) => {
                return (
                  <TableDataRow key={row.id}>
                    <Cell
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.security}
                    </Cell>
                    <Cell
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.holdings.toFixed(2) + "%"}
                    </Cell>
                    <Cell
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.risk_score}
                    </Cell>
                    <Cell
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.upside_range.toFixed(2) + "%"}
                    </Cell>
                    <Cell
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.downside_range.toFixed(2) + "%"}
                    </Cell>
                    <Cell
                      sx={{
                        color: "#31B182",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.one_yr_return}{" "}
                    </Cell>
                    <Cell
                      sx={{
                        color: "#31B182",
                        [theme.breakpoints.down("md")]: {
                          fontSize: "13px !important",
                        },
                      }}
                    >
                      {row.risk_reward.toFixed(2)}{" "}
                    </Cell>
                  </TableDataRow>
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}

export default ScripWiseAnalyticsTable;
