import React, { memo, useEffect, useState } from "react";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./styles.css";
import withStyles from "@mui/styles/withStyles";
import Typography from "@mui/material/Typography";
import {
  Grid,
  TablePagination,
  Box,
  Button,
  IconButton,
  MenuItem,
  Menu
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import unChecked from "../unChecked.png";
import axios from "axios";
import SecurityDetails from "../../modals/SecurityDetails";
import { securityModalAction } from "../../../../Store/actions/securityDetails";
import { useDispatch, useSelector } from "react-redux";
import DescriptionModal from "../../modals/DescriptionModal";
import MoreIcon from "@mui/icons-material/MoreVert";
import {deleteInvestmentDetails} from "../../../../Services/BackendAPI";
import { useSnackbar } from "notistack";
import EditInvestmentDtlsModal from "../../modals/EditInvestmentDtlsModal";
import MobileCards from "../mobileCards";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    scrollBehavior: "smooth",
    overflow: "auto",
    overflowX: "hidden",
    height: "100%",
    paddingRight: "25px",
    width: "calc(100% - 25px)",
    "& .MuiTable-stickyHeader": {
      overflow: "auto",
      borderRadius: "8px",
    },
    "& thead": {
      zIndex: "3",
      position: "sticky",
      top: "0",
      borderRadius: "5px",
      backgroundColor: "#fff",
    },
    "& th": {},
    "& .MuiTableCell-stickyHeader": {
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "unset",
      backgroundColor: "transparent",
    },
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    /* "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },*/
  },
}))(TableRow);

function CustomTable({
  rowSize,
  arrHeaders,
  arrRows,
  pageNumber,
  noResponseComponent,
}) {
  // row -> { a: 1, b: 2 }
  const classes = useStyles({
    tableHead: {
      color: "#9FA2B4",
      display: "inline-block",
    },
  });
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("time");
  const [selectedRow, setSelectedRow] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [openSecurityDetails, setOpenSecurityDetails] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [openDescription, setOpenDescription] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const openSecurityDetailsModal = useSelector(
    (state) => state.securityDetails.openSecurityDetailsModal
  );

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (size) => {
    setRowsPerPage(+size);
    setPage(0);
  };

  const selectAllHandler = () => {
    if (!allSelected) {
      setSelectedRow(arrRows);
      setAllSelected(true);
    } else {
      setSelectedRow([]);
      setAllSelected(false);
    }
  };

  const checkboxHandler = (row) => {
    // console.log(row);
    const present = selectedRow.some((data) => {
      if (data === row) {
        return true;
      } else {
        return false;
      }
    });
    if (present) {
      let filteredRow = selectedRow.filter((data) => data !== row);
      setSelectedRow(filteredRow);
    } else {
      setSelectedRow([row, ...selectedRow]);
    }
    // console.log(selectedRow);
  };

  const selectedChecker = (row) => {
    const present = selectedRow.some((data) => {
      if (data === row) {
        return true;
      } else {
        return false;
      }
    });
    return present;
    // console.log(present);
  };

  useEffect(() => {
    handleChangeRowsPerPage(rowSize);
  }, [rowSize]);

  const TableHeadM = styled("div")(({ theme }) => ({
    color: "#9FA2B4",
    display: "inline-block",
  }));

  const handleCloseSecurityDetails = () => {
    setOpenSecurityDetails(false);
  };
  const handleOpenSecurityDetails = (row, i) => {
    setCurrentRow(row);
    setOpenSecurityDetails(true);
  };

  const handleOpenDescription = (row) => {
    setCurrentRow(row);
    setOpenDescription(true);
  };
  const handleCloseDescription = () => {
    setOpenDescription(false);
  };
    const [anchorEl, setAnchorEl] = React.useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [morePopOver, setMorePopPover] = React.useState(null);
    const [morepopoverRow, setMorepopoverRow] = useState('');
    const isMorePopOverOpen = Boolean(morePopOver);

      const handleEditModalOpen = (event,row) => {
        setAnchorEl(event.currentTarget);
         setEditModalOpen(true)
         console.log(morepopoverRow);
      };
        const handleEditModalClose = () => {
          setEditModalOpen(false);
        };

      const handleDelete = (event, row) => {
        setAnchorEl(event.currentTarget);
        console.log(morepopoverRow);
        deleteInvestmentDetails(morepopoverRow.name)
          .then((success, message) => {
            if(success === true){
              enqueueSnackbar(message, { variant: "success" });
            }else{
              enqueueSnackbar(message, { variant: "error" });
            }
          })
          .catch((err) => {
              enqueueSnackbar(err, { variant: "error" });
          });
      };

      const handleMenuClose = () => {
        setMorePopPover(null);
      };
        const handleMorePopOverOpen = (event,row) => {
          setMorePopPover(event.currentTarget);
          setMorepopoverRow(row);
          console.log(row)
        };
  


  const morePopOverMenuId = "primary-search-account-menu-mobile";
  const renderMorePopOverMenu = (
    <>
      <Menu
        anchorEl={morePopOver}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={morePopOverMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMorePopOverOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={(event) => handleEditModalOpen(event)}>
          <p>Edit</p>
        </MenuItem>
        <MenuItem onClick={(event) => handleDelete(event)}>
          <p>Delete</p>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      {!matches && (
        <TableContainer
          sx={{
            maxHeight: "70vh",
            "::-webkit-scrollbar": {
              width: "0px",
            },
          }}
          className={classes.container}
        >
          <Table stickyHeader aria-label="sticky table" sx={{}}>
            <TableHead>
              {arrRows.length > 0 && (
                <TableRow>
                  {arrHeaders.map(({ label, keyName, muiStyling = {} }, i) =>
                    keyName === "portfolio_name" ? (
                      <TableCell>
                        <Checkbox
                          icon={<img src={unChecked} />}
                          // checkedIcon={<img src={checked} />}
                          onChange={(e) => selectAllHandler(e)}
                        />
                        <TableHeadM>
                          <Typography fontSize="14px" fontWeight={700}>
                            {label}
                          </Typography>
                        </TableHeadM>
                      </TableCell>
                    ) : arrRows.length === 0 ? (
                      " "
                    ) : (
                      <TableCell>
                        <TableHeadM>
                          <Typography fontSize="14px" fontWeight={700}>
                            {label}
                          </Typography>
                        </TableHeadM>
                      </TableCell>
                    )
                  )}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {arrRows.map((row, i) => (
                <StyledTableRow key={i} hover={true}>
                  <>
                    {arrHeaders.map(
                      ({ keyName, formatter, muiStyling = {}, label }, j) => {
                        {
                          return (
                            <>
                              <TableCell
                                align={muiStyling.align}
                                sx={{ width: `${muiStyling.width}` }}
                              >
                                {keyName === "portfolio_name" ? (
                                  <>
                                    {/* {console.log(selectedChecker(row))} */}
                                    <Grid container alignItems="center">
                                      <Grid item>
                                        <Checkbox
                                          checked={selectedRow.some(
                                            (data) => data === row
                                          )}
                                          icon={<img src={unChecked} />}
                                          // checkedIcon={<img src={checked} />}
                                          onChange={() => checkboxHandler(row)}
                                        />
                                      </Grid>
                                      <Grid item>{formatter(row)}</Grid>
                                    </Grid>
                                  </>
                                ) : keyName === "security_details" ? (
                                  <>
                                    <Button
                                      onClick={() => {
                                        handleOpenSecurityDetails(row, i);
                                        // dispatch(securityModalAction(true, row));
                                      }}
                                      size="small"
                                      variant="text"
                                      sx={{
                                        textTransform: "none",
                                        fontSize: "12px",
                                        color: "#194BFB",
                                        padding: 0,
                                        paddingLeft: "4px",
                                        borderBottom: "1px solid #194BFB",
                                        borderRadius: "0px",
                                        //  backgroundColor: "#fff",
                                        "&:hover": {
                                          boxShadow: "none",
                                          backgroundColor: "f5f5f5",
                                        },
                                      }}
                                      disableElevation
                                    >
                                      View More
                                    </Button>
                                  </>
                                ) : label === "Description" ? (
                                  <>
                                    <Button
                                      onClick={() => handleOpenDescription(row)}
                                      size="small"
                                      variant="text"
                                      sx={{
                                        textTransform: "none",
                                        fontSize: "12px",
                                        color: "#194BFB",
                                        padding: 0,
                                        paddingLeft: "4px",
                                        borderBottom: "1px solid #194BFB",
                                        borderRadius: "0px",
                                        //  backgroundColor: "#fff",
                                        "&:hover": {
                                          boxShadow: "none",
                                          backgroundColor: "f5f5f5",
                                        },
                                      }}
                                      disableElevation
                                    >
                                      Description
                                    </Button>
                                  </>
                                ) : keyName === "morepopover" ? (
                                  <IconButton
                                    size="small"
                                    aria-label="show more"
                                    aria-controls={morePopOverMenuId}
                                    aria-haspopup="true"
                                    onClick={(event) =>
                                      handleMorePopOverOpen(event, row)
                                    }
                                  >
                                    <MoreIcon />
                                  </IconButton>
                                ) : (
                                  formatter(row)
                                )}
                              </TableCell>
                            </>
                          );
                        }
                      }
                    )}
                    {openSecurityDetails && (
                      <SecurityDetails
                        open={openSecurityDetails}
                        handleOpen={handleOpenSecurityDetails}
                        handleClose={handleCloseSecurityDetails}
                        row={currentRow}
                      />
                    )}
                    {openDescription && (
                      <DescriptionModal
                        open={openDescription}
                        handleOpen={handleOpenDescription}
                        handleClose={handleCloseDescription}
                        row={currentRow}
                      />
                    )}
                    {editModalOpen && (
                      <EditInvestmentDtlsModal
                        open={editModalOpen}
                        handleOpen={handleEditModalOpen}
                        handleClose={handleEditModalClose}
                        row={morepopoverRow}
                      />
                    )}
                  </>
                </StyledTableRow>
              ))}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={arrRows.length}
              rowsPerPage={rowSize}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              sx={{ display: "none" }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
          {arrRows.length === 0 &&
            typeof noResponseComponent === "function" &&
            noResponseComponent()}
        </TableContainer>
      )}
      {matches && (
        <MobileCards
          rows={arrRows}
          headers={arrHeaders}
          handleOpenSecurityDetails={handleOpenSecurityDetails}
          morePopOverMenuId={morePopOverMenuId}
          handleMorePopOverOpen={handleMorePopOverOpen}
          handleOpenDescription={handleOpenDescription}
          editModalOpen={editModalOpen}
          handleOpen={handleEditModalOpen}
          handleClose={handleEditModalClose}
          row={morepopoverRow}
          openDescription={openDescription}
          handleCloseDescription={handleCloseDescription}
          currentRow={currentRow}
          openSecurityDetails={openSecurityDetails}
          handleCloseSecurityDetails={handleCloseSecurityDetails}
        />
      )}
      {renderMorePopOverMenu}
    </>
  );
}

export default memo(CustomTable);
