import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  Input,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Paper
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import CustomTypography from "../../../common/CustomTypography";
import CustomOutlinedInput from "../../../common/CustomOutlinedInput";
import StackTwoComponents from "../StackTwoComponents";
import { styled } from "@mui/system";
import { Close, Filter } from "@mui/icons-material";
import CustomSelectComponent from "../CustomSelectComponent";
import StatusButtonTabs from "../../InvestmentOptionMapperSection/MapNewInvestment/StatusButtonTabs/index";
import { useTheme } from "@mui/material/styles";
import {
  investmentDetails,
  updateInvestmentDetails,
} from "../../../../../Services/BackendAPI";
import MapNewInvestment from "../../InvestmentOptionMapperSection/MapNewInvestment";
import OrimLogo from "../../../../../assets/ORIMFinal.png"
import { useSnackbar } from "notistack";

const volatilityArr = ["Low", "Medium", "High"];

const statusArr = ["Active", "Pending", "Inactive"];

export function createData(name, value) {
  return { name, value };
}

export const rows = [
  createData("CAGR", 11),
  createData("Minimum Investment", 4558755),
  createData("Return Percentage", 1.2),
  createData("Hyperlink", "_XX_"),
  createData("Volatility", "LOW"),
  createData("Description", "This is Future Investment."),
];

export const CustomButton = styled(Button)((props) => {
  return {
    fontSize: "1rem",
    display: "block",
    fontWeight: "600",
    color: "#FFF",
    backgroundColor: "#194BFB",
    textTransform: "capitalize",
    // padding:'0 1rem 0 1rem',
    // width: '25%',
    [props.theme.breakpoints.down("sm")]: {
      // fontSize: "0.75rem",
      width: "100%",
    },
    "&:hover": {
      backgroundColor: "#194BFB",
    },
  };
});

export const StyledCustomButton = styled(CustomButton)((props) => {
  return {
    fontSize: "1rem",
    fontWeight: "600",
    backgroundColor: "#194BFB",
    textTransform: "capitalize",
    // padding: '0.7rem 1rem',
    // width: '20%',
    [props.theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    "&:hover": {
      backgroundColor: "#194BFB",
    },
  };
});

function AddNewInvestment({row=[],modalOpen=false}) {
  const { enqueueSnackbar } = useSnackbar();
  const inputFile = useRef(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedStatus, setSelectedStatus] = useState(0);
  const medium = useMediaQuery(theme.breakpoints.down("lg"));
  const [investmentDetailsObj, setInvestmentDetailsObj] = useState({
    name: row ? row.name: "",
    cagr:row ? row.cagr: "",
    minimum_investment: row ? row.minimum_investment: "",
    volatility: "",
    hyperlink: row ? row.hyperlink:"",
    return_percentage:row ? row.return_percentage: "",
    description:row ? row.description: "",
    status:"",
    risk_factor:"",
    investment_option:"",
    image: row ? row.image :"",
  });

  const handleStatusChange = (index) => {
    setSelectedStatus(index);
  };
  const handleFileUpload = (e) => {
    inputFile.current.click();
  };

  const onChange = (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    // console.log(URL.createObjectURL(e.target.files[0]).split("blob:http://localhost:3000/")[1]);
    const images = URL.createObjectURL(e.target.files[0]);
    // // const image = e.target.files[0];
    // const img = URL.createObjectURL(e.target.files[0].split("blob:")[1]);
    console.log(formData);
    setUploadedImage(e.target.files[0].name);
    setInvestmentDetailsObj({
      ...investmentDetailsObj,
      image: e.target.files[0],
    });
    setImagePreview(images);
  };

  // const ImageFunc =(image) =>{
  //   let decoded = window.atob(image);
  //   const blob = new Blob([decoded], { type: "image/svg+xml" });
  //   const images = URL.createObjectURL(blob);
  //   setImagePreview(images);
  // }

  const investmentInputHandler = (e) => {
    // console.log(e)
    const { name, value } = e.target;
    // console.log(name,value)
    switch (name) {
      case "name":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          name: value,
        });
        break;
      case "cagr":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          cagr: value,
        });
        break;
      case "minInvestment":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          minimum_investment: value,
        });
        break;
      case "hyperlink":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          hyperlink: value,
        });
        break;
      case "returnPercentage":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          return_percentage: value,
        });
        break;
      case "description":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          description: value,
        });
        break;
      case "volatility":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          volatility: value,
        });
        break;
      case "status":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          status: value,
        });
        break;
      case "investmentOption":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          investment_option: value,
        });
        break;
      case "riskFactor":
        setInvestmentDetailsObj({
          ...investmentDetailsObj,
          risk_factor: value,
        });
        break;
    }
  };

  const submitInvestmentDetails = () => {
    // let payload = { investmentDetailsObj };
    const formData = new FormData();
    formData.append("name", investmentDetailsObj.name);
    formData.append("cagr", investmentDetailsObj.cagr);
    formData.append("minimum_investment", investmentDetailsObj.minimum_investment);
    formData.append("volatility", investmentDetailsObj.volatility);
    formData.append("hyperlink", investmentDetailsObj.hyperlink);
    formData.append("return_percentage", investmentDetailsObj.return_percentage);
    formData.append("description", investmentDetailsObj.description);
    formData.append("status", investmentDetailsObj.status);
    formData.append("risk_factor", investmentDetailsObj.risk_factor);
    formData.append("investment_option", investmentDetailsObj.investment_option);
    formData.append("image", investmentDetailsObj.image);
    console.log(formData);
    investmentDetails(
      formData
      //   {
      //   name: investmentDetailsObj.name,
      //   cagr: investmentDetailsObj.cagr,
      //   minimum_investment: investmentDetailsObj.minimum_investment,
      //   volatility: investmentDetailsObj.volatility,
      //   hyperlink: investmentDetailsObj.hyperlink,
      //   return_percentage: investmentDetailsObj.return_percentage,
      //   description: investmentDetailsObj.description,
      //   status: investmentDetailsObj.status,
      //   risk_factor: investmentDetailsObj.risk_factor,
      //   investment_option: investmentDetailsObj.investment_option,
      //   image: investmentDetailsObj.image,
      // }
    )
      .then(({data,success, message}) => {
        if (success === true) {
          enqueueSnackbar(message, { variant: "success" });
        } else {
          enqueueSnackbar(message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
      });
  };

    const EditInvestmentOptions = () => {
      const formData = new FormData();
      formData.append("name", investmentDetailsObj.name);
      formData.append("cagr", investmentDetailsObj.cagr);
      formData.append(
        "minimum_investment",
        investmentDetailsObj.minimum_investment
      );
      formData.append("volatility", investmentDetailsObj.volatility);
      formData.append("hyperlink", investmentDetailsObj.hyperlink);
      formData.append(
        "return_percentage",
        investmentDetailsObj.return_percentage
      );
      formData.append("description", investmentDetailsObj.description);
      formData.append("status", investmentDetailsObj.status);
      formData.append("risk_factor", investmentDetailsObj.risk_factor);
      formData.append(
        "investment_option",
        investmentDetailsObj.investment_option
      );
      formData.append("image", investmentDetailsObj.image);
      console.log(formData);
      updateInvestmentDetails(formData)
        .then(({data,message,success}) => {
          if(success === true){
            enqueueSnackbar(message, { variant: "success" });
          }else{
            enqueueSnackbar(message, { variant: "error" });
          }
        })
        .catch((err) => {enqueueSnackbar(err, { variant: "error" })});
    };
  return (
    <Paper
      sx={{
        padding: "2rem",
        boxShadow: "none",
        [theme.breakpoints.down("sm")]: {
          marginTop: "10px",
          padding: "8px",
        },
      }}
    >
      <form>
        <div style={{ marginBottom: "1.25rem" }}>
          <Typography variant={"h4"}>
            {!modalOpen
              ? "  Add New Investment details"
              : "Edit Investment Details"}
          </Typography>
        </div>
        <Stack
          sx={{
            px: {
              xs: 2,
              md: 10,
            },
          }}
          // sx={{ padding: "6rem"}}
          spacing={1.2}
        >
          <CustomTypography variant={"h6"} text={"Name"} />
          <CustomOutlinedInput
            name="name"
            value={investmentDetailsObj.name}
            onChange={investmentInputHandler}
            placeholder={"Enter Name"}
          />
          <CustomTypography variant={"h6"} text={"CAGR"} />
          <CustomOutlinedInput
            onChange={investmentInputHandler}
            name="cagr"
            value={investmentDetailsObj.cagr}
            placeholder="Enter CAGR Values"
            type={"number"}
          />

          <StackTwoComponents
            containerOne={[
              <CustomTypography variant={"h6"} text={"Minimum Investment"} />,
              <CustomOutlinedInput
                type={"number"}
                onChange={investmentInputHandler}
                name="minInvestment"
                value={investmentDetailsObj.minimum_investment}
                placeholder="Enter Minimum Investment Amount"
              />,
            ]}
            containerTwo={[
              <CustomTypography variant={"h6"} text={"Volatility"} />,
              <CustomSelectComponent
                onChange={investmentInputHandler}
                name="volatility"
                value={investmentDetailsObj.volatility}
                arr={volatilityArr}
                placeholder={"Select Volatility"}
              />,
            ]}
          />

          <StackTwoComponents
            containerOne={[
              <CustomTypography variant={"h6"} text={"Hyperlink"} />,
              <CustomOutlinedInput
                onChange={investmentInputHandler}
                name="hyperlink"
                value={investmentDetailsObj.hyperlink}
                placeholder="Enter Hyperlink"
              />,
            ]}
            containerTwo={[
              <CustomTypography variant={"h6"} text={"Return Percentage"} />,
              <CustomOutlinedInput
                onChange={investmentInputHandler}
                name="returnPercentage"
                value={investmentDetailsObj.return_percentage}
                placeholder="Enter Return Percentage"
              />,
            ]}
          />
          <StackTwoComponents
            containerOne={[
              <CustomTypography variant={"h6"} text={"Description"} />,
              <CustomOutlinedInput
                onChange={investmentInputHandler}
                name="description"
                value={investmentDetailsObj.description}
                placeholder="Enter Description"
              />,
            ]}
            containerTwo={[
              <CustomTypography variant={"h6"} text={"Status"} />,
              <CustomSelectComponent
                onChange={investmentInputHandler}
                value={investmentDetailsObj.status}
                name="status"
                arr={statusArr}
                placeholder={"Select Status"}
              />,
            ]}
          />
          <br />
          <Grid
            container
            alignItems="center"
            justifyContent={"center"}
            sx={{
              height: "100px",
              border: "2px dashed #CDCDCD",
            }}
          >
            <div style={{ cursor: "pointer" }}>
              <input
                type="file"
                //   id="file"
                ref={inputFile}
                onChange={onChange}
                style={{ display: "none" }}
                accept=".jpg, .jpeg, .png"
              />
              <Stack direction="row" alignItems={"center"} spacing={1}>
                {imagePreview ||
                  (row && row.image && (
                    <img
                      height={small ? "25px" : "50px"}
                      width="auto"
                      src={!modalOpen ? imagePreview : row.image}
                    />
                  ))}
                <Typography
                  onClick={handleFileUpload}
                  component="span"
                  color="#194BFB"
                >
                  {uploadedImage
                    ? uploadedImage.length > 15
                      ? small
                        ? uploadedImage.slice(0, 15)
                        : uploadedImage.slice(0, 25) + "..."
                      : uploadedImage
                    : "Upload Image"}{" "}
                </Typography>
                {uploadedImage && (
                  <IconButton
                    onClick={() => setUploadedImage(null)}
                    color="primary"
                  >
                    <Close />
                  </IconButton>
                )}{" "}
              </Stack>
            </div>
            {/* </input> */}{" "}
          </Grid>
          <Box display="flex" justifyContent="center" py={3}>
            {/* <CustomButton disableRipple sx={{px:4}} variant='contained'>Create Investment Option</CustomButton> */}
          </Box>
        </Stack>
        <div
          style={{
            marginBottom: "1.25rem",
            marginLeft: "5rem",
          }}
        >
          <CustomTypography
            variant={"h5"}
            text={"Map New Investment"}
          />
        </div>
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          pl={small ? 2 : 0}
          sx={{
            px: {
              xs: 2,
              md: 10,
            },
          }}
        >
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <CustomTypography variant={"h6"} text={"Investment Option"} />
              <CustomSelectComponent
                onChange={investmentInputHandler}
                name="investmentOption"
                value={investmentDetailsObj.investment_option}
                // width={"100%" : "40%"}
                placeholder={"Low"}
                arr={["Low", "Moderate", "High"]}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <CustomTypography variant={"h6"} text={"Risk Factor"} />
              <CustomSelectComponent
                onChange={investmentInputHandler}
                name="riskFactor"
                value={investmentDetailsObj.risk_factor}
                // width={medium ? "100%" : "60%"}
                placeholder={"Moderate"}
                arr={["Low", "Moderate", "High"]}
              />
            </Stack>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" py={4}>
          <CustomButton
            onClick={
              !modalOpen ? submitInvestmentDetails : EditInvestmentOptions
            }
            disableRipple
            sx={{ px: 4 }}
            variant="contained"
            disableElevation
          >
            {!modalOpen ? "Create Investment Option" : "Edit Investment Option"}
          </CustomButton>
        </Box>
      </form>
      {!modalOpen && (
        <MapNewInvestment investmentDetailsObj={investmentDetailsObj} />
      )}
    </Paper>
  );
}

export default AddNewInvestment;
