import React, {useEffect, useRef} from 'react';
import { Grid, Typography, Box, Button, Paper, IconButton, InputBase, TextField, TableContainer, Table,TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useTheme } from '@mui/system';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import TextFieldComponent from '../../SignUp/CommonComponent/TextFieldComponent';
import { addCorporateAction, fetchCorporateAction, getSearchFunds, getSearchScript } from '../../../Services/BackendAPI';
import { useSnackbar } from "notistack";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import CustomImageTextButton from "../common/CustomImageTextButton";
import excelUpload from "../../../assets/AssetsForAdmin/excelUpload.png";

const CorporateAction = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [exchange, setExchange] = React.useState('NSE');
  const [executionDate, setExecutionDate] = React.useState(dayjs(new Date()));
  const [adjustmentFactor, setAdjustmentFactor] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  const [closeDropdown, setCloseDropdown] = React.useState(false);
  const [actionList, setActionList] = React.useState([]);
  const excelRef = useRef(null);
  const excelExport = () => {
    if (excelRef.current !== null) {
      excelRef.current.save();
    }
  };
  const listHeaders = ['Symbol', 'Execution Date', 'Adjustment Factor', 'Created At'];

  const formatDate = (passedDate) => {
    return dayjs(passedDate).format("DD-MM-YYYY");
  }

  useEffect(() => {
    fetchCorporateAction()
      .then(({data}) => setActionList(data))
      .catch(err => console.log("Error:", err))
  }, [])

  const handleAdjustmentFactor = (event) => {
    setAdjustmentFactor(event.target.value);
  }
 
  const handleSearch = (event) => {
    event.preventDefault();
    setCloseDropdown(false);
    setSearchQuery(event.target.value.toUpperCase());
  }

  const handleRenderData = (event, instrument) => {
    setExchange(instrument.exchange);
    setSearchQuery(instrument.symbol);
    setCloseDropdown(true);
    setSearchResults([]);
  }

  const callPostAPIFunction = async() => {
    const res = await addCorporateAction({
      "symbol": searchQuery,
      "execution_date": (executionDate)?.format("DD-MM-YYYY"),
      "adjustment_factor": Number.parseFloat(adjustmentFactor),
      "description": "",
      "login_id": localStorage.getItem('username')
    })
    if(!res?.error?.code){
      fetchCorporateAction()
      .then(({data}) => setActionList(data))
      .catch(err => console.log("Error", err))
    }
  }

  useEffect(() => {
    if(searchQuery?.length>=2 && !closeDropdown){
      getSearchScript({
        symbol: searchQuery,
        category: "EQUITY"
      })
        .then(({ data }) => {
          setSearchResults(data);
          if(data?.length === 0) {
            enqueueSnackbar("Invalid search", { variant: "error" });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: "error" });
        });
    }
    else{
      setSearchResults([]);
      setCloseDropdown(true);
    }
  }, [searchQuery, closeDropdown])

  function createData(
    name,
    calories,
    fat,
    carbs,
    protein
  ) {
    return { name, calories, fat, carbs, protein };
  }

  return (
    <>
    <Box
      sx={{
        backgroundColor: "white",
        marginTop: "20px",
        margin: "2rem 4rem 2rem 4rem",
        borderRadius: "20px",
        boxShadow: "none",
        padding: "1.5rem",
        [theme.breakpoints.down("md")]: {
          margin: "1.8rem",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid item xs>
          <Typography variant="h4">
            Corporate Action
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
            sx={{ paddingLeft: '24px', paddingTop: '24px' }}
          >
            {/* <Grid item xs justifyContent="end" display="flex">}
              <TextFieldComponent
                onChange={handleChange}
                name={"Exchange"}
                label={"Exchange"}
                disabled={true}
                value={exchange}
              />
            </Grid> */}
            <Grid item xs justifyContent="center" display="flex" flexDirection='column'>
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center'}}
              >
                <InputBase
                  sx={{ margin: '12px', flex: 1, minWidth: 120 }}
                  placeholder="Search Instrument"
                  inputProps={{ 'aria-label': 'search instrument' }}
                  onChange={handleSearch}
                  value={searchQuery}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              {searchResults?.length && !closeDropdown?<Grid display="flex" flexDirection="column" pt={2} mt={28} sx={{ position: 'absolute', height: '160px', overflowY: "scroll", overflowX: 'hidden', width: "200px", backgroundColor: 'white', border: '1px solid black' }}>
                {
                  searchResults?.map((instrument, idx) => 
                  <Grid key={idx} display="flex" flexDirection="column" p={1} sx={{
                    cursor: 'pointer', 
                    width: "200px",
                    '&: hover': {
                      backgroundColor: '#F6F8FA'
                    }
                  }} onClick={(e)=>handleRenderData(e, instrument)}>
                    <Typography variant='subtitle1'>{instrument.symbol}</Typography>
                    <Typography variant='subtitle2' sx={{ color: 'rgba(0,27,51,0.4)'}}>{instrument.exchange}</Typography>
                  </Grid>)
                }
                </Grid>:""}
            </Grid>
            <Grid item xs justifyContent="center" display="flex">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Execution Date"
                  value={dayjs(executionDate)}
                  onChange={(newValue) => {
                    setExecutionDate(newValue);
                  }}
                  sx={{ minWidth: 120 }}
                  renderInput={(params) => <TextField {...params} required />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs justifyContent="center" display="flex">
              <TextFieldComponent
                onChange={handleAdjustmentFactor}
                name={"Adjustment Factor"}
                label={"Adjustment Factor"}
                value={adjustmentFactor}
                sx={{ minWidth: 100 }}
              />
            </Grid>
            <Grid item xs justifyContent="center" display="flex" onClick={callPostAPIFunction}>
              <Button
                variant="contained"
                disabled={!searchQuery?.length || !Boolean(Number.parseFloat(adjustmentFactor))}
                sx={{
                  backgroundColor: "#194BFB",
                  borderRadius: "8px",
                  textTransform: "none",
                  // padding: "0.5rem 3.5rem 0.5rem 3.5rem",
                  fontSize: "17px",
                  fontWeight: 700,
                  "&:hover": {
                    backgroundColor: "#194BFB",
                  },
                }}
                disableElevation
              >
                Activate
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>  
    </Box>
    {actionList?.length?
    <Box
      sx={{
        backgroundColor: "white",
        marginTop: "20px",
        margin: "2rem 4rem 2rem 4rem",
        borderRadius: "20px",
        boxShadow: "none",
        padding: "1.5rem",
        [theme.breakpoints.down("md")]: {
          margin: "1.8rem",
        },
      }}
    >
      <Grid xs={12} display="flex" justifyContent="flex-end" sx={{ paddingTop: '20px' }}>
        <CustomImageTextButton
          img={excelUpload}
          text={"Export to Excel"}
          handleOnClick={excelExport}
        />
        <ExcelExport data={actionList} fileName="Corporate_Action.xlsx" ref={excelRef}>
          <ExcelExportColumn
            field="symbol"
            title="Symbol"
          />
          <ExcelExportColumn
            field="execution_date"
            title="Execution Date"
          />
          <ExcelExportColumn
            field="adjustment_factor"
            title="Adjustment Factor"
          />
          <ExcelExportColumn
            field="created_at"
            title="Created At"
          />
        </ExcelExport>
      </Grid>
      <TableContainer /*component={Paper}*/>
        <Table sx={{ minWidth: 650, maxHeight: 200, overflowY: 'auto' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {
                listHeaders.map((instrument, idx) => 
                <TableCell key={idx} align="center">
                  <Typography variant="h6">
                    {instrument}
                  </Typography>    
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {actionList.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="center">
                  {row.symbol}
                </TableCell>
                <TableCell align="center">{row.execution_date}</TableCell>
                <TableCell align="center">{row.adjustment_factor}</TableCell>
                <TableCell align="center">{formatDate(row.created_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    :
    <></>}
    </>
  )
}

export default CorporateAction