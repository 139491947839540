import * as React from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};


function getStyles(row, selectedOption, theme) {
  return {
    fontWeight:
    selectedOption.indexOf(row) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

const CustomPlaceHolderText = styled('span')(({ theme }) => ({
    // position: "relative",
    // borderRadius: theme.shape.borderRadius,
    // backgroundColor: "#FAFCFE",
    color:'#9E9E9E',
    fontSize:'1rem',
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(3),
    //   width: "30%",
    // },
  }));
  

export default function CustomSelectComponent({onChange, name,placeholder,arr,width='100%',value}) {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value }
  //   } = event;
  //   setSelectedOption(
  //     event.target.value
  //     // On autofill we get a stringified value.
  //   );
  // };

  return (
    <div>
      <FormControl sx={{ width: width}}>
        <Select
          displayEmpty
          name={name}
          value={value}
          onChange={onChange}
          input={<OutlinedInput />}
          placeholder={placeholder}
          renderValue={(selected) => {
            if (!selected.length) {
              return <CustomPlaceHolderText>{placeholder}</CustomPlaceHolderText>;
            }
            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {arr && arr.map((row) => (
            <MenuItem
              key={row}
              value={row}
              style={getStyles(row, selectedOption, theme)}
            >
              {row}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
