import { Typography } from "@mui/material";
import { borderRadius } from "@mui/system";

// const statusStyleHandler = (color) => {
//   return {
//     backgroundColor : {color},
//       height : "24px",
//       width : "76px",
//       border : `2px solid ${color}`,
//       borderRadius : "100px",
// }
// }

const statusColorChecker = (status) => {
   if(status === "ACTIVE"){
    return {
        background : "#29CC97",
        height : "24px",
        width : "76px",
        borderRadius : "100px",
        color : "white",
        textAlign : "center",
  }  
   }
   else if(status === "INACTIVE"){
    return {
      backgroundColor : "#F12B2C",
        height : "24px",
        width : "76px",
        borderRadius : "100px",
        color : "white",
        textAlign : "center",
  }  
   }
   else{
    return {
      backgroundColor : "#FEC400",
        height : "24px",
        width : "76px",
        borderRadius : "100px",
        color : "white",
        textAlign : "center",
  }  
   }
}

export const Headers = [
       {
      label: "Portfolio Name",
      keyName: "portfolio_name",
      muiStyling: { align: "left",width : 20},
      formatter: ({ portfolio_name }) => (
        <Typography fontSize="14px" fontWeight={600}>
            {portfolio_name}
        </Typography>
      )
    },
    {
        label: "Portfolio Input Type",
        keyName: "portfolio_username",
        muiStyling: { align: "left",width : 20},
        formatter: ({ portfolio_username }) => {
        return ( <Typography fontSize="14px" fontWeight={600}>
              {portfolio_username}
          </Typography> )
        }
    },
    {
      label: "Input Option",
      keyName: "phone_number",
      muiStyling: { align: "left",width : 20},
      formatter: ({ phone_number }) => {
      return ( <Typography fontSize="14px" fontWeight={600}>
            {phone_number}
        </Typography> )
      }
  },
  {
    label: "Security Details",
    keyName: "security_details",
    muiStyling: { align: "left",width : 20},
    formatter: ({ phone_number }) => {
    return ( <Typography fontSize="14px" fontWeight={600}>
          {phone_number}
      </Typography> )
    }
}
    // {
    //   label: "Name",
    //   keyName: "name",
    //   muiStyling: { align: "left", width: "20%" },
    //   formatter: ({ name }) => (
    //     <Typography>
    //         {name}
    //     </Typography>
    //   )
    // },
    // {
    //   label: "Calories",
    //   keyName: "calories",
    //   muiStyling: { align: "right", width: "20%" },
    //   formatter: ({ name }) => (
    //     <Typography>
    //         {name}
    //     </Typography>
    //   )
    // },
    // {
    //   label: "Fat",
    //   keyName: "fat",
    //   muiStyling: { align: "right", width: "40%" },
    //   formatter: ({ fat }) => (
    //     <Typography>
    //         {fat}
    //     </Typography>
    //   )
    // },
    // {
    //   label: "Carbs",
    //   keyName: "carbs",
    //   muiStyling: { align: "right", width: "20%" },
    //   formatter: ({ carbs }) => (
    //     <Typography>
    //         {carbs}
    //     </Typography>
    //   )
    // },
    // {
    //   label: "Status",
    //   keyName: "status",
    //   muiStyling: { align: "left", width: "20%"},
    //   formatter: ({ status }) => (
    //     <div style={statusColorChecker(status)}>
    //     <Typography sx={{fontSize : "12px", fontWeight: "700", padding: "5px 11px"}}>
    //         {status}
    //     </Typography>
    //     </div>
    //   )
    // },
    // {
    //   label: "Security Details",
    //   keyName: "security_details",
    //   muiStyling: { align: "right", width: "20%" },
    // }
  ];