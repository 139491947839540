import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React from 'react'
import { Card, Typography, Grid, Stack } from '@mui/material';
import HCrounded from "highcharts-rounded-corners";
import {data} from '../Dashboard/apiData';

const color="linear-gradient(#e66465, #9198e5)" 
const psign="+";
const nsign="-";

HCrounded(Highcharts);
function ChartPractice({data}) {
  const gain = data !== undefined || data !== null ? data.gain_risk.gain : 0;
  const risk = data !== undefined || data !== null ? data.gain_risk.risk : 0;
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      animation: true,
      backgroundColor: "transparent",
      Color: "#FFF",
      gridLineWidth: "0",

      events: {
        redraw: function () {},
      },
    },
    title: {
      align: "left",
      text: "",
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    xAxis: {
      labels: {
        // style:{
        //   color:'#242424',
        //   fontSize:'0.75rem'
        // }
      },
      type: "category",
      lineWidth: 0,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      // style:{
      //   color:"#777",
      //   fontSize:"52px"
      // },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        maxPointWidth: 60,
        dataLabels: {
          inside: false,
        },
      },
      series: {
        borderRadiusTopLeft: 10,
        borderRadiusTopRight: 10,
        dataLabels: {
          enabled: true,
          overflow: "allow",
          crop: false,
          format:
            '<span style="color:{point.color};font-size:1rem">{point.y:.1f}%</span>',
          //  {point.color}

          // style: {
          //   fontSize:'1rem',
          //   color:'point.color',
          // }
        },
      },
    },

    tooltip: {
      pointFormat:
        '<span style="color:{point.color}">{point.name}</span>: <b>+{point.y:.2f}%</b><br/>',
    },

    series: [
      {
        name: "Result",
        colorByPoint: true,
        data: [
          {
            name: '<span style="font-size:0.75rem;color:#434343;font-weight:600">Gain</span>',
            border: "10px",
            y: parseFloat(gain.toFixed(2)),
            color: "#45B849",
          },
          {
            name: '<span style="font-size:0.75rem;color:#434343;font-weight:600">Risk</span>',
            y: parseFloat(risk.toFixed(2)),
            color: "#F23939",
          },
        ],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            // maxWidth: 300,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: -5,
              },
              title: {
                text: null,
              },
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
  };
  
  return (
    <>
        <Typography
          variant="h6"
          sx={{
            color: "#242424",
            fontFamily: "Lato",
            fontWeight: 600,
          }}
          align ='left'
        >
          Potential Gain & Risk
        </Typography>
      {data !== undefined || data !== null ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        "Nothing To Show!"
      )}
    </>

    // <Card variant='outlined' >

    // </Card>
  );
}

export default ChartPractice