import React from 'react'
import { StyledHeadingTypography } from '../PortfolioPerformance/TitleSection/custom'
import {styled} from '@mui/styles';
import { Grid, Stack, Typography } from '@mui/material';
import CircularProgressBar from '../CustomCircularProgressBar';
import RiskScoreCard from '../CustomCircularProgressBar/RiskScoreCard';

import ChartPractice from '../../Charts/ChartPractice';
import InvestmentValueCard from './InvestmentValueCard';
import RiskCardsContainer from '../../CommonComponents/RiskCardsContainer';
import AnalyserReport from '../RiskReport/RiskAnalyserReport';
import {useTheme} from '@mui/material/styles';

 const StyledRupeesText = styled(Typography)(({ theme }) => ({
 color:'#43B747',
 fontSize:'1.25rem',
}));

function AnalyserReportSection({data,fetchRiskScore,pdfFormat=false}) {
  const theme = useTheme();
  return (
    <Stack spacing={3}>
      {/* {!pdfFormat && (
        <StyledHeadingTypography
          sx={{
            [theme.breakpoints.down("md")]: {
              fontSize: "14px !important",
            },
          }}
        >
          Based upon your investment amount of
          <span
            style={{
              color: "#43B747",
              // textDecoration: "underline",
              // textDecorationColor: "#70C7FA",
              // textDecorationStyle:'solid'
              borderBottom: "3px solid #70C7FA",
            }}
          >
            {" "}
            ₹10,00,000{" "}
          </span>
          & the nature of{" "}
          <span style={{ color: "#209AE2", fontWeight: 800 }}>
            your answers
          </span>{" "}
          , over 1 year you are well suited with a risk profile that has the
          below risk score and fluctuates between the following range:
        </StyledHeadingTypography>
      )} */}

      {data !== undefined ? (
        <AnalyserReport
          data={data}
          fetchRiskScore={fetchRiskScore}
          pdfFormat={pdfFormat}
        />
      ) : (
        <div>"Nothing to report"</div>
      )}
      {/* </Box> */}
    </Stack>
  );
}

export default AnalyserReportSection