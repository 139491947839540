import { Typography } from '@mui/material';
import { styled} from '@mui/system';


export const StyledHeadingTypography = styled(Typography)({
  fontFamily: "Lato",
  fontSize: "1.25rem",
  color: "#242424",
  margin: "revert",
  fontWeight: 500,
});