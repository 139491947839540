import React, { useRef,useEffect,useState } from "react";
import { Button,Grid,Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import TableWrapper from "../Index";
import { Headers } from "./arrHeaders";
import CustomTypography from "../../common/CustomTypography";
import CustomImageTextButton from "../../common/CustomImageTextButton";
import AddNewInvestment, {
  CustomButton,
} from "../../body/investmentTabSection/AddNewInvestment";
import excelUpload from "../../../../assets/AssetsForAdmin/excelUpload.png";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import GenerateExcel from "./GenerateExcel";
import products from "./products.json";
import { createTheme } from "@mui/material/styles";

// const group = [
//   {
//     field: "Category.CategoryName",
//   },
// ];
// const data = process(products, {
//   group: group,
// }).data;

export default function InvestmentTable() {
    const theme = createTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const excelRef = useRef(null);
  const excelExport = () => {
    if (excelRef.current !== null) {
      excelRef.current.save();
    }
  };
  return (
    <>
      {!matches && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              // text="Investment Option"
              variant="h4"
              // fontWeight="800"
            >
              Investment Option
            </Typography>
          </Grid>
          <Grid item>
            <Grid container pb={2} justifyContent="flex-end" columnGap={3}>
              <GenerateExcel excelRef={excelRef} excelExport={excelExport} />
              <Link style={{ textDecoration: "none" }} to="/add-new-investment">
                <CustomButton
                  disableRipple
                  sx={{ px: 4, py: "0.5rem" }}
                  variant="contained"
                  disableElevation
                >
                  + Add New Investment{" "}
                </CustomButton>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
      <TableWrapper tableType="investment-details-list" headers={Headers} />
      {matches && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs>
            {" "}
            <GenerateExcel excelRef={excelRef} excelExport={excelExport} />
          </Grid>
          <Grid item xs>
            {" "}
            <Link style={{ textDecoration: "none" }} to="/add-new-investment">
              <CustomButton
                disableRipple
                sx={{ px: 4, py: "0.5rem" }}
                variant="contained"
                disableElevation
              >
                + Add New Investment{" "}
              </CustomButton>
            </Link>
          </Grid>
        </Grid>
      )}
    </>
  );
}
