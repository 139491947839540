import React from "react";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";

// import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress,  Grid,  Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import GradientCircularProgress from "./GradientCircularProgress";
import {data} from '../../Dashboard/apiData'
import CircularLibraryProgress from "./RiskScoreCard/CircularLibraryProgress";
import { buildStyles } from "react-circular-progressbar";
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "50%",
  },
  top: {
    color: "#04A971",
  },
  circle: {
    strokeLinecap: "round",
    // stroke: "url(#linearColors)"
    // color: 'linear-gradient( red, yellow)',
  },
}));

export default function CustomCircularProgressBar({fetchRiskScore}) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);
  const riskScore = fetchRiskScore !== undefined ? fetchRiskScore.scenario_manager.data[0].risk_score : 0;
  React.useEffect(() => {
    if (progress < riskScore) {
      setTimeout(() => {
        setProgress(progress + 10);
      }, 50);
    }
  }, [progress]);

  return (
    <Box sx={{boxShadow:4}} className={classes.root} p={1.75}>

    {/* <Box position="relative" display="inline-flex" className={classes.root} >
      <CircularProgress  id="linearColors" 
        variant="determinate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        style={{ color: '#00D8FF' }}
        size={125}
        thickness={4}
        value={progress}
        />
        <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        >
        <Typography variant="h5" component="div" py={1.5} px={2} sx={{backgroundColor:'#629AF6',borderRadius:'50%',color:'#FFF',fontSize:'1.875rem'}}>
          {riskScore}
        </Typography>
      </Box>
    </Box> */}
<div
        style={{
          width: "12.5rem",
          height: "12.5rem"
        }}
      >
        <CircularLibraryProgress  value={riskScore}
       
        strokeWidth={9}
        styles={buildStyles({
          pathColor: "rgba(0, 216, 255, 1)",
          trailColor: "rgba(235, 242, 249, 1)",
        })}
        >
          <div style={{backgroundColor:'rgba(98, 154, 246, 1)',borderRadius:'50%',padding:'1.25rem 1.625rem 1.25rem 1.625rem'}}>
            <Typography fontSize={'2.125rem'} fontWeight={700} color='#FFF'>{riskScore}</Typography>
        </div>
        </CircularLibraryProgress>
      </div>
        </Box>
  );
}