import React, { useRef, useState, useEffect, Suspense } from "react";
import Appbar from "../../Appbar";
import FooterDashboard from "../../UIForClient/CommonComponents/FooterDashboard";
import {
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Box,
  Toolbar,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import { Navigate, Routes, Route } from "react-router-dom";
import LandingPage from "../../UIForClient/Dashboard";
import RecommendedAssets from "../../UIForClient/RecommendedAssets";
import DashboardCardPlacement from "../../UIForClient/CommonComponents/CardPlacements";
import CorporateAction from "../../UIForAdmin/CorporateAction";
import SideBar from "../../SideBar";
import AddNewInvestment from "../../UIForAdmin/body/investmentTabSection/AddNewInvestment";
import UserTable from "../../UIForAdmin/Table/UserTable";
import InvestmentTable from "../../UIForAdmin/Table/InvestmentTable";
import SettingsSection from "../../UIForAdmin/body/SettingsSection";
import ManualDataUpload from "../../UIForAdmin/CSVUpload";
function DashboardPage(props) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const refPDF = useRef(null);
  const [showFooterInDash, setShowFooterInDash] = useState(false);
  const [enableAsset, setAsset] = useState(false);
  const drawerWidth = 400;
  const [currentDashboardView, setCurrentDashboardView] = useState("CLIENT_DASHBOARD");

  const handleExportPDF = () => {
    setAsset(true);
    setTimeout(() => {
      refPDF.current.save();
    }, 2000);

    setTimeout(() => {
      setAsset(false);
    }, 5000);
  };

    const routeToRender = {
      CLIENT_DASHBOARD: (
        <Routes>
          <Route
            path="/home"
            element={
              <DashboardCardPlacement
                enableAsset={enableAsset}
                handleExportPDF={handleExportPDF}
                refPDF={refPDF}
                showDownloadBtn={true}
              />
            }
          />
          <Route
            path="/recommendedassets"
            element={
              <RecommendedAssets openFullView={true} showDownloadBtn={false} />
            }
          />
          <Route
            path="/riskprofiling"
            element={<h1 showDownloadBtn={false}>RISK PROFILING</h1>}
          />
          <Route
            path="/riskanalyser"
            element={<LandingPage showDownloadBtn={false} />}
          />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      ),
      ADMIN_DASHBOARD: (
        <Routes>
          <Route path="/dashboard" element={<h1> Dashboard </h1>} />
          <Route path="/add-new-investment" element={<AddNewInvestment />} />
          <Route path="/client" element={<UserTable />} />
          <Route path="/investment" element={<InvestmentTable />} />
          <Route path="/settings" element={<SettingsSection />} />
          <Route
            path="/corporate-action"
            element={<CorporateAction />}
          />
          <Route
            path="/manual-data-upload"
            element={<ManualDataUpload />}
          />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      ),
      SUPERADMIN_DASHBOARD: <div>hello</div>,
    };


  useEffect(() => {
    if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "ADMIN"
    ) {
      setCurrentDashboardView("ADMIN_DASHBOARD");
    } else if (
      localStorage.hasOwnProperty("userType") &&
      localStorage.getItem("userType") === "SUPERADMIN"
    ) {
      setCurrentDashboardView("SUPERADMIN_DASHBOARD");
    } else {
      setCurrentDashboardView("CLIENT_DASHBOARD");
    }
  }, [currentDashboardView]);

  return (
    <>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: 2000,
        }}
        open={enableAsset}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Appbar
        enableAsset={enableAsset}
        handleExportPDF={handleExportPDF}
        refPDF={refPDF}
        drawerWidth={drawerWidth}
      />
      {!enableAsset && (
        <SideBar enableAsset={enableAsset} drawerWidth={drawerWidth} />
      )}
      {!small && <Toolbar />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: enableAsset === true ? "" : `${drawerWidth}px`,
          // marginTop: enableAsset === true ? "" : `3.75rem`,
          [theme.breakpoints.down("md")]: {
            marginLeft: 0,
          },
        }}
      >
        {!small && <Toolbar />}
        {routeToRender[currentDashboardView]}
        {/* <Routes>
          <Route
            path="/home"
            element={
              <DashboardCardPlacement
                enableAsset={enableAsset}
                handleExportPDF={handleExportPDF}
                refPDF={refPDF}
                showDownloadBtn={true}
              />
            }
          />
          <Route
            path="/recommendedassets"
            element={
              <RecommendedAssets openFullView={true} showDownloadBtn={false} />
            }
          />
          <Route
            path="/riskprofiling"
            element={<h1 showDownloadBtn={false}>RISK PROFILING</h1>}
          />
          <Route
            path="/riskanalyser"
            element={<LandingPage showDownloadBtn={false} />}
          />
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes> */}
        {!enableAsset && (
          <div style={{ marginTop: "4rem" }}>
            <FooterDashboard
              enableAsset={enableAsset}
              handleExportPDF={handleExportPDF}
              refPDF={refPDF}
              showDownloadBtn={false}
            />
          </div>
        )}
      </Box>
    </>
  );
}

export default DashboardPage;
