
const initialPortfolioDetails = {
  symbol: null,
  value: null,
  category: null,
};

const initialState ={
  riskScore:{
    portfolioAnalyser: {}
  },
  portfolioDetails: [initialPortfolioDetails],
  recommendedAssets : [],
  openSecurityDetailsModal: false,
  clientDetailsList:[]

}

export default function reducer(state = initialState, action) {

  switch (action.type) {
    case "GET_RISK_SCORE": {
      return {
        ...state,
        riskScore: { ...action.payload },
      };
    }

    case "ADD_NEW_PORTFOLIO": {
      return {
        ...state,
        portfolioDetails: [
          ...state.portfolioDetails,
          { ...initialPortfolioDetails },
        ],
      };
    }

    case "DELETE_SPECIFIC_PORTFOLIO": {
      let newState = [...state.portfolioDetails];
      newState.splice(action.payload, 1);

      return {
        ...state,
        portfolioDetails: [...newState],
      };
    }

    case "INPUT_SPECIFIC_PORTFOLIO": {
      let { index, key, value } = action.payload;

      let newState = [...state.portfolioDetails];

      return {
        ...state,
        portfolioDetails: newState,
      };
    }
    case "GET_RECOMMENDED_ASSETS":
      return {
        ...state,
        recommendedAssets: { ...action.payload },
      };
    case "OPEN_SECURITY_MODAL":
      return {
        ...state,
        openSecurityDetailsModal: { ...action.payload },
      };
    case "GET_CLIENT_DETAILS":
      return {
        ...state,
        clientDetailsList: { ...action.payload },
      };

    default:
      return state;
  }
  

}