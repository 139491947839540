import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material'
import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledHeadingTypography } from './custom';
import {useTheme} from '@mui/material/styles';


// const Div = styled("div")(({ primary }) => ({
//     backgroundColor: primary ? "palevioletred" : "white",
//     color: primary ? "white" : "palevioletred"
//   }));
  
 const StyledTypography=styled(Typography)(({theme})=>({
    fontFamily:'Lato',
    fontSize:"1.1rem",
    color:'#242424',
   
}) )
const StyledBox=styled(Box)(({color})=>({
    width: '1.5rem',
    height: '1.2rem',
    backgroundColor: color,
    borderRadius:'5px 5px 0px 0px',
}))

  function TitleSection({data}) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const handleDropdownClose = () => {
        setAnchorEl(null);
        setOpenDropdown(false);
    }
    const handleDropdownClick = (e) => {
        setAnchorEl(e.currentTarget);
        setOpenDropdown(true);
    }

    const menuItems = ['nifty 50', 'sensex', 'sensex'];
  return (
    <Stack direction='row' justifyContent='space-between'alignItems={'center'}>

                <StyledHeadingTypography 
                sx={{
                    [theme.breakpoints.down("md")]:{
                      fontSize:"14px !important",
                  }}}
                >Portfolio Performance vs Benchmark</StyledHeadingTypography>
                <Stack direction={
                        {
                            xs: 'column',
                            sm: 'row'
                        }
                    }
                    justifyContent='space-between'
                   spacing={{
                    xs:1,
                    sm:4
                   }} >
                    <Stack direction='row' alignItems={'center'} spacing={2}>
                        <StyledBox color='#4F45E5'/>
                        <StyledTypography 
                         sx={{
                            [theme.breakpoints.down("md")]:{
                              fontSize:"10px !important",
                          }}}
                        >
                            Historical Returns
                        </StyledTypography>
                    </Stack>

                    <Stack direction='row'
                        alignItems={'center'}
                        spacing={2}>
                            
                            <StyledBox color='#C7D2FE'/>

                        <Stack direction={'row'} alignItems={'center'}
                            onClick={handleDropdownClick}> 
                            <StyledTypography 
                             sx={{
                                [theme.breakpoints.down("md")]:{
                                  fontSize:"10px !important",
                              }}}
                            >
                             {data && data.benchmark_name &&  data.benchmark_name.display_name}
                            </StyledTypography>
                            <KeyboardArrowDown />
                        </Stack>
                        <Menu anchorEl={anchorEl}
                            open={openDropdown}
                            onClose={handleDropdownClose}>
                            <MenuItem onClick={handleDropdownClose}>Sensex</MenuItem>
                            <MenuItem onClick={handleDropdownClose}>Sensex</MenuItem>
                            <MenuItem onClick={handleDropdownClose}>Sensex</MenuItem>
                        </Menu>
                    </Stack>
                </Stack>
            </Stack>
  )
}

export default TitleSection