import { configureStore, combineReducers } from '@reduxjs/toolkit'
import {reduxBatch} from '@manaflair/redux-batch';
import logger from 'redux-logger';
import securityDetails from './reducers/securityDetails';

const rootReducer = combineReducers({
  securityDetails,
})

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__,
    preloadedState: {},
    enhancers: [reduxBatch]
  
})


export default store;
