import { Delete } from '@mui/icons-material'
import { Button, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import excelUpload from "../../../../assets/AssetsForAdmin/excelUpload.png";
import CustomTypography from '../CustomTypography'
export const StyledButton = styled(Button)((props) => {
  return {
      fontSize: "1rem",
      fontWeight: '700',
      color:'#FFF',
      backgroundColor:`#23A566`,
      textTransform: 'capitalize',
      padding:'0.5rem 1rem 0.5rem 1rem',
      // width: '25%',
    
      '&:hover': {
        backgroundColor:'#23A566',
      }
  }
})

function CustomImageTextButton({img,text,handleOnClick}) {
  return (
    <StyledButton variant="outlined" startIcon={<img src={img}/>} onClick={handleOnClick} >
    <CustomTypography variant={'body'} fontWeight={'700'} text={text}/>
  </StyledButton>
  )
}

export default CustomImageTextButton