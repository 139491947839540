import {Grid} from '@mui/material';
import React from 'react';
function FbGoogleGrid(props) {
    return (
        <Grid container
            rowSpacing={
                {
                    xs: 2,
                    lg: 0
                }
            }
            columnSpacing={
                {sm: 6}
        }>
            {
            props.children.map((element,index) => {
                return <Grid item key={index}
                    xs={12}
                    sm={6}>
                    {element} </Grid>
        })
        } </Grid>
    )
}

export default FbGoogleGrid
