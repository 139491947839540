import React, { useEffect, useState, useCallback } from "react";
// import { getRiskScoreAPI } from "../../Services/BackendAPI";
import { Typography, Grid, Divider } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./style.css";
import CustomTypography from "../common/CustomTypography";
// import { useSelector, useDispatch } from "react-redux";
// import PieChart from "./NoResponseComponent";
// import {fetchRiskScoreAction} from "../../Store/actions/securityDetails";
// import {saveIDCookie} from '../../Services/Utilities';

function RiskScore({
  portfolioAllocation
}) {

  const options = {
    chart: {
      type: "pie",
      responsive: {
        rules: [
          {
            condition: {
            //    maxWidth: 50
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
            },
          },
        ],
      },
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text:'',
    },

    accessibility: {
      announceNewData: {
        enabled: true,
      },
      point: {
        valueSuffix: "%",
      },
    },

    plotOptions: {
      pie: {
        color: ["#808CFC", "#FF7D44", "#FFD87E", "#53BAFF"],
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        size:180,
        showInLegend: true,
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },

    credits: { enabled: false },

    series: [
      {
        name: "Risk Score",
        colorByPoint: true,
        // data: data1.chartData,

        data: [
          {
            name: `<span  style="font-size:1.125rem;" >${portfolioAllocation.equity_per}% </span><br/ > <span  style="color: #A0AEC0;font-weight:500;font-size:0.8125rem" >Equity</span><br/>
            <div>&nbsp;</div>`,
            y: portfolioAllocation.equity_per,
          },
          {
            name: `<span  style="font-size:1.125rem;" >${portfolioAllocation.mutual_fund_per}% </span> <br/ > <span  style="color: #A0AEC0;font-weight:500;font-size:0.8125rem" >Mutual Funds</span>`,
            y: portfolioAllocation.mutual_fund_per,
          },
          {
            name: `<span  style="font-size:1.125rem" >${portfolioAllocation.real_estate_per}%  </span><br/ > <span  style="color: #A0AEC0;font-weight:500;font-size:0.8125rem" >Real estate</span>`,
            y: portfolioAllocation.real_estate_per,
          },
          {
            name: `<span  style="font-size:1.125rem" >${portfolioAllocation.commodity_per}%  </span><br/ > <span  style="color: #A0AEC0;font-weight:500;font-size:0.8125rem" >Commodity</span>`,
            y: portfolioAllocation.commodity_per,
          },
        ],
      },
    ],
  };
  Highcharts.setOptions({
    colors: ["#FF7D44", "#808CFC", "#FFD87E", "#53BAFF"],
    // name: ['Equity']
  });
  return (
    <>
    <CustomTypography variant="h6" text={'Pie Chart'}/>
      {portfolioAllocation !== undefined && portfolioAllocation !== null && portfolioAllocation !== ""? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
       ''
      )}
    </>
  );
}

export default RiskScore;
