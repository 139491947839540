import React, { useState,useCallback,useEffect,useRef } from "react";
import {
  Grid,
  TextField,
  Divider,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  InputBase,
  List,
  ListItem,
  Card,
  MenuItem,
  Button,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getSearchScript } from "../../../../Services/BackendAPI";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { withStyles, createStyles } from "@mui/styles";
import {useTheme} from '@mui/material/styles';
import DeleteIcon from "../../../../assets/Group 2607.png"
import AddIcon from "@mui/icons-material/Add";
import {useSelector, useDispatch} from 'react-redux';
import { useSnackbar } from "notistack";

const CustomMenuItem = withStyles((theme) =>
  createStyles({
    root: {
      fontSize: "12px",
      boxShadow: "none",
      " .css-6hp17o-MuiList-root-MuiMenu-list": {
        backgroundColor: "#F8FBFF",
      },
    },
  })
)(MenuItem);

export const data = [
  { name: "EQUITY" },
  { name: "COMMODITY" },
  { name: "MF" },
  { name: "RE" },
];

function RowComponents({ selectValueType, fetchedScriptValue, setFetchedScriptValue, compValues, setCompValues, idx, handleChange,symbolValue, setSymbolValue,addMoreRow,removeRows, rowDeleted, setRowDeleted }) {


  // console.log(compValues);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {portfolioDetails} = useSelector(state=> state.securityDetails)
  const small = useMediaQuery(theme.breakpoints.down('sm'));
  const [fetchScriptData, setScriptData] = useState([]);
  const [showSearchSelect, setSearchSelect] = useState(compValues.symbol);
  const [selectedAsset, setSelectedAsset] = useState('Select Asset Class');
  const [expanded, setExpanded] = React.useState(false);
  const [adornmentValue, setAdornmentValue] = useState("%");
  const [quantityValue, setQuantityValue] = useState(compValues[idx].value);


  useEffect(()=>{
    if(idx>0){
      setSelectedAsset(compValues[compValues.length-2].category);
      handleChange(compValues[compValues.length-2].category, idx, 'category')
    }
    else{
      // setSelectedAsset("EQUITY");
      // handleChange("EQUITY", 0, 'category');
      // searchFunct("ICICI PRUDENTIAL LIFE INSURANCE COMPANY LIMITED")
    }
   },[])

   useEffect(()=>{
    if(rowDeleted){
    setSelectedAsset(compValues[idx].category)
    setSearchSelect("");
    setQuantityValue(compValues[idx].value)
     let payload = {
      symbol: compValues[idx].symbol,
      category: compValues[idx].category,
    };
    getSearchScript(payload).then((data) => {
         setSearchSelect(data.data[0].name);
         searchFunct(data.data[0].name);
    })
     setRowDeleted(false);
    }
   },[rowDeleted])

   useEffect(()=>{
      handleSearchSelect(selectedAsset);
   },[addMoreRow])

   useEffect(()=>{
      setQuantityValue("");
      handleChange("", idx,  'value');
   },[selectValueType])

   const quantityHandler = (e) => {
    if(e > 0){
     setQuantityValue(e);
    }
    else if (e == 0){
      setQuantityValue(0);
    }
    // console.log(quantityValue)
   }

   const handleSearchSelect = (value) => {
    if(value === "EQUITY"){
      searchFunct("ICICI PRUDENTIAL LIFE INSURANCE COMPANY LIMITED")
    }
    else if (value === "COMMODITY"){
      searchFunct("GOLD")
    }
    else if (value === "MF"){
      searchFunct("HDFC ARBITRAGE FUND - RETAIL GROWTH OPTION")
    }
    else{
      searchFunct("RE")
    }
    if(value !== selectedAsset){
    setSearchSelect("");
    setQuantityValue("");
    handleChange("", idx,  'value');
    handleChange("", idx, 'symbol');
    }
   }

  const handleClose = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchFunct = (query) => {

    let payload = {
      symbol: query,
      category: compValues[idx].category,
    };
    getSearchScript(payload).then((data) => {
      setScriptData(data.data);
    });
  };

  const handleScriptSearch = (fetchValue,idx,symbol) => {
      setFetchedScriptValue(fetchValue);
      setSearchSelect(fetchValue);
      setSymbolValue(symbol);
  }

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 900);
    };
  };

  const optimizedFn = useCallback(debounce(searchFunct), []);


  const handleSymbolSearch = (data) => {
      setSearchSelect(data);
  }

  const adornmentHandler = () => {
    if(selectValueType === "PERCENT"){
      setAdornmentValue("%")
    }
    else if (selectValueType === "PRICES"){
      setAdornmentValue(("\u20B9"))
    }
    else {
      setAdornmentValue("");
    }
  }

  useEffect(()=>{
     adornmentHandler();
  },[selectValueType])

  const start={
    startAdornment : <InputAdornment position="start">{adornmentValue}</InputAdornment>
  }
  const end={
    endAdornment : <InputAdornment position="end">{adornmentValue}</InputAdornment>
  }




  return (
    <>
      <Card variant="outlined" sx={{ p: 1, borderRadius: "10px" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Grid item xs={12} md={3}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleClose("panel1")}
              sx={{ backgroundColor: "#F8FBFF", boxShadow: "none" }}
            >
              <AccordionSummary
                sx={{ minHeight: "20px" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="category"
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color:
                      selectedAsset !== "Select Asset Class"
                        ? "#000"
                        : "#A0AEC0",
                    fontFamily: "Lato",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                    },
                  }}
                >
                  {selectedAsset}
                </Typography>
              </AccordionSummary>
              <Divider />
              {
                <AccordionDetails sx={{ paddingLeft: 0 }}>
                  <List sx={{ fontSize: "16px", ml: 0 }}>
                    {data &&
                      data.length > 0 &&
                      data.map((value, i) => (
                        <ListItem
                          key={i}
                          button
                          value={value.name}
                          sx={{
                            fontSize: "15px",
                            color:
                              compValues[idx].category === value.name
                                ? "#194BFB"
                                : "rgba(112, 118, 125, 1)",
                            fontWeight: 600,
                            fontFamily: "Lato",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                          secondaryAction={
                            <Checkbox
                              edge="end"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 14,
                                  color:
                                    compValues[idx].category === value.name
                                      ? "#194BFB"
                                      : "#A0AEC0",
                                },
                              }}
                              icon={<CheckCircleOutlinedIcon />}
                              checkedIcon={<CheckCircleRoundedIcon />}
                              checked={compValues[idx].category === value.name}
                            />
                          }
                          onClick={() => {
                            handleChange(value.name, idx, "category");
                            handleSearchSelect(value.name);
                            setSelectedAsset(value.name);
                          }}
                        >
                          {value.name}{" "}
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              }
            </Accordion>
          </Grid>
          <Grid item xs={12} md>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleClose("panel2")}
              // onBlur={!expanded}
              sx={{
                backgroundColor: "#F8FBFF",
                boxShadow: "none",
                "& .css-1nu2kgj-MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible ":
                  {
                    backgroundColor: "#F8FBFF !important",
                  },
              }}
            >
              <AccordionSummary
                sx={{
                  minHeight: "20px",
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <InputBase
                  placeholder="Enter Script Code"
                  sx={{
                    // ml: 1,
                    flex: 1,
                    font: "caption",
                    maxHeight: "24px",
                    fontWeight: 600,
                    fontSize: "16px",
                    fontFamily: "Lato",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                    },
                  }}
                  value={showSearchSelect}
                  id={"script-search"}
                  autoComplete="off"
                  name="search"
                  onChange={(event) => {
                    handleChange(event.target.value, idx, "symbol");
                    handleSymbolSearch(event.target.value);
                    optimizedFn(event.target.value);
                  }}
                />
              </AccordionSummary>
              <Divider />
              <AccordionDetails
                sx={{ paddingLeft: 0, maxHeight: "135px", overflowY: "scroll" }}
              >
                <List sx={{ fontSize: "12px", ml: 0 }}>
                  {fetchScriptData &&
                    fetchScriptData.length > 0 &&
                    fetchScriptData.map((fetchValue, index) => (
                      <ListItem
                        key={index}
                        button
                        value={fetchValue.name}
                        sx={{
                          fontSize: "15px",
                          color:
                            fetchValue.name == showSearchSelect
                              ? "#194BFB"
                              : "rgba(112, 118, 125, 1)",
                          fontWeight: 600,
                        }}
                        secondaryAction={
                          <Checkbox
                            edge="end"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 14,
                                color:
                                  fetchValue.name == showSearchSelect
                                    ? "#194BFB"
                                    : "#A0AEC0",
                              },
                            }}
                            icon={<CheckCircleOutlinedIcon />}
                            checkedIcon={<CheckCircleRoundedIcon />}
                            checked={
                              fetchValue.name !== "" &&
                              fetchValue.name == showSearchSelect
                            }
                          />
                        }
                        onClick={() => {
                          !fetchValue.dummy &&
                            handleScriptSearch(
                              fetchValue.name,
                              idx,
                              fetchValue.symbol
                            );
                          handleChange(fetchValue.symbol, idx, "symbol");
                        }}
                      >
                        {fetchValue.name}{" "}
                      </ListItem>
                    ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={3}>
            {" "}
            <TextField
              id="outlined-basic"
              autoComplete="off"
              sx={{
                // maxWidth: "140px",
                "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                  maxHeight: "10px",
                  // maxWidth: "14px",
                  fontWeight: 600,
                  fontSize: "16px",
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "14px",
                  },
                },
              }}
              type="number"
              variant="outlined"
              label={selectValueType === "QUANTITY" ? "Quantity" : ""}
              InputProps={selectValueType === "PERCENT" ? end : start}
              value={quantityValue}
              fullWidth
              // error={compValues.percentage <= 0 || isNaN(compValues.percentage)}
              onChange={(event) => {
                handleChange(Number(event.target.value), idx, "value");
                setExpanded(false);
                quantityHandler(event.target.value);
              }}
            />
          </Grid>
          {small && (
            <>
              {" "}
              <Grid
                item
                xs={idx == compValues.length - 1 ? 6 : 12}
                align="center"
              >
                {compValues.length !== 1 && (
                  <img
                    component={"button"}
                    src={DeleteIcon}
                    style={{ paddingTop: "5px", cursor: "pointer" }}
                    onClick={() => {
                      compValues.length !== 1 && removeRows();
                    }}
                  />
                )}
              </Grid>
              {idx == compValues.length - 1 && (
                <Grid item xs={6} align="center">
                  <Button
                    size="small"
                    sx={{
                      fontSize: "12px",
                      color: "#194BFB",
                      textTransform: "none",
                    }}
                    startIcon={
                      <AddIcon sx={{ fontSize: "16px", color: "#194BFB" }} />
                    }
                    onClick={() => {
                      addMoreRow();
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Card>
    </>
  );
}

export default RowComponents;
