import React from "react";
import bolt from "../../../assets/bolt.png";
import greenbolt from "../../../assets/greenbolt.png";
import yellowbolt from "../../../assets/yellowbolt.png";

export const riskFactorBtn = (risk_factor) => {
  if (risk_factor === "Low") {
    return {
      backgroundColor: "rgba(54, 179, 126, 0.45)",
      border: "1px solid #36B37E"
    };
  } else if (risk_factor === "moderate") {
    return {
      backgroundColor: "rgba(255, 171, 0, 0.45)",
      border: "1px solid #FFAB00",
    };
  } else {
    return {
      backgroundColor: "rgba(255, 86, 48, 0.45)",
      border: "1px solid #FF5630",
    };
  }
};

export const riskFactorIcon = (risk_factor) => {
  if (risk_factor === "Low") {
    return `${greenbolt}`;
  } else if (risk_factor === "moderate") {
    return `${yellowbolt}`;
  } else {
    return `${bolt}`;
  }
};