import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MailIcon from "@mui/icons-material/Mail";
import SearchIcon from "@mui/icons-material/Search";
import SortingIcon from "./Vector.svg";
import FilterIcon from "./filter.svg";
import {
  FormControl,
  InputAdornment,
  Modal,
} from "@mui/material";
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
} from "@mui/material";
import {createTheme } from "@mui/material/styles";
import axios from "axios";
// import { debounce } from "lodash";
import debounce from 'lodash.debounce';
import CustomTable from "./CustomTable";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileCards from "./mobileCards";
const drawerWidth = 240;

export default function TableWrapper({
  tableType,
  headers,
}) {
  const menuId = "primary-search-account-menu";
  const mobileMenuId = "primary-search-account-menu-mobile";
  const theme = createTheme({
    typography: {
      fontFamily: "Raleway, Arial",
    },
  });
   const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const open = Boolean(anchorEl);
  const openFilter = Boolean(anchorElFilter);
  const id = open ? "simple-popover" : undefined;
  const idFilter = openFilter ? "simple-popover-filter" : undefined;

  const [sortValue, setSortValue] = useState("ASC_UPDATED_AT");
  const [row, setRow] = useState([]);
  const [filter, setFilter] = useState("name");
  const [filterText, setFilterText] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowSize, setRowSize] = useState("5");
  const [firstItemIndex, setFirstItemIndex] = useState(1);
  const [lastItemIndex, setLastItemIndex] = useState(row);
  const [totalData, setTotalData] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [prevPageNumber, setPrevPageNumber] = useState("");
  const [investmentTable, setInvestmentTable] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  // const [openDescription, setOpenDescription] = useState(false);
  //API call

  const getData = (pageNo, pageSize, sortOrder, searchType, searchText) => {
    axios
      .get(
        `https://orim.tradelab.co.in/orimdumper/api/v1/${tableType}?page_no=${pageNo}&page_size=${pageSize}&${searchType}=${searchText}&sort_order=${sortOrder}&phone_number=8320263124&user_type=ADMIN`
      )
      .then(({ data }) => {
        setRow(data.data.list);
        setTotalData(data.data.total_count);
        //  console.log(data.data,row,totalData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchFunction = (type, searchText) => {
    if (searchText === "") {
      setSearchType("");
      setSearchText("");
    } else {
      setSearchType(type);
      setSearchText(searchText);
    }
  };

  // debounce code

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const debounceOnChange = React.useCallback(debounce(searchFunction, 500), []);

  useEffect(() => {
    getData(pageNumber + 1, rowSize, sortValue, searchType, searchText);
  }, [searchText]);

  useEffect(() => {
    getData(1, 5, sortValue, searchType, searchText);
    setLastItemIndex(row.size);
  }, []);

  useEffect(() => {
    getData(pageNumber + 1, rowSize, sortValue, searchType, searchText);
  }, [pageNumber]);

  useEffect(() => {
    getData(pageNumber + 1, rowSize, sortValue, searchType, searchText);
  }, [sortValue]);

  const sortingHandler = (e) => {
    setSortValue(e.target.value);
  };

  //   const sortNameChange = (e) => {
  //     setSortName(e.target.value);
  //  }

  const previousPageHandler = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPageHandler = () => {
    if (lastItemIndex < totalData) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    setLastItemIndex(rowSize);
    getData(1, rowSize, sortValue, searchType, searchText);
    // console.log(rowSize);
  }, [rowSize]);

  useEffect(() => {
    if (pageNumber > 0) {
      setFirstItemIndex(pageNumber * rowSize);
      // console.log(
      //   Number(Number(lastItemIndex) + Number(rowSize)) > Number(totalData)
      // );
      if (
        Number(Number(pageNumber) * Number(rowSize) + Number(rowSize)) >
        Number(totalData)
      ) {
        // console.log(row.length);
        setLastItemIndex(totalData);
      } else {
        setLastItemIndex(pageNumber * rowSize + Number(rowSize));
      }
    } else {
      setFirstItemIndex(1);
      setLastItemIndex(rowSize);
    }
  }, [pageNumber]);


  const setFilterType = (e) => {
    setFilterText("");
    // setRow(perPageRow);
    setFilter(e.target.value);
  };


  const rowSizeHandle = (e) => {
    setRowSize(e.target.value);
    setFirstItemIndex(1);
    setLastItemIndex(rowSize);
    setPageNumber(0);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {[
          "Dashboard",
          "Client",
          "Investment",
          "Investment Option Mapper",
          "Setting",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          paddingTop: "1rem",
          boxShadow: "none",
          [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
            padding: "8px",
          },
        }}
      >
        <Grid
          container
          direction="row"
          sx={{
            paddingLeft: "20px",
            paddingTop: "23px",
            paddingBottom: "10px",
          }}
          spacing={2}
        >
          <Grid item xs={12} md={6}>
            <TextField
              placeholder="Search User"
              type="text"
              variant="standard"
              fullWidth={!matches ? true : false}
              size="small"
              onChange={(e) =>
                debounceOnChange("portfolio_username", e.target.value)
              }
              sx={{ backgroundColor: "aliceblue", padding: "10px" }}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid
                    item
                    sx={{
                      marginRight: "34px",
                      marginLeft: "38px",
                      [theme.breakpoints.down("sm")]: {
                        margin: 0,
                        marginTop: "12px",
                      },
                    }}
                  >
                    <img src={SortingIcon} />
                    <Typography
                      sx={{ display: "inline-block", marginLeft: "10px" }}
                      onClick={handleClick}
                    >
                      Sort
                    </Typography>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sort"
                          onChange={sortingHandler}
                          value={sortValue}
                        >
                          <MenuItem value="ASC_UPDATED_AT">
                            Updated At - Ascending
                          </MenuItem>
                          <MenuItem value="DSC_UPDATED_AT">
                            Updated At - Descending
                          </MenuItem>
                        </Select>
                      </Typography>
                    </Popover>
                  </Grid>
                  {/* <Grid item>
                    <img src={FilterIcon} />
                    <Typography
                      sx={{ display: "inline-block", marginLeft: "10px" }}
                      onClick={handleClickFilter}
                    >
                      Filter
                    </Typography>
                    <Popover
                      id={idFilter}
                      open={openFilter}
                      anchorEl={anchorElFilter}
                      onClose={handleCloseFilter}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <TextField
                          onChange={(e) => {
                            if (filter) {
                              setFilterText(e.target.value);
                              searchFunction(filter, e.target.value);
                            }
                          }}
                          value={filterText}
                          id="outlined-basic"
                          label="Search user"
                          variant="outlined"
                        />
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          onChange={setFilterType}
                          value={filter}
                          sx={{ border: "none", outline: "none" }}
                        >
                          <MenuItem value="name">Portfolio Username</MenuItem>
                          <MenuItem value="fat">Y</MenuItem>
                          <MenuItem value="carbs">Z</MenuItem>
                        </Select>
                      </Typography>
                    </Popover>
                  </Grid> */}
                </Grid>
              </Grid>
              {!investmentTable ? (
                <Grid item xs={12} md>
                  <Grid
                    container
                    // justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ marginRight: "8px" }}>
                      Row Per Page
                    </Typography>
                    <FormControl variant="standard">
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        onChange={rowSizeHandle}
                        value={rowSize}
                        disableUnderline
                      >
                        {totalData >= 5 && <MenuItem value="5">5</MenuItem>}
                        {totalData >= 10 && <MenuItem value="10">10</MenuItem>}
                        {totalData >= 15 && <MenuItem value="15">15</MenuItem>}
                        {totalData >= 20 && <MenuItem value="20">20</MenuItem>}
                        <MenuItem value={totalData}>All</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      sx={{
                        marginLeft: "35px",
                        [theme.breakpoints.down("sm")]: {
                          marginLeft: "10px",
                        },
                      }}
                    >
                      {firstItemIndex}-{lastItemIndex} of {totalData}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: "33px",
                        cursor: "pointer",
                        [theme.breakpoints.down("sm")]: {
                          marginLeft: "15px",
                        },
                      }}
                      onClick={previousPageHandler}
                    >
                      {"<"}
                    </Typography>
                    <Typography
                      sx={{
                        marginLeft: "30px",
                        marginRight: "38px",
                        cursor: "pointer",
                        [theme.breakpoints.down("sm")]: {
                          margin: 0,
                        },
                      }}
                      onClick={nextPageHandler}
                    >
                      {">"}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>

        <CustomTable
          arrHeaders={headers}
          isFixed={false}
          arrRows={row}
          setArrRows={setRow}
          rowSize={rowSize}
          pageNumber={pageNumber}
          // changeRowData={changeRowData}
          setPrevPageNumber={setPrevPageNumber}
          investmentTable={investmentTable}
          firstItemIndex={firstItemIndex}
          lastItemIndex={lastItemIndex}
          previousPageHandler={previousPageHandler}
          nextPageHandler={nextPageHandler}
          rowSizeHandle={rowSizeHandle}
        />

        {investmentTable ? (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ padding: "10px" }}
          >
            <Typography sx={{ marginRight: "8px" }}>Row Per Page</Typography>
            <FormControl variant="standard">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                onChange={rowSizeHandle}
                value={rowSize}
                disableUnderline
              >
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="-1">All</MenuItem>
              </Select>
            </FormControl>
            <Typography sx={{ marginLeft: "35px" }}>
              {firstItemIndex}-{lastItemIndex} of {totalData}
            </Typography>
            <Typography
              sx={{ marginLeft: "33px" }}
              onClick={previousPageHandler}
            >
              {"<"}
            </Typography>
            <Typography
              sx={{ marginLeft: "30px", marginRight: "38px" }}
              onClick={nextPageHandler}
            >
              {">"}
            </Typography>
          </Grid>
        ) : (
          ""
        )}
      </Paper>
      {/* {matches && <MobileCards row={row} headers ={headers}/>} */}
    </>
  );
}
