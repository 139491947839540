import React,{useEffect,useState} from "react";
import CustomImageTextButton from "../../common/CustomImageTextButton";
import excelUpload from "../../../../assets/AssetsForAdmin/excelUpload.png";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import products from "./products.json";
import { useSelector, useDispatch } from "react-redux";
import { fetchRecommendedAssetsAction } from "../../../../Store/actions/securityDetails";
const data = products;


// const group = [
//   {
//     field: "Category.CategoryName",
//   },
// ];
// const data = process(products, {
//   group: group,
// }).data;

function GenerateExcel({ excelRef, excelExport }) {
      const dispatch = useDispatch();
      const investmentDetails = useSelector(
        (state) => state.securityDetails.recommendedAssets.list
      );

      useEffect(() => {
        dispatch(fetchRecommendedAssetsAction());
      }, []);


  return (
    <>
      <CustomImageTextButton
        img={excelUpload}
        text={"Export to Excel"}
        handleOnClick={excelExport}
      />
      {/* <ExcelExporta
        data={data}
        group={group}
        fileName="Products.xlsx"
        ref={excelRef}
      >
        <ExcelExportColumn field="ProductID" title="Product ID" />
        <ExcelExportColumn
          field="ProductName"
          title="Product Name"
          width={350}
        />
        <ExcelExportColumn
          field="Category.CategoryName"
          title="Category"
          hidden={true}
        />
      </ExcelExport> */}
      <ExcelExport
        data={investmentDetails}
        fileName="Products.xlsx"
        ref={excelRef}
      >
        <ExcelExportColumn
          field="name"
          title="Name"
        />
        <ExcelExportColumn
          field="description"
          title="Description"
        />
        <ExcelExportColumn
          field="volatility"
          title="Volatility"
        />
        <ExcelExportColumn
          field="cagr"
          title="CAGR"
        />
        <ExcelExportColumn
          field="return_percentage"
          title="Return Percentage"
        />
        <ExcelExportColumn
          field="hyperlink"
          title="Hyper Link"
        />
        <ExcelExportColumn
          field="status"
          title="Status"
        />
      </ExcelExport>
    </>
  );
}

export default GenerateExcel;
