import React, { useState } from "react";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Divider, Grid, useMediaQuery } from "@mui/material";
import googleIconImage from "../../assets/image33.png";
import facebookIconImage from "../../assets/image35.png";
import HeadingTextComponent from "./CommonComponent/HeadingTextComponent";
import TextFieldComponent from "./CommonComponent/TextFieldComponent";
import SubmitButton from "./CommonComponent/SubmitButton";
import SocialSignUpButton from "./CommonComponent/SocialSignUpButton";
import GridContainerForTwo from "./CommonComponent/GridContainerFortwo";
import { Link } from "react-router-dom";
import { orimLogin } from "../../Services/BackendAPI";
import { MobFooterText } from "./SignUp";
import useTheme from "@mui/material/styles/useTheme";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";

const theme = createTheme();
export default function SignIn() {
  const [textFieldValues, setTextFieldValues] = useState({
    emailPhone: "",
    password: "",
  });

  const [rememberMe, setRememberMe] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const textInputHandler = (e) => {
    const { name, value } = e.target;
    if (name === "emailPhone") {
      setTextFieldValues({ ...textFieldValues, emailPhone: value });
      return;
    } else if (name === "password") {
      setTextFieldValues({ ...textFieldValues, password: value });
      return;
    }
  };

  const onSubmitHandler = () => {
    if (textFieldValues.emailPhone === "") {
      enqueueSnackbar("please check email/phone no", { variant: "error" });
      return;
    } else if (textFieldValues.password === "") {
      enqueueSnackbar("please check the password", { variant: "error" });
      return;
    }
    let payload = {
      username: textFieldValues.emailPhone,
      password: textFieldValues.password,
    };
    orimLogin(payload)
      .then(({ data, error }) => {
        if (error.code === 0) {
          // console.log("signup", data);
          enqueueSnackbar(error.message, { variant: "success" });
          localStorage.setItem("token", data.auth_token);
          localStorage.setItem("username", data.username);
          localStorage.setItem("name", data.name);
          localStorage.setItem("userType", data.user_type);
          window.location.reload();
        } else {
          enqueueSnackbar(error.message, { variant: "error" });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: "error" });
      });
  };
  const themev1 = useTheme();
  const matches = useMediaQuery(themev1.breakpoints.down("sm"));
  return (
    <>
      <HeadingTextComponent
        heading={"Welcome Back"}
        subHeading={"Sign In now to check your portfolio analytics"}
      />
      {/* <HeadingTextComponent
        heading={"Sign In to ORIM"}
        subHeading={"Perfect, Easy & Quick"}
      /> */}
      {/* <GridContainerForTwo>
        <SocialSignUpButton
          src={googleIconImage}
          text={"Sign Up with Google  "}
        />
        <SocialSignUpButton
          src={facebookIconImage}
          text={"Sign Up with Facebook"}
        />
      </GridContainerForTwo>
      <Divider color="#E6EBF2">
        <Typography
          align="center"
          color="#718096"
          sx={{
            fontSize: "0.85rem",
            fontWeight: "500",
          }}
        >
          Or With Email
        </Typography>
      </Divider> */}
      <TextFieldComponent
        onChange={textInputHandler}
        value={textFieldValues.emailPhone}
        name={"emailPhone"}
        type={"email"}
        label={"Email/Phone Number"}
      />
      <TextFieldComponent
        onChange={textInputHandler}
        value={textFieldValues.password}
        name={"password"}
        type={"password"}
        label={"Password"}
      />

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent="space-between"
      >
        <FormControlLabel
          checked={rememberMe}
          control={
            <Checkbox
              onClick={() => setRememberMe(!rememberMe)}
              sx={{
                "&.Mui-checked": {
                  color: "#194BFB",
                },
              }}
            />
          }
          label="Remember Me"
        />

        {/* <Link
          href="#"
          sx={{
            color: "#000",
            textDecoration: "none",
            fontFamily: "Lato",
            fontWeight: 500,
          }}
        >
          Forget Password?
        </Link> */}
      </Stack>
      {matches && (
        <MobFooterText>
          Privacy Policy <span style={{ color: "#718096" }}>&</span> Copyright
          2022
        </MobFooterText>
      )}
      <SubmitButton text={"Sign In"} onClick={onSubmitHandler}></SubmitButton>
      <Typography
        align="center"
        sx={{
          fontSize: {
            xs: "0.6rem",
            md: "0.85rem",
          },
          fontWeight: "500",
        }}
      >
        Don’t have an account?
        <Link
          to="/auth/signup"
          style={{
            color: "#020202",
            fontWeight: "700",
            fontSize: "14px",
            fontFamily: "Lato",
            cursor: "pointer",
            paddingLeft: "4px",
          }}
        >
          Sign Up
        </Link>
      </Typography>
    </>
  );
}
