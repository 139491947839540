import React from 'react'
import { Typography, Grid, IconButton } from '@mui/material';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function UploadProgress({isUploading,setIsUploading,setBrowseImageUpload,setSelectedFile}) {
  const [progress, setProgress] = React.useState(0);
  const theme = useTheme();
  const medium = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            setIsUploading(false)
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 200);
  
      return () => {
        clearInterval(timer);
      };   
  }, [progress]);
  
  const Childdiv = {
    height: '0.063rem',
    width: `${progress}%`,
    backgroundColor: '#194BFB',
  }

  const handlePauseUpload = () => {
    
  }

  const handleCancelUpload = () => {
    setIsUploading(false)
    setBrowseImageUpload(true)
    setSelectedFile(null)
  }
  return (
    <div style={{ position: 'relative' }}>
        <Grid container 
          sx={{
              borderRadius: '0.25rem',
              border: 'thin solid #CDCDCD',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.3rem 0',
              position: 'absolute',
          }}
        >
          <Grid item container
            sx={{ width: '90%', alignItems: 'center' }}
          >
            <Grid item xs>
              <Typography variant='caption' fontWeight='bold'>Uploading...</Typography><br/>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='caption' sx={{ color: '#A0AEC0' }}>{progress.toFixed()}%</Typography>&nbsp;&nbsp;
                <CircleIcon sx={{ fontSize:'0.375rem' }} color="disabled" />&nbsp;&nbsp;
                <Typography variant='caption' sx={{ color: '#A0AEC0' }}>4 s left</Typography>
              </div>
            </Grid>
            <Grid container item xs sx={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <IconButton  onClick={() => handlePauseUpload()}>
                  <PauseCircleIcon sx={{ cursor: 'pointer', color: '#CDCDCD' }} fontSize='medium' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleCancelUpload()}>
                  <CancelIcon sx={{ cursor: 'pointer', color: '#CDCDCD' }} fontSize='medium' />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '90%' }}>
            <div style={Childdiv}></div>
          </Grid>
        </Grid>
        <div style={{  zIndex: "1", backgroundColor: '#ECF2FC', width: `${progress}%`, borderRadius: '0.25rem', height: "3.6rem" }}></div>
    </div>
  )
}

export default UploadProgress