import React from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  Paper,
  Stack,
  Typography,
  Grid,
  CardMedia,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import MoreIcon from "@mui/icons-material/MoreVert";
import EditInvestmentDtlsModal from "../modals/EditInvestmentDtlsModal";
import DescriptionModal from "../modals/DescriptionModal";
import SecurityDetails from "../modals/SecurityDetails";

function mobileCards({
  rows,
  headers,
  handleOpenSecurityDetails,
  morePopOverMenuId,
  handleMorePopOverOpen,
  handleOpenDescription,
  editModalOpen,
  handleOpen,
  handleClose,
  row,
  openDescription,
  handleCloseDescription,
  currentRow,
  openSecurityDetails,
  handleCloseSecurityDetails,
}) {
  //   console.log(row, headers);
  return (
    <>
      {rows.map((value, index) => (
        <Card
          key={index}
          sx={{
            display: "flex",
            padding: "1rem",
            marginTop: "0.75rem",
            marginBottom: "0.75rem",
          }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
          >
            {headers.map((val, idx) => (
              <Grid key={idx} item xs={6}>
                {val.label}
                {val.keyName === "security_details" ? (
                  <>
                    {" "}
                    <Grid item xs={12}>
                      <Button
                        onClick={() => {
                          handleOpenSecurityDetails(value, index);
                        }}
                        size="small"
                        variant="text"
                        sx={{
                          textTransform: "none",
                          fontSize: "12px",
                          color: "#194BFB",
                          padding: 0,
                          paddingLeft: "4px",
                          borderBottom: "1px solid #194BFB",
                          borderRadius: "0px",
                          "&:hover": {
                            boxShadow: "none",
                            backgroundColor: "f5f5f5",
                          },
                        }}
                        disableElevation
                      >
                        View More
                      </Button>
                    </Grid>
                  </>
                ) : val.label === "Description" ? (
                  <>
                    <Button
                      onClick={() => handleOpenDescription(value)}
                      size="small"
                      variant="text"
                      sx={{
                        textTransform: "none",
                        fontSize: "12px",
                        color: "#194BFB",
                        padding: 0,
                        paddingLeft: "4px",
                        borderBottom: "1px solid #194BFB",
                        borderRadius: "0px",
                        "&:hover": {
                          boxShadow: "none",
                          backgroundColor: "f5f5f5",
                        },
                      }}
                      disableElevation
                    >
                      Description
                    </Button>
                  </>
                ) : val.keyName === "morepopover" ? (
                  <Grid item xs={12} align="right">
                    <IconButton
                      size="small"
                      aria-label="show more"
                      aria-controls={morePopOverMenuId}
                      aria-haspopup="true"
                      onClick={(event) => handleMorePopOverOpen(event, value)}
                    >
                      <MoreIcon />
                    </IconButton>
                  </Grid>
                ) : (
                  <Typography gutterTop variant="subtitle1">
                    {val.formatter(value)}
                  </Typography>
                )}
              </Grid>
            ))}
          </Grid>
        </Card>
      ))}
      {openSecurityDetails && (
        <SecurityDetails
          open={openSecurityDetails}
          handleOpen={handleOpenSecurityDetails}
          handleClose={handleCloseSecurityDetails}
          row={currentRow}
        />
      )}
      {openDescription && (
        <DescriptionModal
          open={openDescription}
          handleOpen={handleOpenDescription}
          handleClose={handleCloseDescription}
          row={currentRow}
        />
      )}
      {editModalOpen && (
        <EditInvestmentDtlsModal
          open={editModalOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          row={row}
        />
      )}
    </>
  );
}

export default mobileCards;
