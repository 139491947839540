// import { Box, Button } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import React, { useState } from 'react'


// const StyledButton = styled(Button)(({ theme, myColor/}) => ({
//     backgroundColor: myColor?myColor:'#FFF',
//     textTransform:'capitalize',
//     color:'#101010',
//     fontSize:'1.1rem',
//     border:clicked?'1px solid #29CC97':'1px solid #E2E8F0',
//     borderRadius:'1.25rem',
//     padding:'0.3rem 1.5rem',
//   }));
import { Box, Button } from '@mui/material';
import { styled} from '@mui/system';
import React, { useState } from 'react'

const StyledButton=styled(Button) ((props)=>{
    return {
      fontSize:'1.1rem',
      color:'#101010',
      backgroundColor:'#FFF',
      backgroundColor:'#FFF',
      textTransform:'capitalize',
      borderRadius:'1.25rem',
      padding:'0.3rem 1.5rem',
      border:`1px solid ${props.active?'#29CC97':'#E2E8F0'}`,
      // [props.theme.breakpoints.down('sm')]: {
      //   fontSize: "0.75rem"
      // },
      
      
  }})
  
  function StatusButtonTabs({active=false,text,onClick,index}) {
    return (
      <StyledButton active={active} onClick={onClick}> {text}</StyledButton>
    )
  }
  
  export default StatusButtonTabs
  
// function StatusButtonTabs({text,index}) {
//     const [clickedIndex,setClickedIndex] = useState(0)
//   return (
//     <>
//     <StyledButton onClick={setClickedIndex(index)} selected>{text}</StyledButton>
//     </>
//   )
// }

// export default StatusButtonTabs